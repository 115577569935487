import { FC } from 'react';
import { Search } from 'components/Search';
import { ThemeIcon, FeedbackIcon } from 'components/Icons';
import { Contract } from 'types';

interface HeaderProps {
  query?: string;
  results?: Contract[];
  onSearch?: () => void;
  onSearchTerms?: (value: string) => void;
  onSwitchTheme?: () => void;
  darkTheme?: boolean;
  disabled?: boolean;
  goToSignPage: (e: Contract) => void;
}

export const Header: FC<HeaderProps> = ({
  query = '',
  results = [],
  darkTheme = false,
  disabled = false,
  onSearch,
  onSwitchTheme,
  onSearchTerms,
  goToSignPage
}) => {
  return (
    <div className="flex items-center mb-10">
      <div className="flex-1">
        <Search
          query={query}
          disabled={disabled}
          onSearch={onSearch}
          onSearchTerms={onSearchTerms}
          results={results}
          goToSignPage={goToSignPage}
        />
      </div>
      <div className="flex items-center justify-end ml-6">
        {false && (
          <span className="flex items-center cursor-pointer" onClick={onSwitchTheme}>
            <ThemeIcon />
            <a className="text-body text-gray-500 ml-1">{!darkTheme ? 'light' : 'dark'} theme</a>
          </span>
        )}
        {/* <span className="flex items-center cursor-pointer mr-8">
          <QAIcon />
          <a className="text-body text-gray-500 ml-1">Q&A</a>
        </span> */}
        <a
          className="flex items-center cursor-pointer"
          href="https://form.typeform.com/to/vAAV1qPr"
          target="_blank"
          rel="noreferrer"
        >
          <FeedbackIcon />
          <span className="text-body text-gray-500 ml-1">Feedback</span>
        </a>
      </div>
    </div>
  );
};
