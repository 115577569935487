import { Recipient } from './Recipient';

export enum AnnotationField {
  Signature = 'signature',
  Name = 'name',
  Address = 'address',
  Checkbox = 'checkbox',
  DateSigned = 'date-signed',
  Text = 'text'
}

export const AllAnnotationFields = [
  AnnotationField.Signature,
  AnnotationField.DateSigned,
  AnnotationField.Address,
  AnnotationField.Text,
  AnnotationField.Checkbox
];

export interface AnnotationOptions {
  index: number;
  signer: Recipient;
  field: AnnotationField;
  required: boolean;
}

export interface Annotation {
  id: string;
  options: AnnotationOptions;
  page?: number;
  filled?: boolean;
}
