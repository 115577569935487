import { SVGProps } from 'react';

export const DragHandlerIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.9199 13.5C16.7483 13.5 17.4199 12.8284 17.4199 12C17.4199 11.1716 16.7483 10.5 15.9199 10.5C15.0915 10.5 14.4199 11.1716 14.4199 12C14.4199 12.8284 15.0915 13.5 15.9199 13.5Z"
        fill="currentColor"
      />
      <path
        d="M15.9199 25.5C16.7483 25.5 17.4199 24.8284 17.4199 24C17.4199 23.1716 16.7483 22.5 15.9199 22.5C15.0915 22.5 14.4199 23.1716 14.4199 24C14.4199 24.8284 15.0915 25.5 15.9199 25.5Z"
        fill="currentColor"
      />
      <path
        d="M21.9199 13.5C22.7483 13.5 23.4199 12.8284 23.4199 12C23.4199 11.1716 22.7483 10.5 21.9199 10.5C21.0915 10.5 20.4199 11.1716 20.4199 12C20.4199 12.8284 21.0915 13.5 21.9199 13.5Z"
        fill="currentColor"
      />
      <path
        d="M21.9199 25.5C22.7483 25.5 23.4199 24.8284 23.4199 24C23.4199 23.1716 22.7483 22.5 21.9199 22.5C21.0915 22.5 20.4199 23.1716 20.4199 24C20.4199 24.8284 21.0915 25.5 21.9199 25.5Z"
        fill="currentColor"
      />
      <path
        d="M21.9199 19.5C22.7483 19.5 23.4199 18.8284 23.4199 18C23.4199 17.1716 22.7483 16.5 21.9199 16.5C21.0915 16.5 20.4199 17.1716 20.4199 18C20.4199 18.8284 21.0915 19.5 21.9199 19.5Z"
        fill="currentColor"
      />
      <path
        d="M15.9199 19.5C16.7483 19.5 17.4199 18.8284 17.4199 18C17.4199 17.1716 16.7483 16.5 15.9199 16.5C15.0915 16.5 14.4199 17.1716 14.4199 18C14.4199 18.8284 15.0915 19.5 15.9199 19.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
