import classNames from 'classnames';

import { Status } from '../Icons';

export enum BarStatus {
  UPLOAD = 0,
  MANAGE = 1,
  PREPARE = 2,
  REVIEW = 3
}

enum StatusText {
  UPLOAD = 'Upload Document',
  MANAGE = 'Manage Recipients',
  PREPARE = 'Prepare Documents',
  REVIEW = 'Review and Send'
}

interface DividerProps {
  status: Status;
  isVertical?: boolean;
}

const Divider = ({ status, isVertical = false }: DividerProps) => {
  return (
    <div
      className={classNames({
        'border-gray-400 border-solid': status === Status.DONE && !isVertical,
        'border-gray-200 border-dashed': status !== Status.DONE && !isVertical,
        'border-gray-300 border-solid': status === Status.DONE && isVertical,
        'border-gray-300 border-dashed': status !== Status.DONE && isVertical,
        'mx-6 border-t-2': !isVertical,
        'my-3 mx-3 border-l-2': isVertical
      })}
      //min width and height arbitrary values are not working on tailwind for this
      style={{ minWidth: isVertical ? '0px' : '46px', minHeight: isVertical ? '18px' : '0px' }}
    />
  );
};

interface StatusBarProps {
  status: BarStatus;
}

export const StatusBar = ({ status }: StatusBarProps) => {
  return (
    <div className="flex-1 flex flex-col max-w-[75%]">
      <div className="flex items-start flex-col">
        <div className="flex flex-col items-start">
          <div className="flex items-center">
            <div
              className={classNames('w-6 h-6 flex text-body-bold justify-center items-center border rounded-2xl', {
                'text-gray-500 border-gray-500': status < BarStatus.UPLOAD,
                'bg-gray-500 text-white border-gray-500': status > BarStatus.UPLOAD,
                'text-orange-500 border-orange-500': status === BarStatus.UPLOAD
              })}
            >
              {BarStatus.UPLOAD + 1}
            </div>
            <span
              className={classNames('text-body pl-3', {
                'text-gray-500': status !== BarStatus.UPLOAD,
                'text-orange-500': status === BarStatus.UPLOAD
              })}
            >
              {StatusText.UPLOAD}
            </span>
          </div>
          <Divider isVertical status={Status.DONE} />
        </div>
        <div className="flex flex-col items-start">
          <div className="flex items-center">
            <div
              className={classNames('w-6 h-6 flex text-body-bold justify-center items-center border rounded-2xl', {
                'text-gray-500 border-gray-500': status < BarStatus.MANAGE,
                'bg-gray-500 text-white border-gray-500': status > BarStatus.MANAGE,
                'text-orange-500 border-orange-500': status === BarStatus.MANAGE
              })}
            >
              {BarStatus.MANAGE + 1}
            </div>
            <span
              className={classNames('text-body pl-3', {
                'text-gray-500': status !== BarStatus.MANAGE,
                'text-orange-500': status === BarStatus.MANAGE
              })}
            >
              {StatusText.MANAGE}
            </span>
          </div>
          <Divider isVertical status={Status.DONE} />
        </div>
        <div className="flex flex-col items-start">
          <div className="flex items-center">
            <div
              className={classNames('w-6 h-6 flex text-body-bold justify-center items-center border rounded-2xl', {
                'text-gray-500 border-gray-500': status < BarStatus.PREPARE,
                'bg-gray-500 text-white border-gray-500': status > BarStatus.PREPARE,
                'text-orange-500 border-orange-500': status === BarStatus.PREPARE
              })}
            >
              {BarStatus.PREPARE + 1}
            </div>
            <span
              className={classNames('text-body pl-3', {
                'text-gray-500': status !== BarStatus.PREPARE,
                'text-orange-500': status === BarStatus.PREPARE
              })}
            >
              {StatusText.PREPARE}
            </span>
          </div>
          <Divider isVertical status={Status.IN_PROGRESS} />
        </div>
        <div className="flex flex-col items-start">
          <div className="flex items-center">
            <div
              className={classNames('w-6 h-6 flex text-body-bold justify-center items-center border rounded-2xl', {
                'text-gray-500 border-gray-500': status < BarStatus.REVIEW,
                'bg-gray-500 text-white border-gray-500': status > BarStatus.REVIEW,
                'text-orange-500 border-orange-500': status === BarStatus.REVIEW
              })}
            >
              {BarStatus.REVIEW + 1}
            </div>
            <span
              className={classNames('text-body pl-3', {
                'text-gray-500': status !== BarStatus.REVIEW,
                'text-orange-500': status === BarStatus.REVIEW
              })}
            >
              {StatusText.REVIEW}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
