import { FC, HTMLAttributes, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';

import { METAMASK, WALLET_CONNECT, COINBASE, TORUS } from 'utils/providers';
import { Modal, ModalContent, IModalProps } from 'components/Modal';
import { setAlias, setAvatar, setLoginType } from 'store/userSlice';
import { Provider, Providers, ProviderType } from 'types';
import { getAliasByAddress, getAvatarByAlias } from 'utils/address';
import { useToast } from 'hooks';
import { setAccount } from 'store/contractSlice';

interface ConnectButtonProps extends HTMLAttributes<HTMLButtonElement> {
  provider: Provider;
}

const ConnectButton = ({ provider, ...rest }: ConnectButtonProps) => (
  <button
    type="button"
    className="w-full p-4 md:p-8 flex justify-between items-center rounded-xl bg-white border border-white group hover:border-orange-700"
    {...rest}
  >
    <span className="text-left whitespace-nowrap">
      <span className="block text-heading2 text-gray-600">{provider.name}</span>
      {provider.description ? (
        <span className="md:-mt-2 block text-p6 md:text-p5 text-gray-500 py-2">{provider.description}</span>
      ) : null}
    </span>
    <span className="flex flex-wrap items-center">
      {provider.logos?.map((Logo) => (
        <Logo key={Logo.name} className="mx-[10px] w-[30px] md:w-[40px]" />
      ))}
    </span>
  </button>
);

export const ConnectAccountModal: FC<IModalProps> = ({ onClose, ...rest }) => {
  const dispatch = useDispatch();
  const { activate, account } = useWeb3React();
  const { showError } = useToast();
  const loginType = window.localStorage.getItem('provider') as ProviderType;
  const [loginProviderId, setLoginProviderId] = useState<string>(loginType);

  useEffect(() => {
    (async () => {
      if (account) {
        onClose?.();
        dispatch(setAccount(account.toLowerCase()));

        try {
          const alias = await getAliasByAddress(account);
          const avatar = await getAvatarByAlias(alias);

          dispatch(setAlias(alias));
          dispatch(setAvatar(avatar));
        } catch (err) {
          console.log('===== err =====', err);
          showError((err as Error).message);
        }
      }
    })();
  }, [account]);

  useEffect(() => {
    if (account) {
      setProvider(loginProviderId);
    }
  }, [loginProviderId]);

  const setProvider = (type: string) => {
    window.localStorage.setItem('provider', type);
  };

  const activateInjectedProvider = (providerName: string) => {
    if (providerName !== Providers.COINBASE_WALLET && providerName !== Providers.METAMASK) {
      return false;
    }

    const { ethereum } = window;

    if (!ethereum?.providers) {
      return undefined;
    }

    let provider;
    switch (providerName) {
      case Providers.COINBASE_WALLET:
        provider = ethereum.providers.find(({ isCoinbaseWallet }: { isCoinbaseWallet: boolean }) => isCoinbaseWallet);
        break;
      case Providers.METAMASK:
        provider = ethereum.providers.find(({ isMetaMask }: { isMetaMask: boolean }) => isMetaMask);
        break;
    }

    if (provider) {
      ethereum.setSelectedProvider(provider);
      return true;
    }

    return false;
  };

  const handleLoginClick = (provider: Provider) => async () => {
    activateInjectedProvider(provider.name);
    await activate(provider.connector);
    dispatch(setLoginType(provider.type));
    setProvider(provider.type);
    setLoginProviderId(provider.id);
  };

  return (
    <Modal {...rest} shouldCloseOnEsc={true} shouldCloseOnBackdropClick={true} onClose={onClose}>
      <ModalContent className="m-2" hasCloseButton={false} hasPadding={false}>
        <div className="md:w-[37.5rem] p-6 md:px-14 md:py-16 space-y-4 md:space-y-6 bg-background">
          <ConnectButton provider={METAMASK} onClick={handleLoginClick(METAMASK)} />
          <ConnectButton provider={WALLET_CONNECT} onClick={handleLoginClick(WALLET_CONNECT)} />
          <ConnectButton provider={COINBASE} onClick={handleLoginClick(COINBASE)} />
          <div className="py-3 grid grid-cols-[1fr_auto_1fr] gap-3 items-center">
            <div className="h-px w-full bg-gray-300" />
            <span className="text-p5 text-gray-600">or continue with</span>
            <div className="h-px w-full bg-gray-300" />
          </div>
          <ConnectButton provider={TORUS} onClick={handleLoginClick(TORUS)} />
        </div>
      </ModalContent>
    </Modal>
  );
};
