import { useWeb3React } from '@web3-react/core';
import { useWebViewer } from 'hooks';
import React, { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Contract, ContractStatus, EncryptMethod, RecipientType } from 'types';
import { find, get } from 'lodash';
import { decryptDataArrayFromStringAES } from 'utils/file-utils';
import { setDecryptStatus, updateContract } from 'store/contractSlice';
import CryptoJS from 'crypto-js';
import { fetchCompletedContract, fetchContractSignedEvent } from 'services/contracts';
import moment from 'moment';
import { fetchTxOnArweave } from 'services/storage';

interface IDecryptContext {
  AESKey: string | null;
  decryptContract: (id: string, completed?: boolean, password?: string) => Promise<IDecryptResult>;
}

interface IDecryptResult {
  success: boolean;
  files?: File[];
  signatures?: string;
}

const DecryptContext = createContext<IDecryptContext | null>(null);

const DecryptProvider: React.FC = (props) => {
  const dispatch = useDispatch();
  const { active, account } = useWeb3React();
  const { instance } = useWebViewer();

  const [AESKey, setAESKey] = useState<string | null>(null);

  const decryptContract = async (contractId: string, completed = false, password = ''): Promise<IDecryptResult> => {
    if (!active) {
      return { success: false };
    }

    // reset current contract & document opened
    instance?.UI.closeDocument();
    instance?.Core.documentViewer.closeDocument();

    let event;

    // check if decrypt is for download contract
    if (completed) {
      // fetch signed contract from thegraph by contractId
      event = await fetchCompletedContract(contractId);
    } else {
      // fetch signed contract from thegraph by contractId
      event = await fetchContractSignedEvent(contractId);
    }

    // destructure event from api response
    const rawEvent = event.data.data.events[0];

    // deserialize event to EthSign Contract type
    const encryptedContract: Contract = {
      id: rawEvent.contract.id,
      name: rawEvent.contract.name,
      network: 'Polygon',
      expiry: rawEvent.contract.expiry,
      birth: rawEvent.contract.birth,
      signed: rawEvent.contract.signed,
      expired: rawEvent.contract.expiry > 0 && new Date().getTime() > rawEvent.contract.expiry * 1000,
      date: moment(rawEvent.contract.birth * 1000).format('MMMM DD, YYYY HH:MM A'),
      recipients: rawEvent.contract.signers.map((address) => ({
        id: address,
        type: RecipientType.SIGNER,
        user: { email: '', address: address, oneTapEnabled: false, regKey: null },
        signed: rawEvent.contract.signedSigners.findIndex((adx) => adx === address) !== -1
      })),
      encrypted: rawEvent.contract.metadata,
      pdfTxId: rawEvent.contract.rawDataHash,
      signatureTxId: !rawEvent.rawDataHash ? rawEvent.contract.rawDataHash : rawEvent.rawDataHash,
      meta: {
        hasSignPermission: true,
        hasAccountSigned: true,
        numPendingSigners: 0,
        numSigned: 0,
        expiredAt: '',
        status: ContractStatus.SIGN
      }
    };

    dispatch(updateContract({ current: encryptedContract }));

    if (encryptedContract.encrypted === EncryptMethod.PASSWORD && !password) {
      dispatch(setDecryptStatus(true));
      return { success: false };
    }

    // ========================= one-tap encryption flow ============================
    if (encryptedContract.encrypted === EncryptMethod.ONETAP && account) {
      // open decrypt modal in layout
      dispatch(setDecryptStatus(true));

      // fetch tx data from arweave network via original txId
      const response = await fetchTxOnArweave(encryptedContract.pdfTxId);
      const signatureRes = await fetchTxOnArweave(encryptedContract.signatureTxId);
      // update the signature data for display
      response.fdfString = signatureRes.fdfString;

      // // if there's anyone signed the contract, use xfdf string from the signatureTxId
      // const hasAnyoneSigned = current.recipients.some((item) => item.signed);

      // if (hasAnyoneSigned) {
      //   // get response from signatureTxId from arweave
      //   // update the signature data for display
      //   response.fdfString = signatureRes.fdfString;
      // }

      // see if the version has been introduced
      if (response.meta && response.meta.version) {
        // find encrypted message from the response
        const encryptedMessage = get(find(response.recipientKeys, account), account);
        if (encryptedMessage) {
          let decryptedMessage;
          try {
            // descrypt message with eth_decrypt method
            decryptedMessage = await window.ethereum.request({
              method: 'eth_decrypt',
              params: [encryptedMessage, account]
            });
            setAESKey(decryptedMessage);
          } catch (error) {
            setAESKey(null);
            // close decrypt modal in layout
            dispatch(setDecryptStatus(false));
            // reset contract store with no current, no files, and no signature string
            dispatch(updateContract({ current: undefined, files: [], fdfString: undefined }));
            return { success: false };
          }
          // decrypt file data with encryptionKey (decryptedMessage)
          const fileBlob = decryptDataArrayFromStringAES(response.fileStr, decryptedMessage);
          const file = new File([fileBlob], 'sample.pdf');

          if (signatureRes.meta && signatureRes.meta.version) {
            // decrypt signature data with encryptionKey (decryptedMessage)
            const bytes = CryptoJS.AES.decrypt(response.fdfString, decryptedMessage);
            const signatureString = bytes.toString(CryptoJS.enc.Utf8);

            dispatch(
              updateContract({
                files: [file],
                fdfString: signatureString,
                isDecrypting: false
              })
            );
            return { success: true, files: [file], signatures: signatureString };
          } else {
            // use the original signature string as it's not encrypted at all
            dispatch(
              updateContract({
                files: [file],
                fdfString: signatureRes.fdfString,
                isDecrypting: false
              })
            );
            return { success: true, files: [file], signatures: signatureRes.fdfString };
          }
        }
      } else {
        // open decrypt modal in layout
        dispatch(setDecryptStatus(true));
        const encryptionKey = '';
        setAESKey(encryptionKey);

        // decrypt file data with encryptionKey (decryptedMessage)
        const fileBlob = decryptDataArrayFromStringAES(response.fileStr, encryptionKey);
        const file = new File([fileBlob], 'sample.pdf');

        if (signatureRes.meta && signatureRes.meta.version) {
          // decrypt signature data with encryptionKey (decryptedMessage)
          const bytes = CryptoJS.AES.decrypt(response.fdfString, encryptionKey);
          const signatureString = bytes.toString(CryptoJS.enc.Utf8);

          dispatch(
            updateContract({
              files: [file],
              fdfString: signatureString,
              isDecrypting: false
            })
          );
          return { success: true, files: [file], signatures: signatureString };
        } else {
          // use the original signature string as it's not encrypted at all
          dispatch(
            updateContract({
              files: [file],
              fdfString: signatureRes.fdfString,
              isDecrypting: false
            })
          );
          return { success: true, files: [file], signatures: signatureRes.fdfString };
        }
      }
    }

    // ========================= password encryption flow ============================
    if (encryptedContract.encrypted === EncryptMethod.PASSWORD && account) {
      // open decrypt modal in layout
      dispatch(setDecryptStatus(true));
      const encryptionKey = password;
      setAESKey(encryptionKey);

      const response = await fetchTxOnArweave(encryptedContract.pdfTxId);
      const signatureRes = await fetchTxOnArweave(encryptedContract.signatureTxId);

      // decrypt file data with encryptionKey (decryptedMessage)
      const fileBlob = decryptDataArrayFromStringAES(response.fileStr, encryptionKey);
      const file = new File([fileBlob], 'sample.pdf');

      if (signatureRes.meta && signatureRes.meta.version) {
        // decrypt signature data with encryptionKey (decryptedMessage)
        const bytes = CryptoJS.AES.decrypt(signatureRes.fdfString, encryptionKey);
        const signatureString = bytes.toString(CryptoJS.enc.Utf8);

        if (file && signatureString) {
          dispatch(
            updateContract({
              files: [file],
              fdfString: signatureString,
              isDecrypting: false
            })
          );

          dispatch(setDecryptStatus(false));
          return { success: true, files: [file], signatures: signatureString };
        } else {
          return { success: false, files: [], signatures: undefined };
        }
      } else {
        // use the original signature string as it's not encrypted at all
        dispatch(
          updateContract({
            files: [file],
            fdfString: signatureRes.fdfString,
            isDecrypting: false
          })
        );
        return { success: true, files: [file], signatures: signatureRes.fdfString };
      }
    }

    // ========================= no encryption flow ============================
    if (encryptedContract.encrypted === EncryptMethod.DEFAULT && account) {
      const encryptionKey = '';
      setAESKey(encryptionKey);

      const response = await fetchTxOnArweave(encryptedContract.pdfTxId);
      const signatureRes = await fetchTxOnArweave(encryptedContract.signatureTxId);

      // decrypt file data with encryptionKey (decryptedMessage)
      const fileBlob = decryptDataArrayFromStringAES(response.fileStr, encryptionKey);
      const file = new File([fileBlob], 'sample.pdf');

      // decrypt signature data with encryptionKey (decryptedMessage)
      const bytes = CryptoJS.AES.decrypt(signatureRes.fdfString, encryptionKey);
      const signatureString = bytes.toString(CryptoJS.enc.Utf8);

      dispatch(
        updateContract({
          files: [file],
          fdfString: signatureString,
          isDecrypting: false
        })
      );
      return { success: true, files: [file], signatures: signatureString };
    }

    // close decrypt modal in layout
    dispatch(setDecryptStatus(false));
    return { success: false };
  };

  return <DecryptContext.Provider value={{ AESKey, decryptContract }} {...props} />;
};

const useDecrypt = () => {
  const hookData = useContext(DecryptContext);
  if (!hookData) throw new Error('Hook used without provider');
  return hookData;
};

export { DecryptProvider, useDecrypt };
