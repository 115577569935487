import { ReactNode } from 'react';

export interface IStepCardProps {
  icon: ReactNode;
  title: string;
  description: string;
}

const StepCard = ({ icon, title, description }: IStepCardProps): JSX.Element => {
  return (
    <div className="font-circularMedium text-base text-gray-600 flex flex-col items-center px-4 py-8 bg-white rounded-2xl mx-2 max-w-[200px] xl:max-w-[210px] 2xl:max-w-[240px]">
      {icon}
      <p className="font-bold my-4">{title}</p>
      <p className="font-circular font-normal text-center">{description}</p>
    </div>
  );
};

export default StepCard;
