import { FC, ButtonHTMLAttributes, useRef, useState } from 'react';
import classNames from 'classnames';

import { EyeIcon, PenIcon, TriIcon } from 'components/Icons';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { RecipientType } from 'types/Recipient';

export const RECIPIENT_DATA = {
  [RecipientType.SIGNER]: {
    icon: PenIcon,
    label: 'Signer'
  },
  [RecipientType.VIEWER]: {
    icon: EyeIcon,
    label: 'Viewer'
  }
};

interface ITypeOptionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  option: RecipientType;
  active?: boolean;
}

const TypeOptionButton = ({ className, option, active, ...props }: ITypeOptionButtonProps) => {
  const Icon = RECIPIENT_DATA[option].icon;
  return (
    <button className={classNames('w-full p-2 flex items-center border border-gray-300', className)} {...props}>
      <span className="mr-1 w-[1.375rem] h-[1.375rem] flex justify-content items-center">
        <Icon className="text-gray-400" />
      </span>
      <span className="flex-1 text-p5 text-gray-600 text-left">{RECIPIENT_DATA[option].label}</span>
      {active ? <TriIcon className="mr-2 text-gray-400" /> : null}
    </button>
  );
};

interface IRecipientTypeSelectProps {
  value: RecipientType;
  onChange?: (type: RecipientType) => void;
}

export const RecipientTypeSelect: FC<IRecipientTypeSelectProps> = ({ value, onChange }) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  useOnClickOutside(ref, () => setOpen(false));

  const handleChange = (value: RecipientType) => {
    setOpen(false);
    onChange?.(value);
  };

  const rest = value === RecipientType.SIGNER ? RecipientType.VIEWER : RecipientType.SIGNER;

  return (
    <div ref={ref} className="relative min-w-[8.5rem]">
      <TypeOptionButton
        className={classNames({
          'rounded-[0.625rem]': !open,
          'rounded-t-[0.625rem] border-b-0': open
        })}
        option={value}
        active
        onClick={() => setOpen(true)}
      />
      {open ? (
        <TypeOptionButton
          className="absolute left-0 bg-white rounded-b-[0.625rem]"
          option={rest}
          onClick={() => handleChange(rest)}
        />
      ) : null}
    </div>
  );
};
