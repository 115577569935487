import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ContractDataTable } from 'components/ContractTable';
import { QuickAccessCard } from 'components/QuickAccessCard';
import { SkeletonCard } from 'components/Skeletons/SkeletonCard';
import { SkeletonContractTable } from 'components/Skeletons/SkeletonContractTable';
import { RootState, useAppDispatch } from 'store';
import { useWeb3React } from '@web3-react/core';
import { setOpenLoginModal } from 'store/userSlice';
import { Contract, EncryptMethod } from 'types/Contract';
import {
  fetchContractsByAddress,
  RecentContractsSelector,
  savePendingTransaction,
  updateContract
} from 'store/contractSlice';
import { clearTxState, updateTxState } from 'store/txStateSlice';
import { ArchivingStatus } from 'components/TxState/StateItem';
import { useWebViewer, useToast, useDecrypt } from 'hooks';
import Welcome from './WelcomeSection';
import { POLYGON_CHAIN_ID } from 'utils/constants';
import { showSignProgress } from 'utils/sign';

const Dashboard = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { active, account, chainId } = useWeb3React();
  const contractsState = useSelector((state: RootState) => state.contracts);
  const {
    list: contracts,
    filtered,
    searchDisplay,
    loading,
    current,
    passwordEncryption,
    oneTapEncryption
  } = contractsState;
  const { showError } = useToast();
  const { status } = useSelector((state: RootState) => state.txState);
  const { instance, downloadPdf } = useWebViewer();
  const { decryptContract } = useDecrypt();

  const quickAccessContracts = RecentContractsSelector(contractsState);

  const isPolygonChain = chainId === POLYGON_CHAIN_ID;

  useEffect(() => {
    if (!active) {
      dispatch(clearTxState());
    } else {
      const hasEncryption =
        passwordEncryption || oneTapEncryption || !!(current && current?.encrypted !== EncryptMethod.DEFAULT);

      showSignProgress(status, hasEncryption, dispatch, account);
    }
  }, [status, active, account]);

  useEffect(() => {
    if (account) {
      dispatch(fetchContractsByAddress(account));
    } else {
      dispatch(setOpenLoginModal(true));
    }
  }, [account]);

  useEffect(() => {
    instance?.UI.closeDocument();
    instance?.Core.documentViewer.closeDocument();
    dispatch(updateContract({ passPhase: '' }));
    dispatch(savePendingTransaction(null));
  }, []);

  const inActiveDisplay = () => {
    return (
      <>
        <h2 className="py-4 font-nexa font-bold text-gray-600 text-xl mt-6">Contracts</h2>
        <ContractDataTable contracts={[]} openLoginModal={() => dispatch(setOpenLoginModal(true))} />
      </>
    );
  };

  const emptyDisplay = () => {
    return <Welcome onStarted={() => navigate('/create')} />;
  };

  const loadingDisplay = () => {
    return (
      <>
        <h2 className="py-4 font-nexa font-bold text-gray-600 text-xl mt-6">Quick Access</h2>
        <div className="flex">
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </div>
        <h2 className="py-4 font-nexa font-bold text-gray-600 text-xl mt-6">Contracts</h2>
        <SkeletonContractTable />
      </>
    );
  };

  const handleDownload = async (item: Contract) => {
    if (!active) {
      return;
    }

    dispatch(
      updateTxState({
        title: 'Contract Download',
        items: [
          {
            status: ArchivingStatus.PROCESSING,
            title: 'Downloading file'
          }
        ],
        currentItem: 'Downloading file'
      })
    );

    try {
      const { success, files, signatures } = await decryptContract(item.id, true);

      if (!success || !files || !signatures) {
        return;
      }

      await downloadPdf(account || '', files[0], item.name, signatures);

      dispatch(
        updateTxState({
          title: 'Contract Download',
          items: [
            {
              status: ArchivingStatus.SUCCESS,
              title: 'Contract Downloaded'
            }
          ],
          currentItem: 'Contract Downloaded'
        })
      );
    } catch (err) {
      dispatch(
        updateTxState({
          title: 'Download Failed',
          items: [
            {
              status: ArchivingStatus.FAILED,
              title: 'Downloading file'
            }
          ],
          currentItem: 'Downloading file'
        })
      );
      showError((err as Error).message);
      return;
    }
  };

  const handleGoEditing = (item: Contract) => {
    navigate(`/recipients?id=${item.id}`);
  };

  const handleDecryptContract = async (item: Contract) => {
    dispatch(updateContract({ current: item }));
    const { success } = await decryptContract(item.id);
    if (success) {
      navigate(`/sign/${item.id}`);
    }
  };

  const handleGoSign = (item: Contract) => {
    navigate(`/sign/${item.id}`);
  };

  const activeDisplay = () => {
    return searchDisplay ? (
      <>
        <h2 className="py-4 text-body-bold text-gray-600 mt-6">Search Results</h2>
        {filtered.length > 3 ? (
          <>
            <ContractDataTable contracts={filtered} onDownloadContract={handleDownload} />
          </>
        ) : (
          <div className="flex">
            {filtered.map((el: Contract) => (
              <QuickAccessCard
                key={el.id}
                item={el}
                onDownload={() => handleDownload(el)}
                onGoEditing={() => handleGoEditing(el)}
                onGoSign={() => handleGoSign(el)}
                onDecryptContract={() => handleDecryptContract(el)}
              />
            ))}
          </div>
        )}
      </>
    ) : (
      <>
        {quickAccessContracts.length > 0 && (
          <>
            <h2 className="py-4 font-nexa font-bold text-gray-600 text-xl mt-6">Quick Access</h2>
            <div className="flex">
              {quickAccessContracts.map((el: Contract) => (
                <QuickAccessCard
                  key={el.id}
                  item={el}
                  onDownload={() => handleDownload(el)}
                  onGoEditing={() => handleGoEditing(el)}
                  onDecryptContract={() => handleDecryptContract(el)}
                  onGoSign={() => handleGoSign(el)}
                />
              ))}
            </div>
          </>
        )}

        <h2 className="py-4 font-nexa font-bold text-gray-600 text-xl mt-6">Contracts</h2>
        <ContractDataTable contracts={contracts} onDownloadContract={handleDownload} />
      </>
    );
  };

  return (
    <div>
      {!active && !isPolygonChain && <Welcome onStarted={() => dispatch(setOpenLoginModal(true))} />}
      {!active && isPolygonChain && inActiveDisplay()}
      {loading && loadingDisplay()}
      {!loading && active && isPolygonChain && (contracts.length > 0 ? activeDisplay() : emptyDisplay())}
    </div>
  );
};

export default Dashboard;
