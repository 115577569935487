import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sumBy } from 'lodash';

import { Button, ButtonVariant } from 'components/Button';
import FileUploadArea from 'components/FileUploadArea';
import { FileCard } from 'components/FileCard';
import { updateContract } from 'store/contractSlice';
import { RootState } from 'store';
import { getFileName, MAX_FILE_SIZE } from 'utils/file-utils';
import { useToast } from 'hooks';
import { FileCardDragWrapper } from './FileCardDragWrapper';

const CreateContract = (): JSX.Element => {
  const dispatch = useDispatch();
  const { openToast } = useToast();
  const navigateTo = useNavigate();

  const savedFiles = useSelector((state: RootState) => state.contracts.files);

  const [files, setFiles] = useState<File[]>(savedFiles);

  const onNext = () => {
    dispatch(updateContract({ files, contractName: getFileName(files[0]) }));
    navigateTo('/recipients');
  };

  const handleSwapFile = (first: number, second: number) => {
    setFiles((prev) => {
      const temp = [...prev];
      [temp[first], temp[second]] = [temp[second], temp[first]];
      return temp;
    });
  };

  const handleDeleteFile = (index: number) => {
    setFiles((prev) => prev.filter((_, idx: number) => idx !== index));
  };

  const handleUploadContract = (newFiles: File[]) => {
    const filteredFiles = newFiles.filter((newFile) => !files.some((f) => f.name === newFile.name));
    const fileSize = sumBy(files.concat(filteredFiles), 'size');
    if (fileSize > MAX_FILE_SIZE * 1024 * 1024) {
      openToast('error', `The files combined exceed the size limit of ${MAX_FILE_SIZE}mb.`, 'Upload Error');
    } else {
      setFiles(files.concat(filteredFiles));
    }
  };

  return (
    <div className="lg:col-span-3 mt-2 mb-4">
      <h1 className="text-heading3 text-gray-600 mb-8">Upload Contract</h1>
      <FileUploadArea onUpload={handleUploadContract} />
      <h2 className="text-heading3 text-gray-600 pb-5">Uploaded Contracts</h2>
      {files.length > 0 ? (
        <div className="w-full grid grid-cols-2 gap-x-4 gap-y-3 relative max-h-[24rem] overflow-y-auto">
          {files.map((el: File, index: number) => (
            <FileCardDragWrapper key={`${el.name}-${el.size}`} data={{ id: el.name, index }} onSwap={handleSwapFile}>
              <FileCard file={el} onDelete={() => handleDeleteFile(index)} />
            </FileCardDragWrapper>
          ))}
        </div>
      ) : (
        <div className="text-p5 py-20 text-center text-gray-500">There are no contracts uploaded yet!</div>
      )}
      <div className="pt-10">
        <div className="absolute right-10 bottom-10 flex justify-end w-full">
          <Button variant={ButtonVariant.PRIMARY} disabled={files.length === 0} onClick={onNext}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateContract;
