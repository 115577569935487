import { SVGProps } from 'react';

export const KeyIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="1.25rem" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.5416 8.33333C9.85831 6.39167 8.00831 5 5.83331 5C3.07498 5 0.833313 7.24167 0.833313 10C0.833313 12.7583 3.07498 15 5.83331 15C8.00831 15 9.85831 13.6083 10.5416 11.6667H14.1666V15H17.5V11.6667H19.1666V8.33333H10.5416ZM5.83331 11.6667C4.91665 11.6667 4.16665 10.9167 4.16665 10C4.16665 9.08333 4.91665 8.33333 5.83331 8.33333C6.74998 8.33333 7.49998 9.08333 7.49998 10C7.49998 10.9167 6.74998 11.6667 5.83331 11.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};
