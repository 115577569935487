import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonSize, ButtonVariant } from 'components/Button';
import { addRecipient, updateContract } from 'store/contractSlice';
import { BarStatus } from 'components/StatusBar';
import { RootState } from 'store';
import { RecipientType } from 'types/Recipient';
import { User } from 'types/User';

import { RecipientInput } from './RecipientInput';
import { RecipientList } from './RecipientList';
import { useRegistryContract } from 'hooks';
import { truncateAddress } from 'utils/utils';

const Recipients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { recipients } = useSelector((state: RootState) => state.contracts);
  const { getRegistryKey } = useRegistryContract();

  useEffect(() => {
    dispatch(updateContract({ status: BarStatus.MANAGE }));
  }, []);

  // Add user if not exist in the recipients
  const handleAddRecipients = (users: User[]) => {
    users.forEach(async (user) => {
      const index = recipients.findIndex((recipient) => recipient.user.address === user.address);
      if (index === -1) {
        // look up for public key registered if exists
        const key = await getRegistryKey(user.address);
        dispatch(
          addRecipient({
            type: RecipientType.SIGNER,
            order: 0,
            user: {
              ...user,
              nickname: user?.alias || truncateAddress(user?.address),
              oneTapEnabled: !!key,
              regKey: key
            },
            signed: false
          })
        );
      }
    });
  };

  const handleNext = () => {
    navigate('/prepare-document');
  };

  const handleBack = () => {
    navigate('/create');
  };

  const isRecipientsReady = useMemo(() => {
    return recipients.filter(({ type }) => type === RecipientType.SIGNER).length > 0;
  }, [recipients]);

  return (
    <div>
      <h2 className="mb-10 text-heading2 text-gray-600">Manage Recipients</h2>
      <RecipientInput recipients={recipients} onAddUsers={handleAddRecipients} />
      <div className="mt-20">
        <div className="mb-6 flex items-center">
          <h3 className="mr-4 text-heading3 text-gray-600">Recipients</h3>
        </div>
        <div className="space-x-10 flex flex-row w-full">
          <RecipientList type={RecipientType.SIGNER} draggable />
          <RecipientList type={RecipientType.VIEWER} draggable />
        </div>
      </div>
      <div className="mt-24 flex justify-end space-x-4 absolute right-10 bottom-10 w-full">
        <Button variant={ButtonVariant.TERTIARY} size={ButtonSize.CTA} onClick={handleBack}>
          Back
        </Button>
        <Button
          size={ButtonSize.CTA}
          variant={ButtonVariant.PRIMARY}
          disabled={!isRecipientsReady}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Recipients;
