import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './json/en.json';
import jp from './json/jp.json';
import zh from './json/zh.json';

export const resources = {
  en: {
    translation: en
  },
  jp: {
    translation: jp
  },
  zh: {
    translation: zh
  }
} as const;

i18n.use(initReactI18next).init({
  lng: 'en',
  interpolation: {
    escapeValue: false
  },
  resources
});
