import { createSlice } from '@reduxjs/toolkit';

import { Item } from 'components/TxState';
import { ContractProgress } from 'types';

export interface TxState {
  currentItem: string;
  isEnabled: boolean;
  items: Item[];
  title: string;
  status: ContractProgress;
}

const txStateSlice = createSlice({
  name: 'tx-state',
  initialState: { currentItem: '', isEnabled: false, items: [], title: '', status: ContractProgress.NONE } as TxState,
  reducers: {
    setCurrentItem(state, action) {
      state.currentItem = action.payload;
    },
    setIsEnabled(state, action) {
      state.isEnabled = action.payload;
    },
    setItems(state, action) {
      state.items = action.payload;
    },
    setTitle(state, action) {
      state.title = action.payload;
    },
    updateTxContractProgress(state, action) {
      state.status = action.payload;
    },
    updateTxState(state, action) {
      state.title = action.payload.title;
      state.items = action.payload.items;
      state.currentItem = action.payload.currentItem;
      state.isEnabled = true;
    },
    clearTxState(state) {
      state.title = '';
      state.items = [];
      state.currentItem = '';
      state.isEnabled = false;
      state.status = ContractProgress.NONE;
    }
  }
});

export const {
  setCurrentItem,
  setIsEnabled,
  setItems,
  setTitle,
  updateTxState,
  updateTxContractProgress,
  clearTxState
} = txStateSlice.actions;

export const txStateReducer = txStateSlice.reducer;
