import { SVGProps } from 'react';

export const CrossIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.9199 8.5H8.91992V14.5H6.91992V8.5H0.919922V6.5H6.91992V0.5H8.91992V6.5H14.9199V8.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
