export const DiscordSettingIcon = ({ ...props }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.035 0H3.19933C1.94826 0 0.929688 0.92 0.929688 2.06V15.58C0.929688 16.72 1.94826 17.64 3.19933 17.64H15.7543L15.1675 15.79L16.5847 16.98L17.9243 18.1L20.3047 20V2.06C20.3047 0.92 19.2861 0 18.035 0ZM13.7615 13.06C13.7615 13.06 13.3629 12.63 13.0308 12.25C14.4811 11.88 15.0347 11.06 15.0347 11.06C14.5808 11.33 14.149 11.52 13.7615 11.65C13.2079 11.86 12.6765 12 12.1561 12.08C11.2079 12.2377 10.2355 12.2343 9.28862 12.07C8.73274 11.9718 8.18798 11.8278 7.66112 11.64C7.3834 11.5435 7.11336 11.4299 6.8529 11.3C6.81969 11.28 6.78647 11.27 6.75326 11.25C6.73112 11.24 6.72004 11.23 6.70897 11.22C6.50969 11.12 6.39897 11.05 6.39897 11.05C6.39897 11.05 6.9304 11.85 8.33647 12.23C8.00433 12.61 7.59469 13.06 7.59469 13.06C5.1479 12.99 4.2179 11.54 4.2179 11.54C4.2179 8.32 5.81219 5.71 5.81219 5.71C7.40647 4.63 8.92326 4.66 8.92326 4.66L9.03397 4.78C7.04112 5.3 6.12219 6.09 6.12219 6.09C6.12219 6.09 6.36576 5.97 6.7754 5.8C7.96004 5.33 8.90112 5.2 9.28862 5.17C9.35504 5.16 9.4104 5.15 9.47683 5.15C11.3967 4.91529 13.3493 5.24487 15.0458 6.09C15.0458 6.09 14.1711 5.34 12.289 4.82L12.444 4.66C12.444 4.66 13.9608 4.63 15.555 5.71C15.555 5.71 17.1493 8.32 17.1493 11.54C17.1493 11.54 16.2083 12.99 13.7615 13.06Z"
        fill="currentColor"
      />
    </svg>
  );
};
