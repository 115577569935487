import { KeyboardEvent } from 'react';
import { SearchIcon } from 'components/Icons/SearchIcon';
import { Contract } from 'types/Contract';
import TextField from 'components/TextField';
import './index.scss';
import { useState } from 'react';

interface SearchProps {
  query?: string;
  disabled?: boolean;
  results?: Contract[];
  onSearch?: () => void;
  onSearchTerms?: (value: string) => void;
  goToSignPage: (e: Contract) => void;
}

export const Search = ({
  query = '',
  onSearch,
  onSearchTerms,
  disabled = false,
  results,
  goToSignPage
}: SearchProps) => {
  const [openSearchResults, setOpenSearchResults] = useState(false);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onSearch) {
      onSearch();
      setOpenSearchResults(false);
    }
  };

  const handleOnChange = (value: string) => {
    setOpenSearchResults(true);
    onSearchTerms && onSearchTerms(value);
  };

  const handleGoSign = (e: Contract) => {
    goToSignPage(e);
  };

  return (
    <div className="relative inline-block w-full max-w-[75%]">
      <TextField
        leftIcon={<SearchIcon />}
        disabled={disabled}
        fullWidth={true}
        type="search"
        name="search"
        value={query}
        placeholder="Search contract name and status"
        onKeyDown={handleKeyDown}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e.target.value)}
      />
      {openSearchResults && (
        <div
          className="origin-top-left z-30 absolute w-full left-0 mt-2 rounded-2xl shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
          style={{ display: query ? 'block' : 'none' }}
        >
          <div
            className="group flex items-center justify-between px-7 py-3 text-sm"
            role="menuitem"
            tabIndex={-1}
            id="menu-item-0"
          >
            <span className="font-lato text-gray-500 text-lg font-normal">
              Search for: <b>{query}</b>
            </span>
            <span className="font-lato text-gray-500 text-sm font-normal">
              Press: <b>ENTER</b>
            </span>
          </div>
          {results &&
            results.map((item: Contract) => {
              return (
                <div
                  key={item.id}
                  className="group flex flex-col px-7 py-4 text-sm cursor-pointer hover:bg-orange-100"
                  role="menuitem"
                  tabIndex={-1}
                  id={item.id}
                  onClick={() => handleGoSign(item)}
                >
                  <span className="font-lato text-gray-500 text-lg font-normal">{item.name}</span>
                  <span className="font-lato text-gray-500 font-normal">
                    in <span className="capitalize">{item.meta.status.toLowerCase()}</span>
                  </span>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
