import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, ButtonColor } from 'components/Button';
import { Modal, ModalContent, IModalProps } from 'components/Modal';
import { Contract, EncryptMethod } from 'types/Contract';
import { updateContract } from 'store/contractSlice';
import { useDecrypt, useWebViewer } from 'hooks';
import { useWeb3React } from '@web3-react/core';
import TextField from 'components/TextField';
import { useNavigate } from 'react-router-dom';
import { updateTxState } from 'store/txStateSlice';
import { ArchivingStatus } from 'components/TxState/StateItem';

interface IDecryptModalProps extends IModalProps {
  item: Contract;
  hasCloseButton?: boolean;
}

export const DecryptModal = ({ item, onClose, hasCloseButton = true, ...rest }: IDecryptModalProps) => {
  const dispatch = useDispatch();
  const { decryptContract } = useDecrypt();
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { active, account } = useWeb3React();
  const navigate = useNavigate();
  const { downloadPdf } = useWebViewer();

  const handleInputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && password) {
      onDecrypt();
    } else {
      return;
    }
  };

  const onDecrypt = async () => {
    setIsLoading(true);

    try {
      if (!active || !item?.pdfTxId || !item.signatureTxId) {
        throw new Error();
      }

      const { success, files, signatures } = await decryptContract(item.id, item.signed, password);

      if (success) {
        dispatch(updateContract({ passPhase: password }));

        if (!item.signed) {
          navigate(`/sign/${item.id}`);
          return;
        }

        if (!files || !signatures) {
          return;
        }

        downloadPdf(account || '', files[0], item.name, signatures);

        dispatch(
          updateTxState({
            title: 'Contract Download',
            items: [
              {
                status: ArchivingStatus.SUCCESS,
                title: 'Contract Downloaded'
              }
            ],
            currentItem: 'Contract Downloaded'
          })
        );

        onClose?.();
      } else {
        setError('Incorrect Password');
        setIsLoading(false);
      }
    } catch (err) {
      setError('Incorrect Password');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal {...rest} onClose={onClose}>
      <ModalContent hasCloseButton={hasCloseButton}>
        <div className="md:px-12 w-[616px]">
          <div className="text-center">
            <h3 className="text-p1 text-heading2 text-gray-600" id="modal-title">
              Decrypt Contract
            </h3>
            {item.encrypted === EncryptMethod.ONETAP && (
              <div className="mt-4">
                <p className="text-body text-gray-500">This contract is 1-tap encrypted.</p>
                <p className="text-body text-gray-500">Sign Metamask popup to decrypt Contract.</p>
              </div>
            )}
            {item.encrypted === EncryptMethod.PASSWORD && (
              <div className="mt-4">
                <p className="text-body text-gray-500">Input password to decrypt the contract.</p>
              </div>
            )}
          </div>
          {item.encrypted === EncryptMethod.PASSWORD && (
            <div className="text-body mt-5 sm:mt-6">
              <div className="flex mx-auto relative flex-row">
                <div>
                  <TextField
                    autoComplete="new-password"
                    additionalClasses="md:min-w-[400px]"
                    name="contractPassword"
                    placeholder="Password..."
                    type="password"
                    value={password}
                    error={error}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPassword(e.target.value);
                      setError('');
                    }}
                    onKeyDown={handleInputOnKeyDown}
                  />
                </div>
                <Button
                  type="button"
                  color={ButtonColor.ORANGE}
                  className="ml-3 h-[54px]"
                  disabled={isLoading || password === ''}
                  onClick={onDecrypt}
                >
                  {isLoading ? 'Decrypting...' : 'Decrypt'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};
