import { Button, ButtonSize, ButtonVariant } from 'components/Button';
import { Modal, ModalContent, IModalProps } from 'components/Modal';
import { useNavigate } from 'react-router-dom';

export const ProcessingSignatureModal = ({ ...rest }: IModalProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <Modal {...rest}>
      <ModalContent hasCloseButton={false} className="w-1/2">
        <div className="md:px-12">
          <div className="text-center">
            <h3 className="text-heading2 text-gray-600" id="modal-title">
              Processing Signature
            </h3>
            <div className="mt-4 mb-6">
              <p className="text-body text-gray-500">
                The processing will take a couple of minutes, please do not leave EthSign site before the process is
                completed.
              </p>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <div className="mx-auto relative flex justify-center">
              <Button
                type="button"
                variant={ButtonVariant.PRIMARY}
                className="ml-3"
                size={ButtonSize.CTA}
                onClick={handleClick}
              >
                Back to Dashboard
              </Button>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
