import { useEffect, createContext, FC, useState, useContext } from 'react';
import { isEmpty } from 'lodash';
import { Button, ButtonVariant } from 'components/Button';

interface ToastContextProps {
  isActive: boolean;
  message: string;
  toastType: string;
  title?: string;
  CTA?: JSX.Element;
  openToast: (toastType: string, message: string, title?: string, customCTA?: JSX.Element) => void;
  showError: (message: string) => void;
  closeToast: () => void;
}

export const ToastContext = createContext<ToastContextProps>({
  isActive: false,
  message: '',
  toastType: 'warning',
  openToast: () => console.log('Open Toast'),
  closeToast: () => console.log('Close Toast'),
  showError: () => console.log('Something went wrong')
});

export const ToastProvider: FC = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [toastType, setType] = useState<string>('warning');
  const [title, setTitle] = useState<string>();
  const [CTA, setCTA] = useState<JSX.Element>();

  useEffect(() => {
    if (!isEmpty(message)) {
      setIsActive(true);
      setTimeout(() => {
        setIsActive(false);
        setMessage('');
      }, 5000);
    }
  }, [message]);

  const openToast = (type = 'warning', msg = 'Something went wrong...', title?: string, customCTA?: JSX.Element) => {
    setMessage(msg);
    setType(type);
    setTitle(title);
    setCTA(customCTA);
  };

  const showError = (msg = 'Something went wrong...') => {
    setMessage(msg);
    setType('error');
    setCTA(
      <Button
        className="w-fit !border-0 !px-0 !mt-1 !min-w-0 !font-bold !p-0 !text-red-200"
        variant={ButtonVariant.OUTLINED}
        onClick={() => navigator.clipboard.writeText(msg)}
      >
        Copy
      </Button>
    );
  };

  const closeToast = () => {
    setIsActive(false);
  };

  return (
    <ToastContext.Provider value={{ isActive, message, toastType, openToast, closeToast, showError, title, CTA }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const ctx = useContext(ToastContext);

  if (!ctx) {
    console.warn(
      "You probably want to render this Toast inside a ToastProvider. If that's not the expected behavior, please ignore this message."
    );
  }

  return ctx;
};
