import React, { ChangeEvent, LegacyRef, useRef } from 'react';

import DragAndDrop from 'components/DragAndDrop';
import { FileIcon } from 'components/Icons';

const ACCEPT_FILE_EXTENSION = '.pdf';
const PDF_FILE_TYPE = 'application/pdf';

interface IProps {
  onUpload: (files: File[]) => void;
}

type InputUploadElement = Omit<HTMLInputElement, 'value'> & {
  value: string | null;
};

const FileUploadArea = (props: IProps) => {
  const { onUpload } = props;
  const fileUploadRef = useRef<HTMLInputElement>();

  const handleDrop = (files: FileList) => {
    const pdfFiles = Array.from(files).filter((file) => file.type === PDF_FILE_TYPE);

    onUpload(pdfFiles);
  };

  const hasAvailableAndValidFiles = (e: ChangeEvent<HTMLInputElement>): boolean => {
    return !!(e.target?.files && e.target?.files.length > 0);
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const hasFiles = hasAvailableAndValidFiles(e);

    if (!hasFiles) {
      return;
    }

    const files = Object.values(e.target?.files as unknown as File[]);
    onUpload(Array.from(files).filter((file) => file.type === PDF_FILE_TYPE));
    (fileUploadRef.current as InputUploadElement).value = null;
  };

  const handleFileInputClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    fileUploadRef.current?.click();
  };

  return (
    <DragAndDrop handleDrop={handleDrop} onClick={handleFileInputClick}>
      <FileIcon />
      <p className="text-body text-gray-600 mt-3 mb-7">Drop your contract here</p>
      <div className="relative border border-grey-400 w-[26rem]">
        <div className="absolute inset-x-1/2 -translate-x-2/4 -top-3 text-body text-gray-500 w-[5rem] flex justify-center bg-orange-100">
          OR
        </div>
      </div>
      <input
        ref={fileUploadRef as LegacyRef<HTMLInputElement>}
        hidden
        multiple
        accept={ACCEPT_FILE_EXTENSION}
        className="cursor-pointer"
        id="contained-button-file"
        type="file"
        onChange={handleFileUpload}
      />
      <label className="text-body mt-7 text-white bg-orange-700 hover:bg-orange-800 px-6 py-2 min-w-[6.85rem] rounded-[0.6875rem] cursor-pointer">
        Browse Files
      </label>
    </DragAndDrop>
  );
};

export default FileUploadArea;
