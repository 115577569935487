import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { PencilIcon, TrashIcon } from 'components/Icons';
import { getPDFPageCount, getStringFileSize } from 'utils/file-utils';

interface IProps {
  file: File;
  onEdit?: (e: string) => void;
  onDelete: () => void;
}

export const FileCard = ({ file, onEdit, onDelete }: IProps) => {
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isReadingAttributes, setIsReadingAttributes] = useState<boolean>(true);
  const [filePageCount, setFilePageCount] = useState<string>('');
  const [fileSize, setFileSize] = useState<string>('');

  useEffect(() => {
    const asyncMountFunction = async () => {
      const pageCount = await getPDFPageCount(file);
      const size = getStringFileSize(file);
      let stringPageCount = `${pageCount.toString()} page`;

      if (pageCount !== 1) {
        stringPageCount += 's';
      }

      setFilePageCount(stringPageCount);
      setFileSize(size);
      setIsReadingAttributes(false);
    };

    asyncMountFunction();
  }, []);

  const handleToggleEditing = () => {
    setEditing(!isEditing);
  };

  const handleInputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      setEditing(false);
    } else {
      return;
    }
  };

  const handleInputOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onEdit) {
      onEdit(e.target.value);
      setEditing(false);
    }
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onEdit) {
      onEdit(e.target.value);
    }
  };

  const pencilIconDisplay = onEdit && (
    <PencilIcon className="text-gray-300 hover:text-gray-400 cursor-pointer" onClick={handleToggleEditing} />
  );
  const fileDisplay = isEditing ? (
    <input
      type="text"
      name="file-name"
      className={classNames('rounded-xl pl-2 pr-2 py-1 text-body border-0 bg-gray-100 w-full')}
      style={{ boxShadow: 'none' }}
      value={file.name}
      onKeyDown={handleInputOnKeyDown}
      autoFocus
      onBlur={handleInputOnBlur}
      onChange={handleInputOnChange}
    />
  ) : (
    <>
      <span className="text-body mr-2 text-gray-600 overflow-hidden whitespace-nowrap max-w-md py-1">{file.name}</span>
      {pencilIconDisplay}
    </>
  );
  const fileAttributesDisplay = isReadingAttributes ? (
    '...'
  ) : (
    <>
      <span className="mr-3">{filePageCount}</span>
      {fileSize}
    </>
  );

  return (
    <div className="flex items-center p-6 border border-gray-100 bg-white rounded-xl relative">
      <img className="max-w-[2.5rem]" src="/img/pdf.png" alt="PDF" />
      <div className="flex flex-col flex-1 items-start ml-7 max-w-[80%]">
        <div className="flex items-center w-11/12">{fileDisplay}</div>
        <span className="text-caption text-gray-500">{fileAttributesDisplay}</span>
        <TrashIcon
          className="absolute top-[40%] right-6 cursor-pointer text-gray-300 hover:text-gray-400"
          onClick={onDelete}
        />
      </div>
    </div>
  );
};
