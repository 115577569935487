import { createContext, FC, useState, useMemo, useContext } from 'react';
import { v4 } from 'uuid';

interface ModalContextProps {
  openedModals: Array<string>;
  registerModal(): string;
  unregisterModal(id: string): void;
}

export const ModalContext = createContext<Partial<ModalContextProps>>({
  registerModal: () => ''
});

export const ModalProvider: FC = ({ children }) => {
  const [registeredModals, setRegisteredModals] = useState<Array<string>>([]);

  const modalContext = useMemo(() => {
    const registerModal = () => {
      const newModalId = v4();
      setRegisteredModals((registeredModals) => {
        return [...registeredModals, newModalId];
      });
      return newModalId;
    };

    const unregisterModal = (id: string) => {
      setRegisteredModals((registeredModals) => {
        const modalIdx = registeredModals.findIndex((m) => m === id);
        if (modalIdx) {
          const modals = [...registeredModals];
          modals.splice(modalIdx, 1);
          return modals;
        }
        return registeredModals;
      });
    };

    return { registerModal, unregisterModal };
  }, []);

  return (
    <ModalContext.Provider value={{ ...modalContext, openedModals: registeredModals }}>
      {children}
    </ModalContext.Provider>
  );
};

export function useModal() {
  const ctx = useContext(ModalContext);

  if (!ctx) {
    console.warn(
      "You probably want to render this modal inside a ModalProvider. If that's not the expected behavior, please ignore this message."
    );
  }

  return ctx;
}
