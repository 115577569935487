import { FC, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Header } from 'components/Header';
import { SideBar } from 'components/SideBar';
import { TxState } from 'components/TxState';
import { BarStatus, StatusBar } from 'components/StatusBar';

import { RootState } from 'store';
import { setTheme } from 'store/userSlice';
import { setSearchTerms, setSearchDisplay, setDecryptStatus, updateContract } from 'store/contractSlice';

import { routes, IRoute } from 'layouts/routes';
import { SignerStatusBar } from 'pages/Sign/sections/SignerStatusbar';
import { useEthSignContract, useWebViewer } from 'hooks';
import { Contract } from 'types/Contract';
import { LoadSpinner } from 'components/LoadSpinner';
import { DecryptModal } from 'components/DecryptModal';
import { POLYGON_CHAIN_ID } from 'utils/constants';
import PolygonSwitch from 'components/PolygonSwitch';

const CREATE_PAGE_INDEX = 2;

// TODO: handle routers more specific way

const CREATE_CONTRACT_PATHS = [
  routes[2].url, // Upload document
  routes[3].url, // Manage recipients
  routes[4].url, // Prepare document
  routes[5].url // Review and Send
];

const MainLayout: FC = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigator = useNavigate();
  const { active, account, chainId } = useWeb3React();
  const isWrongNetwork = useMemo(() => chainId && chainId !== POLYGON_CHAIN_ID, [chainId]);

  const pdfRef = useRef<HTMLDivElement>(null);

  const { contract } = useEthSignContract();
  const { instance, initializeWebInstance, loadDocument, mergeDocument } = useWebViewer();

  const route = routes.find((el: IRoute) => el.url === location.pathname);

  const { darkTheme, alias } = useSelector((state: RootState) => state.user);
  const { status, current, inProgress, searchTerms, filtered, files, isDecrypting } = useSelector(
    (state: RootState) => state.contracts
  );

  const createPageRoute = routes[CREATE_PAGE_INDEX];

  const showPdfViewer = useMemo(
    () => ['/prepare-document', '/sign'].some((path) => location.pathname.startsWith(path)),
    [location.pathname]
  );

  const canPerformRedirect = useMemo(
    () => ['/create', '/recipients', '/prepare-document', '/review'].some((path) => location.pathname.startsWith(path)),
    [location.pathname]
  );

  useEffect(() => {
    if (canPerformRedirect && (!account || !contract || !instance)) {
      navigator('/');
    }
  }, [account, contract, instance]);

  const handleNavigateToCreatePage = () => {
    navigator(createPageRoute.url);
    dispatch(updateContract({ status: BarStatus.UPLOAD, inProgress: true, files: [], recipients: [] }));
  };

  const onSwitchTheme = () => {
    dispatch(setTheme(!darkTheme));
  };

  const handleSearchTerm = (value: string) => {
    dispatch(setSearchDisplay(false));
    dispatch(setSearchTerms(value));
  };

  const handleSearch = () => {
    dispatch(setSearchDisplay(true));
  };

  const goToSignPage = (el: Contract) => {
    navigator(`/sign/${el.id}`);
  };

  useEffect(() => {
    if (!instance && pdfRef.current && !pdfRef.current?.children.length) {
      initializeWebInstance(pdfRef.current);
    }
  }, [instance, pdfRef.current]);

  const mountFiles = async () => {
    if (!instance || files.length === 0) return;

    await loadDocument(files[0]);
    for (const file of files.slice(1)) {
      await mergeDocument(file);
    }
  };

  useEffect(() => {
    mountFiles();
  }, [files]);

  useEffect(() => {
    if (!instance) return;
    const annotationManager = instance.Core.annotationManager;
    annotationManager.setCurrentUser(alias || account || '');
  }, [instance, alias, account]);

  const headerDisplay = route?.hasHeader && !isWrongNetwork && (
    <Header
      onSwitchTheme={onSwitchTheme}
      query={searchTerms}
      results={filtered}
      onSearchTerms={handleSearchTerm}
      onSearch={handleSearch}
      disabled={!active}
      goToSignPage={goToSignPage}
    />
  );

  const sidebarDisplay = !active ? (
    <div className="flex-1 flex flex-col max-w-[75%]" />
  ) : inProgress && active && CREATE_CONTRACT_PATHS.includes(route?.url || '') ? (
    <StatusBar status={status} />
  ) : location.pathname.startsWith('/sign') ? (
    <SignerStatusBar />
  ) : (
    <div className="flex-1 flex flex-col max-w-[75%]">
      {contract ? (
        <div className="flex flex-col cursor-pointer" onClick={handleNavigateToCreatePage}>
          <div className="flex items-center space-between rounded-3xl bg-orange-100 hover:bg-orange-300 group px-6 py-4">
            <span className="text-gray-600 text-body-bold">Upload Contract</span>
            <div className="w-12 h-12 bg-orange-700 rounded-full flex items-center justify-center px-4">
              <p className="text-4xl leading-none text-orange-100 group-hover:text-orange-300 pb-1">+</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col cursor-not-allowed">
          <div className="flex items-center space-between rounded-3xl bg-gray-100 px-6 py-4">
            <span className="text-gray-300 text-body-bold">Upload Contract</span>
            <div className="w-12 h-12 bg-gray-300 rounded-full flex items-center justify-center px-4">
              <p className="text-4xl leading-none text-gray-100 pb-1">+</p>
            </div>
          </div>
        </div>
      )}
      <div className="mt-4 3xl:mt-14 mb-0 3xl:mb-2">
        {routes
          ?.filter((e: IRoute) => e.isMenu && e.title !== 'Settings')
          .map((el: IRoute) => (
            <div key={el.url} className="flex items-center py-5 cursor-pointer" onClick={() => navigator(el.url)}>
              <el.icon
                className={classNames(
                  { 'text-orange-800': location.pathname === el.url },
                  { 'text-gray-400': location.pathname !== el.url }
                )}
              />
              <span
                className={classNames(
                  'text-body ml-4',
                  { 'text-orange-800': location.pathname === el.url },
                  { 'text-gray-400': location.pathname !== el.url }
                )}
              >
                {el.title}
              </span>
            </div>
          ))}
      </div>
    </div>
  );

  if (route?.notInLayout) {
    return <div className="w-full">{children}</div>;
  }

  return (
    <>
      {active && !contract && !isWrongNetwork && (
        <div className="fixed z-20 w-full h-screen top-0 flex flex-col justify-center items-center">
          <div className="absolute w-full h-screen bg-gray-200 opacity-50" />
          <LoadSpinner />
          <span className="text-body text-gray-600">Loading...</span>
        </div>
      )}
      <div
        className={classNames(
          'min-h-screen flex w-full relative',
          { 'bg-background': !darkTheme },
          { 'bg-darkLayout': darkTheme }
        )}
      >
        <div className="fixed z-10">
          <SideBar>{sidebarDisplay}</SideBar>
        </div>
        <div
          className={classNames('relative min-h-screen w-full pl-[19.5rem]', {
            'pr-[3.5rem] py-[3.5rem]': !showPdfViewer
          })}
        >
          {headerDisplay}
          <div className={classNames({ 'h-full': showPdfViewer, 'h-0': !showPdfViewer })}>
            <div ref={pdfRef} className="h-full"></div>
          </div>
          {!isWrongNetwork ? children : <PolygonSwitch />}
        </div>
        <TxState />
      </div>
      {current && (
        <DecryptModal
          shouldCloseOnEsc={false}
          shouldCloseOnBackdropClick={false}
          hasCloseButton={true}
          isOpen={isDecrypting}
          item={current}
          onClose={() => {
            dispatch(setDecryptStatus(false));
          }}
        />
      )}
    </>
  );
};

export { MainLayout };
