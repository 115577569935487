export const STORAGE_KEY_LOGIN_TYPE = 'login-type';

export const NETWORKS = ['ethereum', 'ropsten', 'bsc', 'avalanche', 'polygon', 'fantom'];

export const DISCORD_LINK = 'https://discord.gg/RVSm2n3q';
export const DISCORD_INVITE_LINK = 'https://discord.com/invite/Wvhp9dWdSg';
export const TWITTER_LINK = 'https://www.twitter.com/ethsign';
export const TUTORIAL_LINK = 'https://docs.ethsign.xyz/ethsign/';
export const ETHSIGN3_LINK = 'https://legacy.ethsign.xyz/';
export const DEFAULT_EXPIRY = 1; // Default Expiry is 1 day.
export const MAX_TABLE_ITEMS = 7; //Maximum items on a table.

export const POLYGON_CHAIN_ID = 137;

export const UPLOAD_SIGNATURE_STATEMENT =
  'EthSign is requesting your signature to validate the data being uploaded. This action does not incur any gas fees.';
export const SIGNATURE_VERSION = '4.1';
export const NUMBER_OF_CONFIRMATIONS = 4;
export const GAS_LIMIT = 500000;
