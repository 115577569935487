/* eslint-disable @typescript-eslint/no-explicit-any */
import { SVGProps } from 'react';
import { AbstractConnector } from '@web3-react/abstract-connector';

export enum ProviderType {
  INJECTED = 'injected',
  TORUS = 'torus',
  WALLETCONNECT = 'walletConnect',
  WALLETLINK = 'walletLink',
  SAFE = 'safe'
}

export enum Providers {
  METAMASK = 'MetaMask',
  TORUS = 'Social Media',
  COINBASE_WALLET = 'Coinbase Wallet',
  WALLETCONNECT = 'WalletConnect'
}

export interface Provider {
  id: string;
  name: string;
  type: ProviderType;
  description?: string;
  connector: AbstractConnector;
  logos?: ((props: SVGProps<SVGSVGElement>) => JSX.Element)[];
}

export class RPCError extends Error {
  code = 0;
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, RPCError.prototype);
  }
}

export type ExternalProvider = {
  isMetaMask?: boolean;
  isStatus?: boolean;
  host?: string;
  path?: string;
  sendAsync?: (request: { method: string; params?: Array<any> }, callback: (error: any, response: any) => void) => void;
  send?: (request: { method: string; params?: Array<any> }, callback: (error: any, response: any) => void) => void;
  request?: (request: { method: string; params?: Array<any> }) => Promise<any>;
};
