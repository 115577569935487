import classNames from 'classnames';
import { ArrowIcon } from 'components/Icons';
import { MenuWrapper } from 'components/Menu';
import { IMenu } from 'components/Menu/MenuItem';
import { TreeMenu } from 'components/TreeMenu';
import { useState, useEffect } from 'react';
import _ from 'lodash';

interface IProps {
  menuData: IMenu[];
  filter?: string[];
  setFilter: (e: string[]) => void;
}

export const StatusFilter = ({ menuData, filter, setFilter }: IProps) => {
  const [data, setData] = useState<IMenu[]>([]);

  useEffect(() => {
    setData(menuData);
  }, []);

  useEffect(() => {
    const resFilter: string[] = [];

    data.forEach((el) => {
      if ((!el.children || el.children.length < 1) && el.current) {
        resFilter.push(el.name);
      } else if (el.children && el.children?.length > 0) {
        el.children.forEach((_el) => {
          if (_el.current) {
            resFilter.push(_el.name);
          }
        });
      }
    });
    if (!_.isEqual(resFilter, filter)) {
      setFilter(resFilter);
    }
  }, [data]);

  return (
    <MenuWrapper header={<ArrowIcon className={classNames('table-head-sort-icon')} />}>
      <TreeMenu data={data} setData={setData} />
    </MenuWrapper>
  );
};
