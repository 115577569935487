export const contractSignedEvent = (id: string): string => `
  query {
    events(
      where: {contract: "${id}"}
      orderBy: timestamp
      orderDirection: desc
      first: 1
    ) {
      id
      timestamp
      type
      involvedEntity
      rawDataHash
      contract {
        id
        name
        birth
        expiry
        initiator
        signers
        steps
        rawDataHash
        signedSigners
        viewers
        signed
        metadata
      }
    }
  }`;

export const contractCompletedEvent = (id: string): string => `
  query {
    events(
      where: {type: SignerSigned, contract: "${id}"}
      orderBy: timestamp
      orderDirection: desc
      first: 1
    ) {
      id
      timestamp
      type
      involvedEntity
      rawDataHash
      contract {
        id
        name
        birth
        expiry
        initiator
        signers
        steps
        rawDataHash
        signedSigners
        viewers
        signed
        metadata
      }
    }
  }`;

export const contracts = (address: string): string => `
  query {
    contracts(where: { signers_contains: ["${address}"] }
    orderBy: birth
    orderDirection: desc
  ) {
      id
      name
      rawDataHash
      birth
      expiry
      initiator
      signers
      steps
      signedSigners
      viewers
      signed
      metadata
    }
  }`;

export const viewerContracts = (address: string): string => `
  query {
    contracts(where: { viewers_contains: ["${address}"] }
    orderBy: birth
    orderDirection: desc
  ) {
      id
      name
      rawDataHash
      birth
      expiry
      initiator
      signers
      steps
      signedSigners
      viewers
      signed
      metadata
    }
  }`;

export const getMetrics = (): string => `
  {
    generalInfos(first: 1) {
      signaturesMade
      contractsSigned
      totalUsers
    }
  }`;

export const getSignatures = (): string => `
  {
    infos(first: 1) {
      totalSignaturesSigned
    }
  }`;

export const getPolygonSignatures = (): string => `
  {
    infos(first: 1, block: { number: 31908763 }) {
      totalSignaturesSigned
    }
  }`;
