export const PencilIcon = ({ ...props }) => {
  return (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.771 4.58475C17.3321 4.02386 18.093 3.70884 18.8864 3.70898C19.6798 3.70913 20.4406 4.02444 21.0015 4.58553C21.5623 5.14663 21.8774 5.90756 21.8772 6.70093C21.8771 7.4943 21.5618 8.25511 21.0007 8.816L19.8554 9.95975L15.6225 5.73163L16.7694 4.58475H16.771ZM14.521 6.83631L5.21474 16.1394C4.93226 16.4221 4.71936 16.7666 4.59286 17.1457L3.16474 21.4316C3.11911 21.5692 3.11264 21.7167 3.14603 21.8577C3.17942 21.9987 3.25137 22.1276 3.35382 22.23C3.45628 22.3325 3.58521 22.4044 3.72621 22.4378C3.86721 22.4712 4.01471 22.4648 4.15224 22.4191L8.43817 20.991C8.81786 20.866 9.16161 20.6519 9.44442 20.3691L18.7507 11.066L14.5194 6.83631H14.521Z"
        fill="currentColor"
      />
    </svg>
  );
};
