export const LayoutIcon = ({ ...props }) => {
  return (
    <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x="4.13916"
        y="4.78467"
        width="13.7778"
        height="17.4444"
        rx="2.30556"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <rect
        x="1.0835"
        y="1.72998"
        width="13.7778"
        height="17.4444"
        rx="2.30556"
        fill="white"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
