/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Biconomy } from '@biconomy/mexa';
import { ExternalProvider } from 'types';
import { useWeb3React } from '@web3-react/core';

interface IBiconomyContext {
  biconomy: any;
  isBiconomyReady: boolean;
}

const BICONOMY_API_KEY = process.env.REACT_APP_BICONOMY_API_KEY;
const ETHSIGN_CONTRACT_SIGN_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_SIGN_ADDRESS;
const ETHSIGN_ONETAP_REGISTRY_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_REGISTER_ADDRESS;

const BiconomyContext = createContext<IBiconomyContext | null>(null);

const BiconomyProvider: React.FC = (props) => {
  const { connector, active } = useWeb3React();

  const [isBiconomyReady, setIsBiconomyReady] = useState(false);
  const [biconomy, setBiconomy] = useState<any>(null);

  useEffect(() => {
    (async () => {
      try {
        const provider = await connector?.getProvider();
        if (active && provider) {
          const instance = new Biconomy(provider as ExternalProvider, {
            apiKey: BICONOMY_API_KEY,
            debug: true,
            contractAddresses: [ETHSIGN_CONTRACT_SIGN_ADDRESS, ETHSIGN_ONETAP_REGISTRY_ADDRESS]
          });
          await instance.init();

          setBiconomy(instance);
          setIsBiconomyReady(true);
        }
      } catch (error) {
        setIsBiconomyReady(false);
      }
    })();
  }, [active]);

  return (
    <BiconomyContext.Provider
      value={{
        isBiconomyReady,
        biconomy
      }}
      {...props}
    />
  );
};

const useBiconomy = () => {
  const hookData = useContext(BiconomyContext);
  if (!hookData) throw new Error('Hook used without provider');
  return hookData;
};

export { BiconomyProvider, useBiconomy };
