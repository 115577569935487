import { useWeb3React } from '@web3-react/core';
import { useEagerConnect } from 'hooks/useEagerConnect';
import { chainNames } from 'utils/chains-list';
import NetworkImage from '../../assets/images/network.svg';

const PolygonSwitch = () => {
  const { switchToPolygon } = useEagerConnect();
  const { chainId } = useWeb3React();

  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <div>
        <img className="h-[20rem]" src={NetworkImage} alt="" />
      </div>
      <div className="block text-heading2 text-gray-600 mt-12 mb-4">Your wallet is connected to the wrong network</div>
      <p className="text-gray-600 text-body mb-6">
        The app is currently set to{' '}
        <span className="text-orange-500">{chainId !== undefined && chainNames[chainId].name}</span>.
      </p>
      <div>
        <button
          type="button"
          className="min-w-[13.75rem] min-h-[3.125rem] inline-flex justify-center items-center text-white bg-orange-700 hover:bg-orange-800 disabled:bg-orange-300 px-4 py-2 w-full rounded-[0.625rem] text-body"
          onClick={switchToPolygon}
        >
          Switch to Polygon
        </button>
      </div>
    </div>
  );
};

export default PolygonSwitch;
