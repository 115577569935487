import classNames from 'classnames';
import { EyeIcon } from 'components/Icons';
import React, { HTMLInputTypeAttribute, InputHTMLAttributes, useEffect, useState } from 'react';

export enum TextFieldSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small'
}

interface ITextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  autoComplete?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  additionalClasses?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  label?: string | JSX.Element;
  error?: string;
  success?: string;
  type: HTMLInputTypeAttribute;
  elementSize?: TextFieldSize;
}

const TextField = ({
  autoComplete = 'off',
  disabled = false,
  fullWidth = false,
  additionalClasses,
  elementSize = TextFieldSize.SMALL,
  leftIcon,
  rightIcon,
  label,
  onFocus,
  onBlur,
  onChange,
  error,
  success,
  type,
  ...rest
}: ITextFieldProps): JSX.Element => {
  const [isFocus, setIsFocus] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  useEffect(() => {
    setDirty(!!error);
  }, [error]);

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(true);
    setDirty(true);
    onFocus && onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(false);
    onBlur && onBlur(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    onChange && onChange(e);
  };

  return (
    <div className="flex flex-col">
      {label && <label className="mb-2 text-gray-500 text-body">{label}</label>}
      <div
        className={classNames(
          'flex justify-between items-center px-4 py-3 border border-solid hover:border-orange-600 rounded-lg bg-white',
          { 'cursor-not-allowed': disabled },
          { 'w-fit': !fullWidth },
          { 'w-full': fullWidth },
          { 'border-gray-100': !isFocus && !error && !success },
          { 'border-orange-600': isFocus && !error && !success },
          { 'border-red-200 hover:border-red-200': error },
          { 'border-green-200 hover:border-green-200': success },
          additionalClasses
        )}
      >
        <div className="flex items-center w-full">
          {leftIcon}
          <input
            autoComplete={autoComplete}
            className={classNames(
              'p-0 w-full text-gray-600 text-body outline-none focus:outline-none focus:ring-transparent border-none placeholder-gray-300',
              {
                'text-base': elementSize === TextFieldSize.SMALL
              },
              {
                'text-md': elementSize === TextFieldSize.MEDIUM
              },
              {
                'text-lg': elementSize === TextFieldSize.LARGE
              },
              {
                'ml-4': leftIcon
              }
            )}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
            type={isPasswordVisible ? 'text' : type}
            {...rest}
          />
        </div>
        {type === 'password' && (
          <EyeIcon className="cursor-pointer text-gray-300" onClick={handleTogglePasswordVisibility} />
        )}
        {rightIcon}
      </div>
      {isDirty && error && <span className="mt-2 text-caption text-red-200">{error}</span>}
      {success && <span className="mt-2 text-caption text-green-200">{success}</span>}
    </div>
  );
};

export default TextField;
