import { ChangeEvent, FC, KeyboardEvent, useState } from 'react';
import { KeyIcon, PencilIcon, TrashIcon } from 'components/Icons';
import classNames from 'classnames';

import { Recipient, RecipientType } from 'types/Recipient';
import { RecipientTypeSelect, RECIPIENT_DATA } from './RecipientTypeSelect';

import { truncateAddress } from 'utils/utils';
import { useLocation } from 'react-router-dom';

interface RecipientCardProps {
  className?: string;
  recipient: Recipient;
  editable?: boolean;
  onUpdate?: (id: string, data: Partial<Recipient>) => void;
  onRemove?: (recipient: Recipient) => void;
}

export const RecipientCard: FC<RecipientCardProps> = ({ className, recipient, editable, onRemove, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [nickname, setNickname] = useState('');
  const location = useLocation();
  const isOnReview = location.pathname.includes('review');

  const handleNicknameInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onUpdate?.(recipient.id, { user: { ...recipient.user, nickname } });
      setNickname('');
      setIsEditing(false);
    }
  };

  const handleNicknameInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNickname(e.target.value);
  };

  const handleNicknameInputBlur = () => {
    onUpdate?.(recipient.id, { user: { ...recipient.user, nickname } });
    setNickname('');
    setIsEditing(false);
  };

  const handleEditButtonClick = () => {
    setNickname(recipient.user?.nickname || '');
    setIsEditing(true);
  };

  const handleTypeChange = (type: RecipientType) => {
    onUpdate?.(recipient.id, { type });
  };

  const Icon = RECIPIENT_DATA[recipient.type].icon;

  return (
    <div
      className={classNames(
        className,
        'relative p-4 bg-white border border-gray-100 rounded-[1.25rem] overflow-hidden flex-auto max-h-[180px]',
        { 'max-w-[283px]': isOnReview }
      )}
    >
      <div className="flex items-center relative h-[56px]">
        <div className="w-[3.5rem] h-[3.5rem] rounded-full bg-gray-300 absolute top-0">
          <img src={'/avatar.png'} alt="Avatar" className="w-fit h-fit" />
        </div>
        <div className="pl-16 pr-[135px] flex items-center w-full">
          {!isEditing ? (
            <div className="flex items-center">
              <div
                className={classNames('text-heading3 text-gray-600 truncate', {
                  'max-w-[169px]': isOnReview
                })}
              >
                {recipient.user?.nickname || '(Not Set)'}
              </div>
              {editable ? (
                <button
                  className="w-6 h-6 ml-2 flex items-center justify-center text-gray-300 hover:text-gray-500"
                  onClick={handleEditButtonClick}
                >
                  <PencilIcon className="w-[1.25rem] h-[1.25rem] text-gray-300" />
                </button>
              ) : null}
            </div>
          ) : (
            <input
              className={classNames(
                'px-[0.4375rem] py-[0.3125rem] bg-gray-100 text-heading3 text-gray-600 rounded-[0.625rem] outline-none placeholder:text-p5',
                { 'bg-white border-2 border-orange-700': !nickname }
              )}
              placeholder="Name"
              value={nickname}
              onKeyDown={handleNicknameInputKeyDown}
              onChange={handleNicknameInputChange}
              onBlur={handleNicknameInputBlur}
            />
          )}
        </div>
      </div>
      <div className="mt-6 text-body text-gray-500 space-y-4">
        <div className="truncate">{truncateAddress(recipient.user?.address, 7) || 'Not Set'}</div>
        <div>{recipient.user?.email}</div>
        {recipient.user.oneTapEnabled ? (
          <div className="flex item-center">
            <KeyIcon className="mr-2 text-green-200" />
            <div className="text-caption ">1-Tap Encryption Enabled</div>
          </div>
        ) : (
          <div className="flex item-center">
            <KeyIcon className="mr-2 text-gray-200" />
            <div className="text-caption ">1-Tap Encryption Disabled</div>
          </div>
        )}
      </div>

      {editable ? (
        <div>
          <div className="absolute top-4 right-4">
            <RecipientTypeSelect value={recipient.type} onChange={handleTypeChange} />
          </div>

          <div className="absolute right-4 bottom-7">
            <button
              className="w-6 h-6 flex items-center justify-center text-gray-300 hover:text-gray-500"
              onClick={() => onRemove?.(recipient)}
            >
              <TrashIcon />
            </button>
          </div>
        </div>
      ) : (
        <div className="absolute top-4 right-4">
          <Icon className="text-gray-400" />
        </div>
      )}
    </div>
  );
};
