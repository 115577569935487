export const PrepareIcon = ({ ...props }): JSX.Element => (
  <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M40.8305 9.22681L29.6372 31.5303C29.5337 31.7372 29.5025 31.9749 29.5545 32.1922L30.6819 37.8509L30.0303 39.2784C29.7922 39.8062 30.0199 40.4162 30.5373 40.6543C30.6719 40.7162 30.8269 40.7474 30.9715 40.7474C31.3543 40.7474 31.737 40.5301 31.9024 40.1473L32.5648 38.7407L37.613 35.9166C37.7888 35.8131 37.9338 35.6581 38.0269 35.472L47.4094 16.7685C47.5852 16.8927 47.7198 17.0685 47.7921 17.2859C47.906 17.5755 47.8852 17.8859 47.7609 18.1652L43.9332 26.441C43.6951 26.9584 43.9228 27.5789 44.4402 27.817C44.5853 27.8789 44.7299 27.91 44.8745 27.91C45.2676 27.91 45.6399 27.6927 45.8157 27.31L49.6434 19.0237C49.995 18.2478 50.0365 17.3789 49.7365 16.5719C49.478 15.8683 48.9918 15.2891 48.3502 14.9064L49.2191 13.1685C49.2191 13.1581 49.2295 13.1477 49.2295 13.1373C49.7469 12.0099 49.7984 10.7582 49.3745 9.59964C48.9403 8.44108 48.0917 7.51017 46.9643 6.99279C44.6372 5.92686 41.8854 6.94084 40.8302 9.22679L40.8305 9.22681Z"
      fill="currentColor"
    />
    <path
      d="M4.02395 47.9998H32.9997C35.2134 47.9998 37.024 46.1896 37.024 43.9656V38.6173L34.1587 40.2205L33.776 41.0276C33.3209 42.0931 32.2139 42.8173 30.9726 42.8173C30.5176 42.8173 30.0725 42.7242 29.6694 42.5381C28.1281 41.8241 27.4454 39.9828 28.1489 38.4311L28.5212 37.6037L27.528 32.597C27.383 31.9762 27.4661 31.2419 27.7865 30.6007L37.0244 12.1975V4.03549C37.0244 1.81147 35.2142 0.00129885 33.0001 0.00129885L4.02433 0C1.81061 0 0 1.81017 0 4.03419V43.9649C0.000432924 46.1894 1.81061 48 4.02433 48L4.02395 47.9998ZM7.69671 8.63762H29.3274C29.9067 8.63762 30.3617 9.10304 30.3617 9.67193C30.3617 10.2512 29.9067 10.7063 29.3274 10.7063L7.69671 10.7067C7.11742 10.7067 6.66239 10.2516 6.66239 9.67237C6.66196 9.10347 7.11743 8.63762 7.69671 8.63762V8.63762ZM7.69671 14.8756H29.3274C29.9067 14.8756 30.3617 15.341 30.3617 15.9099C30.3617 16.4788 29.9067 16.9442 29.3274 16.9442L7.69671 16.9446C7.11742 16.9446 6.66239 16.4792 6.66239 15.9103C6.66196 15.341 7.11743 14.8756 7.69671 14.8756ZM7.69671 21.1032H29.3274C29.9067 21.1032 30.3617 21.5686 30.3617 22.1375C30.3617 22.7168 29.9067 23.1718 29.3274 23.1718L7.69671 23.1722C7.11742 23.1722 6.66239 22.7172 6.66239 22.1379C6.66196 21.5686 7.11743 21.1032 7.69671 21.1032ZM7.69671 27.3411H23.3068C23.8861 27.3411 24.3411 27.8065 24.3411 28.3754C24.3411 28.9443 23.8861 29.4098 23.3068 29.4098H7.69671C7.11742 29.4098 6.66239 28.9443 6.66239 28.3754C6.66196 27.8065 7.11743 27.3411 7.69671 27.3411ZM7.69671 33.5786H23.3068C23.8861 33.5786 24.3411 34.0336 24.3411 34.6129C24.3411 35.1818 23.8861 35.6472 23.3068 35.6472L7.69671 35.6477C7.11742 35.6477 6.66239 35.1823 6.66239 34.6134C6.66196 34.0341 7.11743 33.5786 7.69671 33.5786ZM7.69671 39.8062H24.2068C24.7861 39.8062 25.2411 40.2717 25.2411 40.8406C25.2411 41.4095 24.7861 41.8749 24.2068 41.8749H7.69671C7.11742 41.8749 6.66239 41.4095 6.66239 40.8406C6.66196 40.2717 7.11743 39.8062 7.69671 39.8062Z"
      fill="currentColor"
    />
  </svg>
);
