export const CopyIcon = ({ ...props }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.043 1H4.04297C2.94297 1 2.04297 1.9 2.04297 3V17H4.04297V3H16.043V1ZM15.043 5H8.04297C6.94297 5 6.05297 5.9 6.05297 7L6.04297 21C6.04297 22.1 6.93297 23 8.03297 23H19.043C20.143 23 21.043 22.1 21.043 21V11L15.043 5ZM8.04297 21V7H14.043V12H19.043V21H8.04297Z"
        fill="currentColor"
      />
    </svg>
  );
};
