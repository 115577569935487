import { FC } from 'react';
import classNames from 'classnames';

import { ACTION_BUTTONS, Button, ButtonSize, ButtonVariant } from 'components/Button';
import { LockIcon } from 'components/Icons';
import { Contract, EncryptMethod, ContractStatus } from 'types/Contract';

import ContractPlaceholder from '../../assets/images/contract_placeholder.svg';
import { useDispatch } from 'react-redux';
import { updateContract } from 'store/contractSlice';
import { STATUS_FILTERS } from 'components/ContractTable';

interface IQuickAccessCardProps {
  item: Contract;
  onDownload?: () => void;
  onGoEditing?: () => void;
  onGoSign?: () => void;
  onDecryptContract?: () => void;
}

export const QuickAccessCard: FC<IQuickAccessCardProps> = ({ item, onDecryptContract, onDownload, onGoSign }) => {
  const dispatch = useDispatch();

  const handleClick = async () => {
    dispatch(updateContract({ passPhase: '', current: undefined }));

    if (item.meta.status === ContractStatus.COMPLETED || item.meta.status === ContractStatus.EXPIRED) {
      onDownload && onDownload();
      return;
    }

    switch (item.encrypted) {
      case EncryptMethod.ONETAP: {
        onDecryptContract?.();
        return;
      }
      case EncryptMethod.PASSWORD: {
        onDecryptContract?.();
        return;
      }
      default:
        onGoSign && onGoSign();
        return;
    }
  };

  return (
    <>
      <div className="p-4 max-w-[23rem] text-body text-gray-600 rounded-2xl bg-white mr-7">
        <div className="mb-3 flex justify-between items-center">
          <h5>{item?.name}</h5>
        </div>
        <div className="max-h-[8rem] mb-4 rounded-xl border border-gray-100 overflow-hidden relative">
          <img src={ContractPlaceholder} alt="thumbnail" />
          {item?.encrypted > 0 && (
            <div className="absolute w-full h-full flex items-center justify-center top-0 bg-white/50">
              <LockIcon />
            </div>
          )}
        </div>
        <div className="p-3 flex justify-between items-center rounded-xl bg-background">
          <div className="flex flex-col text-body leading-4 text-gray-500">
            <div className="flex items-center text-gray-600 mr-2">
              <div
                className={classNames(
                  'w-2 min-w-[.5rem] h-2 rounded-lg mr-2',
                  ACTION_BUTTONS[item.meta.status].twColor
                )}
              />
              {STATUS_FILTERS[item.meta.status]}
            </div>
          </div>
          <Button
            type="button"
            variant={ACTION_BUTTONS[item.meta.status].variant as ButtonVariant}
            size={ButtonSize.CARD}
            onClick={handleClick}
          >
            {ACTION_BUTTONS[item.meta.status].button}
          </Button>
        </div>
      </div>
    </>
  );
};
