/* eslint-disable @typescript-eslint/no-explicit-any */
import { WebViewerInstance, Core } from '@pdftron/webviewer';

import { Recipient, AnnotationField, AnnotationOptions, AllAnnotationFields } from 'types';
import {
  getAnnotationContent,
  getSignerColor,
  getAnnotContentWidth,
  ANNOTATION_SUBJECT_PREFIX,
  drawRoundRect,
  getSignerCursor
} from 'utils/pdfviewer';
import { parseJSON } from 'utils/utils';

const NativeMath = Math;
const ANNOTATION_MIN_WIDTH = 40;
const ANNOTATION_MIN_HEIGHT = 24;
const ANNOTATION_HANDLE_COUNT = 4;

export const createSignatureField = (instance: WebViewerInstance, signers: Recipient[]): Promise<WebViewerInstance> => {
  const { Core, UI } = instance;
  const { documentViewer, annotationManager, Annotations, Tools, Math } = Core;

  class EthsignControlHandle extends Annotations.ControlHandle {
    index: number;

    constructor(index: number) {
      super(0, 0, 0, 0);
      this.index = index;
    }
    getDimensions(annotation: EthsignAnnotation, selectionBox: Core.Math.Rect, zoom: number) {
      let x = annotation.vertices[this.index].x;
      let y = annotation.vertices[this.index].y;

      const width = Annotations.ControlHandle.handleWidth / zoom;
      const height = Annotations.ControlHandle.handleHeight / zoom;
      x -= width * 0.5;
      y -= height * 0.5;
      return new Math.Rect(x, y, x + width, y + height);
    }
    move(annotation: EthsignAnnotation, deltaX: number, deltaY: number) {
      const temp = annotation.vertices.map((vertex) => vertex.clone());
      annotation.vertices[this.index].x += deltaX;
      annotation.vertices[this.index].y += deltaY;

      if (this.index == 0) {
        annotation.vertices[1].y += deltaY;
        annotation.vertices[3].x += deltaX;
      } else if (this.index == 1) {
        annotation.vertices[0].y += deltaY;
        annotation.vertices[2].x += deltaX;
      } else if (this.index == 2) {
        annotation.vertices[3].y += deltaY;
        annotation.vertices[1].x += deltaX;
      } else if (this.index == 3) {
        annotation.vertices[2].y += deltaY;
        annotation.vertices[0].x += deltaX;
      }

      const allX = annotation.vertices.map((vertex) => vertex.x);
      const allY = annotation.vertices.map((vertex) => vertex.y);
      const minX = NativeMath.min(Number.MAX_VALUE, ...allX);
      const maxX = NativeMath.max(-Number.MAX_VALUE, ...allX);
      const minY = NativeMath.min(Number.MAX_VALUE, ...allY);
      const maxY = NativeMath.max(-Number.MAX_VALUE, ...allY);

      // Revert change if reaches minimum size of annotation
      if (maxX - minX <= annotation.minWidth || maxY - minY <= annotation.minHeight) {
        annotation.vertices = temp;
        return false;
      }

      annotation.vertices[0].x = minX;
      annotation.vertices[0].y = minY;
      annotation.vertices[1].x = maxX;
      annotation.vertices[1].y = minY;
      annotation.vertices[2].x = maxX;
      annotation.vertices[2].y = maxY;
      annotation.vertices[3].x = minX;
      annotation.vertices[3].y = maxY;

      annotation.setCustomData('vertices', JSON.stringify(annotation.vertices));

      const rect = new Math.Rect(minX, minY, maxX, maxY);
      annotation.setRect(rect);
      return true;
    }
    draw(
      ctx: CanvasRenderingContext2D,
      annotation: EthsignAnnotation,
      selectionBox: Core.Math.Rect,
      zoom: number
    ): void {
      if (typeof zoom === 'undefined') {
        zoom = 1;
      }

      ctx.strokeStyle = Annotations.ControlHandle.outlineColor.toString();
      ctx.fillStyle = Annotations.ControlHandle.color.toString();
      ctx.shadowColor = Annotations.ControlHandle.shadowColor.toString();
      ctx.shadowBlur = Annotations.ControlHandle.shadowBlur;
      ctx.shadowOffsetY = Annotations.ControlHandle.shadowOffsetY;
      ctx.lineWidth = 0;

      const dim = this.getDimensions(annotation, selectionBox, zoom);
      const x = dim.x1;
      const y = dim.y1;
      const width = dim.getWidth();
      const height = dim.getHeight();

      ctx.beginPath();
      ctx.rect(x, y, width, height);
      ctx.stroke();
      ctx.fill();
      ctx.closePath();
    }
  }

  class EthsignSelectionModel extends Annotations.SelectionModel {
    constructor(annotation: EthsignAnnotation, canModify: boolean) {
      super(annotation, canModify, true, documentViewer);
      if (canModify) {
        const controlHandles = this.getControlHandles();
        Array(ANNOTATION_HANDLE_COUNT)
          .fill(0)
          .forEach((_, index) => {
            controlHandles.push(new EthsignControlHandle(index));
          });
      }
    }
    drawSelectionOutline(ctx: CanvasRenderingContext2D, annotation: Core.Annotations.Annotation, zoom: number): void {
      if (typeof zoom !== 'undefined') {
        ctx.lineWidth = Annotations.SelectionModel.selectionOutlineThickness / zoom;
      } else {
        ctx.lineWidth = Annotations.SelectionModel.selectionOutlineThickness;
      }
      if (this.canModify()) {
        ctx.strokeStyle = Annotations.SelectionModel.defaultSelectionOutlineColor.toString();
      } else {
        ctx.strokeStyle = Annotations.SelectionModel.defaultNoPermissionSelectionOutlineColor.toString();
      }
      ctx.beginPath();
      ctx.setLineDash([]);
      ctx.rect(annotation.X, annotation.Y, annotation.Width, annotation.Height);
      ctx.stroke();
      ctx.closePath();
    }
  }

  class EthsignAnnotation extends Annotations.CustomAnnotation {
    vertices: Core.Math.Point[] = [];
    options: AnnotationOptions;
    content = '';
    minWidth = ANNOTATION_MIN_WIDTH;
    minHeight = ANNOTATION_MIN_HEIGHT;
    rotation: number;

    constructor(options: AnnotationOptions) {
      // provide the custom XFDF element name
      super('SigFieldTxt');
      this.StrokeThickness = 0;
      this.Subject = `${ANNOTATION_SUBJECT_PREFIX}-${options.field}-${options.signer.user.address}`;
      this.rotation = documentViewer.getCompleteRotation(documentViewer.getCurrentPage()) * -90;
      this.options = options;
      this.vertices = [];
      for (let i = 0; i < ANNOTATION_HANDLE_COUNT; ++i) {
        this.vertices.push(new Core.Math.Point(this.X, this.Y));
      }
      this.selectionModel = EthsignSelectionModel as any;
      this.initializeData();
    }
    initializeData() {
      this.content = getAnnotationContent(this.options);
      this.minWidth = 16 + getAnnotContentWidth(this.content, 12);
      this.minHeight = 24;
      if (this.options.field === AnnotationField.Signature) {
        this.minHeight += 60;
      } else if (this.options.field === AnnotationField.Checkbox) {
        this.minHeight = 24;
        this.minWidth = 30;
      }
      this.Height = this.minHeight;
      this.Width = this.minWidth;

      this.setCustomData('options', JSON.stringify(this.options));
      this.setCustomData('rotation', this.rotation.toString());
      this.setCustomData('vertices', JSON.stringify(this.vertices));
    }
    serialize(element: Element, pageMatrix: any) {
      this.setCustomData('vertices', JSON.stringify(this.vertices));
      const el = super.serialize(element, pageMatrix);
      return el;
    }
    deserialize(element: Element, pageMatrix: any) {
      super.deserialize(element, pageMatrix);
      const vertices = parseJSON(this.getCustomData('vertices'));
      this.vertices = vertices.map((v: any) => new Core.Math.Point(v.x, v.y));
      this.options = parseJSON(this.getCustomData('options'));
      this.rotation = +this.getCustomData('rotation');

      this.initializeData();
    }
    draw(ctx: CanvasRenderingContext2D, pageMatrix: any) {
      const zoom = documentViewer.getZoomLevel();
      this.setStyles(ctx, pageMatrix);

      const height = this.Height;
      const width = this.Width;
      const topLeft: Core.Math.Point = this.vertices[0].clone();
      topLeft.translate((this.Width - width) / 2, (this.Height - height) / 2);
      const center: Core.Math.Point = topLeft.clone();
      center.translate(width / 2, height / 2);

      const { field, index, required } = this.options;
      const color = getSignerColor(index);

      // begin drawing
      ctx.lineWidth = 1 / zoom;
      if (field !== AnnotationField.Address) {
        ctx.setLineDash([5, 3]);
      }
      drawRoundRect(ctx, topLeft.x, topLeft.y, width, height, 4);

      ctx.fillStyle = color.border;
      ctx.fill();
      if (field !== AnnotationField.Address) {
        ctx.strokeStyle = color.default;
        ctx.stroke();
      }
      ctx.save();

      if (field === AnnotationField.Checkbox) {
        const checkboxSize = 16;
        ctx.strokeStyle = color.default;
        ctx.fillStyle = color.default;
        ctx.setLineDash([]);
        drawRoundRect(ctx, center.x - checkboxSize / 2, center.y - checkboxSize / 2, checkboxSize, checkboxSize, 4);
        ctx.stroke();
      }

      if (field == AnnotationField.Signature) {
        // draw svg
        const svgHeight = 24;
        const path = new Path2D(
          'M2.10284 2.60208C5.0349 -0.326661 9.68119 -0.434473 13.9286 3.80817C17.6135 7.48878 16.787 6.66378 17.9481 7.82346C20.7822 10.6542 21.2477 14.8936 20.9313 17.722L12.5668 9.36658C12.2734 9.07361 11.7981 9.07361 11.5051 9.36658C11.2121 9.65955 11.2121 10.1344 11.5051 10.4274L23.6702 22.5797C24.1099 23.0189 24.1099 23.7314 23.6702 24.1706C23.2305 24.6098 22.5171 24.6098 22.0774 24.1706L18.9789 21.0754C16.5274 21.8118 10.922 21.9272 7.40867 18.5001H12.0114L5.09396 16.1967C2.75066 13.8563 3.38863 14.5008 2.92316 14.0002H7.50664L1.34065 11.947C-0.789371 8.45674 -0.267651 4.97019 2.10284 2.60208Z'
        );
        ctx.fillStyle = color.default;
        ctx.save();
        ctx.font = `12px san-serif`;
        ctx.transform(1, 0, 0, 1, center.x - svgHeight / 2, center.y - svgHeight - 2);
        ctx.fill(path);
        ctx.restore();
      }

      ctx.fillStyle = color.default;
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      ctx.font = `12px san-serif`;
      ctx.rotate((this.rotation / 180) * NativeMath.PI);
      ctx.fillText(this.content, center.x, center.y + (field === AnnotationField.Signature ? 18 : 0));

      // show "asterisk" tag for required fields
      if ([AnnotationField.Signature, AnnotationField.Checkbox, AnnotationField.Text].includes(field) && required) {
        ctx.font = `16px san-serif`;
        ctx.fillText('*', topLeft.x - 8, topLeft.y + 8);
      }
    }

    resize(rect: Core.Math.Rect) {
      const annotationRect = this.getRect();
      const deltaX = rect.x1 - annotationRect.x1;
      const deltaY = rect.y1 - annotationRect.y1;

      this.vertices.forEach((vertex: Core.Math.Point) => {
        vertex.translate(deltaX, deltaY);
      });

      this.setRect(rect);
    }
  }
  // this is necessary to set the elementName before instantiation
  EthsignAnnotation.prototype.elementName = 'SignatureField';
  EthsignAnnotation.SerializationType = Annotations.CustomAnnotation.SerializationTypes.CUSTOM;

  // register the annotation type so that it can be saved to XFDF files
  annotationManager.registerAnnotationType(EthsignAnnotation.prototype.elementName, EthsignAnnotation as any);

  class EthsignAnnotationCreateTool extends Tools.GenericAnnotationCreateTool {
    constructor(documentViewer: Core.DocumentViewer, options: AnnotationOptions) {
      super(documentViewer, EthsignAnnotation as any, options);
    }
    mouseLeftDown(e: any) {
      super.mouseLeftDown(e);
      if (this.annotation) {
        this.annotation.Height = (this.annotation as EthsignAnnotation).minHeight;
        this.annotation.Width = (this.annotation as EthsignAnnotation).minWidth;

        (this.annotation as EthsignAnnotation).vertices[0].x = this.annotation.X;
        (this.annotation as EthsignAnnotation).vertices[0].y = this.annotation.Y;
        (this.annotation as EthsignAnnotation).vertices[1].x = this.annotation.X + this.annotation.Width;
        (this.annotation as EthsignAnnotation).vertices[1].y = this.annotation.Y;
        (this.annotation as EthsignAnnotation).vertices[2].x = this.annotation.X + this.annotation.Width;
        (this.annotation as EthsignAnnotation).vertices[2].y = this.annotation.Y + this.annotation.Height;
        (this.annotation as EthsignAnnotation).vertices[3].x = this.annotation.X;
        (this.annotation as EthsignAnnotation).vertices[3].y = this.annotation.Y + this.annotation.Height;

        this.annotation.setCustomData('vertices', JSON.stringify((this.annotation as EthsignAnnotation).vertices));

        // update the annotation appearance
        annotationManager.redrawAnnotation(this.annotation);
      }
    }
    mouseMove(e: any) {
      super.mouseMove(e);
      if (this.annotation) {
        const initialHeight = (this.annotation as EthsignAnnotation).minHeight;
        const initialWidth = (this.annotation as EthsignAnnotation).minWidth;

        this.annotation.Height = this.annotation.Height + initialHeight;
        this.annotation.Width = this.annotation.Width + initialWidth;

        (this.annotation as EthsignAnnotation).vertices[0].x = this.annotation.X;
        (this.annotation as EthsignAnnotation).vertices[0].y = this.annotation.Y;
        (this.annotation as EthsignAnnotation).vertices[1].x = this.annotation.X + this.annotation.Width;
        (this.annotation as EthsignAnnotation).vertices[1].y = this.annotation.Y;
        (this.annotation as EthsignAnnotation).vertices[2].x = this.annotation.X + this.annotation.Width;
        (this.annotation as EthsignAnnotation).vertices[2].y = this.annotation.Y + this.annotation.Height;
        (this.annotation as EthsignAnnotation).vertices[3].x = this.annotation.X;
        (this.annotation as EthsignAnnotation).vertices[3].y = this.annotation.Y + this.annotation.Height;
        this.cursor = 'none';

        this.annotation.setCustomData('vertices', JSON.stringify((this.annotation as EthsignAnnotation).vertices));

        // update the annotation appearance
        annotationManager.redrawAnnotation(this.annotation);
      }
    }
  }

  class FreeTextCreateTool extends Tools.GenericAnnotationCreateTool {
    hasBeenCreated: boolean;
    constructor(documentViewer: Core.DocumentViewer) {
      super(documentViewer, Core.Annotations.FreeTextAnnotation as any);
      this.hasBeenCreated = false;
    }
    mouseLeftDown(e: any) {
      super.mouseLeftDown(e);
      if (this.annotation) {
        this.annotation.Width = 100;
        this.annotation.Height = 20;
        (this.annotation as any).TextColor = new Annotations.Color(0, 0, 0);

        this.annotation.setContents('read only text field');

        this.annotation.Subject = 'ethsign-readonlytext';
        (this.annotation as any).setAutoSizeType(instance.Core.Annotations.FreeTextAnnotation.AutoSizeTypes.AUTO);
        this.annotation.setCustomData('isRecentlyAdded', '1');
      }
    }
    mouseMove(e: any) {
      super.mouseMove(e);
    }
  }

  const TextField = new FreeTextCreateTool(documentViewer);
  TextField.addEventListener('annotationCreated', (annotation: Core.Annotations.FreeTextAnnotation) => {
    annotationManager.setAnnotationStyles(annotation, {
      Style: 'dash',
      Dashes: '3'
    });
  });
  UI.registerTool(
    {
      toolName: 'text',
      toolObject: TextField,
      buttonImage:
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">' +
        '<path d="M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z"/>' +
        '<path fill="none" d="M0 0h24v24H0V0z"/>' +
        '</svg>',
      buttonName: 'TextFieldToolButton',
      tooltip: 'Read Only Text'
    },
    Core.Annotations.FreeTextAnnotation as any
  );
  documentViewer.getTool('text').setStyles({
    StrokeColor: new Annotations.Color(22, 23, 27),
    TextColor: new Annotations.Color(22, 23, 27),
    Color: new Annotations.Color(22, 23, 27)
  });

  AllAnnotationFields.map((field) => {
    signers.map((signer, index) => {
      const options: AnnotationOptions = {
        field,
        signer,
        index,
        required: [AnnotationField.Checkbox, AnnotationField.Address, AnnotationField.DateSigned].includes(field)
          ? false
          : true
      };
      const EthsignAnnotationTool = new EthsignAnnotationCreateTool(documentViewer, options);
      EthsignAnnotationTool.cursor = getSignerCursor(field, index);

      UI.registerTool(
        {
          toolName: field + '-' + signer.user.address,
          toolObject: EthsignAnnotationTool,
          buttonImage:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">' +
            '<path d="M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z"/>' +
            '<path fill="none" d="M0 0h24v24H0V0z"/>' +
            '</svg>',
          buttonName: 'EthsignAnnotationTool',
          tooltip: 'SignatureField'
        },
        EthsignAnnotation as any
      );
    });
  });

  return Promise.resolve(instance);
};
