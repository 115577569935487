import { useEffect, useState, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types';
import { POLYGON_CHAIN_ID } from 'utils/constants';

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */

const DEFAULT_CHAIN_ID = POLYGON_CHAIN_ID;

const useActiveWeb3React = (): Web3ReactContextInterface => {
  const { library, chainId, ...web3React } = useWeb3React();
  const refEth = useRef(library);
  const [provider, setProvider] = useState(library);

  useEffect(() => {
    if (library !== refEth.current) {
      let provider;

      const hasMultipleProviders = !!library.providers;

      if (hasMultipleProviders) {
        provider = library.providers.find(({ isMetaMask }: { isMetaMask: boolean }) => isMetaMask);
      } else {
        provider = library;
      }

      setProvider(provider);
      refEth.current = provider;
    }
  }, [library]);

  return {
    library: provider,
    chainId: chainId ?? DEFAULT_CHAIN_ID,
    ...web3React
  };
};

export default useActiveWeb3React;
