import { useWeb3React } from '@web3-react/core';
import classNames from 'classnames';
import { KeyboardEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonColor, ButtonVariant } from 'components/Button';

import { RootState } from 'store';
import TextField from 'components/TextField';
import { setEmail } from 'store/userSlice';
import { useToast } from 'hooks';

const AccountSection = () => {
  const { account } = useWeb3React();
  const dispatch = useDispatch();
  const { showError, openToast } = useToast();
  const { email, alias, avatar } = useSelector((state: RootState) => state.user);
  const [shouldEditEmail, setShouldEditEmail] = useState<boolean>(false);

  const avatarPadding = account ? '' : 'p-2';

  const handleCopyWeb3Address = () => {
    if (account) {
      try {
        navigator.clipboard.writeText(account);
        openToast('info', 'Copied Wallet Address');
      } catch (err) {
        showError((err as Error).message);
      }
    }
  };

  const handleOnChangeEmail = (newEmail: string) => {
    dispatch(setEmail(newEmail));
  };

  const handleKeyDownEmail = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      setShouldEditEmail(false);
    }
  };

  const emailAddressDisplay = shouldEditEmail ? (
    <TextField
      additionalClasses="h-1/2 p-2.5"
      name="email"
      placeholder="Email"
      type="email"
      value={email || ''}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChangeEmail(e.target.value)}
      onKeyDown={handleKeyDownEmail}
    />
  ) : (
    email || '-'
  );

  return (
    <div className="flex flex-col bg-white bg-settings-account-background bg-contain bg-no-repeat rounded-[43px] relative min-h-[356px] mb-16">
      <div className="px-10 py-8 w-full">
        <div className="flex items-center">
          <div
            className={classNames(
              'w-[4.125rem] h-[4.125rem] bg-gray-300 rounded-full overflow-hidden flex items-center justify-center',
              avatarPadding
            )}
          >
            {avatar ? (
              <img src={avatar} alt="Avatar" className="w-fit h-fit" />
            ) : (
              <img src={'/avatar.png'} alt="Avatar" className="w-fit h-fit" />
            )}
          </div>
          <h3 className="text-heading3 text-gray-600 text-center ml-8">{alias}</h3>
        </div>
        <div className="space-y-6">
          {account && (
            <div className="flex justify-between items-end mt-9">
              <div className="flex flex-col">
                <span className="text-body-bold text-gray-400">Web3 Address</span>
                <span className="flex-1 mr-4 text-ellipsis overflow-hidden text-gray-600 text-body">
                  {account || 'Not Connected'}
                </span>
              </div>
              <Button
                className="text-white w-fit bg-gray-400"
                variant={ButtonVariant.FILLED}
                color={ButtonColor.GREEN}
                fullWidth={false}
                onClick={() => handleCopyWeb3Address()}
              >
                Copy
              </Button>
            </div>
          )}
          <div className="flex justify-between items-end mt-9">
            <div className="flex flex-col mt-9">
              <span className="text-body-bold text-gray-400">Email Address</span>
              <span className="flex-1 mr-4 text-ellipsis overflow-hidden text-gray-600 text-body">
                {emailAddressDisplay}
              </span>
            </div>
            <Button
              className={classNames('text-white w-fit bg-gray-400', { 'mb-[6px]': shouldEditEmail })}
              variant={ButtonVariant.FILLED}
              color={ButtonColor.GREEN}
              fullWidth={false}
              onClick={() => setShouldEditEmail(!shouldEditEmail)}
            >
              {shouldEditEmail ? 'Save' : 'Edit'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSection;
