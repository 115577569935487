import { Button, ButtonColor, ButtonVariant } from 'components/Button';
import { KeyIcon } from 'components/Icons';
import { useRegistryContract } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import { setOneTapEnabled } from 'store/userSlice';

const KeylessSection = () => {
  const dispatch = useDispatch();
  const { oneTapEnabled } = useSelector((state: RootState) => state.user);
  const { register } = useRegistryContract();

  const handleKeylessChange = async (value?: boolean) => {
    if (value) {
      const result = await register();
      dispatch(setOneTapEnabled({ oneTapEnabled: result }));
    }
  };

  return (
    <div>
      <div className="py-16 flex flex-col max-w-[60%]">
        <h3 className="font-nexa font-bold text-2xl text-gray-600 mb-5">Keyless</h3>
        <p className="font-lato font-normal text-gray-500 mb-5">
          Keyles is an account-locked keyless encryption mechanism. You won’t have to memorize any password for
          keyless-enabled contracts now!
        </p>
        <Button
          className="text-white w-fit py-3 px-6"
          variant={ButtonVariant.FILLED}
          color={ButtonColor.GREEN}
          iconLeft={<KeyIcon />}
          fullWidth={false}
          disabled={oneTapEnabled}
          onClick={() => handleKeylessChange(true)}
        >
          Enable 1-Tap Encryption
        </Button>
      </div>
    </div>
  );
};

export default KeylessSection;
