export interface IMenu {
  id: string;
  icon?: JSX.Element;
  name: string;
  count?: number;
  onClick?: () => void;
  current?: boolean;
  children?: IMenu[];
}

export const MenuItem = ({ icon, name, onClick }: IMenu) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <div
      className="group flex items-center px-4 py-3 text-sm cursor-pointer"
      role="menuitem"
      tabIndex={-1}
      id="menu-item-0"
      onClick={handleClick}
    >
      {icon}
      <span className="text-body text-gray-500 hover:text-gray-600">{name}</span>
    </div>
  );
};
