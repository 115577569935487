import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Core } from '@pdftron/webviewer';

import { Button, ButtonSize, ButtonVariant } from 'components/Button';
import { BarStatus } from 'components/StatusBar';
import { updateContract } from 'store/contractSlice';
import { useWebViewer } from 'hooks';
import { RootState } from 'store';
import { ANNOTATION_SUBJECT_PREFIX, getDefaultTool, getSignerColor } from 'utils/pdfviewer';
import { delay } from 'utils/testing-utils';
import { AnnotationField, RecipientType } from 'types';

const PrepareContract = (): JSX.Element => {
  const dispatch = useDispatch();
  const { addSignerAnnotationTools, removeAnnotationTools, instance, documentStatus } = useWebViewer();
  const navigate = useNavigate();
  const [rendered, setRendered] = useState(false);
  const [isSignatureReady, setIsSignatureReady] = useState(false);

  const signers = useSelector((state: RootState) =>
    state.contracts.recipients.filter(({ type }) => type === RecipientType.SIGNER)
  );

  useEffect(() => {
    dispatch(updateContract({ status: BarStatus.PREPARE }));

    if (instance?.UI) {
      instance.UI.setActiveLeftPanel('toolbarPanel');
    }
  }, []);

  useEffect(() => {
    if (!instance) return;

    setRendered(true);
    addSignerAnnotationTools(signers);
    cleanupAnnotations();
    handleCheckSignatures();

    const { Core, UI } = instance;
    UI.openElements(['leftPanel']);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (UI as any).disableBookmarks();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (UI as any).enableToolbar();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (UI as any).setSigners(signers.map((signer, index) => ({ ...signer, color: getSignerColor(index) })));
    UI.disableElements(['richTextPopup']);

    const { annotationManager, documentViewer } = Core;
    annotationManager.addEventListener('annotationChanged', async (annotations, action, { imported }) => {
      if (!imported) {
        if (action == 'add') {
          await delay(100);
          UI.setToolMode(getDefaultTool());
          annotationManager.selectAnnotations(annotations);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (UI as any).setCursor('default');

          // remove annotations that are not created from Ethsign
          annotations.forEach((annotation: Core.Annotations.Annotation) => {
            if (!annotation.Subject.startsWith(ANNOTATION_SUBJECT_PREFIX)) {
              annotationManager.deleteAnnotation(annotation);
            }
          });
        }
        handleCheckSignatures();
      } else if (action === 'modify') {
        if (annotations.length && annotations[0].Subject === 'ethsign-readonlytext') {
          annotationManager.setAnnotationStyles(annotations[0], {
            StrokeThickness: 1
          });
        }
      }
    });

    annotationManager.addEventListener('annotationSelected', (annotations, action) => {
      const selectedAnnotations = annotationManager.getSelectedAnnotations();
      if (action === 'selected') {
        if (selectedAnnotations.length > 1) {
          UI.setActiveLeftPanel('alignmentPanel');
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (UI as any).setSelectedAnnotationCount(selectedAnnotations.length);
        } else if (selectedAnnotations.length === 1) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const options = (selectedAnnotations[0] as any).options;
          if ([AnnotationField.Checkbox, AnnotationField.Text].includes(options?.field)) {
            UI.setActiveLeftPanel('fieldSettingPanel');
          }

          if (annotations[0].Subject === 'ethsign-readonlytext') {
            const annotContent = annotations[0].getContents();
            annotationManager.setAnnotationStyles(annotations[0], {
              StrokeThickness: 1
            });

            if (annotContent === 'read only text field' && annotations[0].getCustomData('isRecentlyAdded') !== '1') {
              annotations[0].setContents('');
            } else if (annotContent === 'read only text field') {
              annotations[0].setCustomData('isRecentlyAdded', '0');
            } else if (!annotContent) {
              annotations[0].setWidth(100);
            }
          }
        }
      } else if (action === 'deselected') {
        if (selectedAnnotations.length === 0) {
          UI.setActiveLeftPanel('toolbarPanel');
        }

        if (annotations[0].Subject === 'ethsign-readonlytext') {
          if (annotations.length === 1) {
            const annotContent = annotations[0].getContents();

            if (annotContent === '') {
              annotations[0].setContents('read only text field');
            }
          }
        }
      }
    });

    documentViewer.setSearchHighlightColors({
      searchResult: new instance.Core.Annotations.Color(250, 239, 231, 0.5),
      activeSearchResult: 'rgba(250, 239, 231, 0.5)'
    });

    return () => {
      annotationManager.removeEventListener('annotationChanged');
      annotationManager.removeEventListener('annotationSelected');
      removeAnnotationTools(signers);
    };
  }, [instance]);

  const handleNext = () => {
    if (isSignatureReady) {
      navigate('/review');
    }
  };

  const handleBack = () => {
    navigate('/recipients');
  };

  const handleCheckSignatures = () => {
    const annotationSubjects =
      instance?.Core.annotationManager.getAnnotationsList().map((annotation) => annotation.Subject) || [];
    setIsSignatureReady(
      signers.every((signer) =>
        annotationSubjects.includes(`${ANNOTATION_SUBJECT_PREFIX}-${AnnotationField.Signature}-${signer.user.address}`)
      )
    );
  };

  const cleanupAnnotations = () => {
    if (!instance) return;
    const { annotationManager } = instance.Core;
    const annotations = annotationManager.getAnnotationsList();

    // retain only current signers' annotations
    annotationManager.deleteAnnotations(
      annotations.filter(
        (annotation) =>
          annotation.Subject?.startsWith(ANNOTATION_SUBJECT_PREFIX) &&
          !signers.some((signer) => annotation.Subject.endsWith(signer.user.address))
      )
    );
  };

  return (
    <div
      className={classNames(
        'fixed w-[320px] py-5 bottom-0 right-0 space-x-4 flex justify-center bg-white transition-transform delay-[750ms] duration-300 ease-in-out',
        {
          'translate-x-full': !rendered || documentStatus !== 'rendered',
          'translate-x-0': rendered && documentStatus === 'rendered'
        }
      )}
    >
      <Button variant={ButtonVariant.TERTIARY} size={ButtonSize.CTA} onClick={handleBack}>
        Back
      </Button>
      <Button
        variant={ButtonVariant.PRIMARY}
        size={ButtonSize.CTA}
        disabled={!isSignatureReady}
        onClick={handleNext}
        disableText="Please assign signature fields to all signers"
      >
        Next
      </Button>
    </div>
  );
};

export default PrepareContract;
