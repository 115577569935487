export const CheckFillIcon = ({ ...props }) => {
  return (
    <svg
      className="fill-current hidden text-orange-700 pointer-events-none"
      width="11"
      height="9"
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.8133 0.469528C10.0806 0.686365 10.1367 1.0993 9.93854 1.39184L5.65456 7.71741C5.19178 8.40074 4.31017 8.54339 3.68652 8.03585L0.700026 5.60535C0.433037 5.38807 0.377541 4.97504 0.576072 4.68283C0.774603 4.39062 1.15198 4.32988 1.41897 4.54717L4.40546 6.97766C4.49455 7.05017 4.6205 7.02979 4.68661 6.93217L8.97059 0.606602C9.16871 0.314061 9.546 0.252691 9.8133 0.469528Z"
        fill="#D97D40"
      />
    </svg>
  );
};
