import classNames from 'classnames';
import { ChevronRight } from 'components/Icons';
import { CheckFillIcon } from 'components/Icons/CheckFillIcon';
import { IMenu } from 'components/Menu/MenuItem';
import React, { useState } from 'react';

interface IProps {
  item: IMenu;
  openMenu?: boolean;
  onHandleDropdown?: () => void;
  onClick: () => void;
  children?: JSX.Element | boolean;
}

const TreeItem = ({ item, openMenu = false, onHandleDropdown, onClick }: IProps) => {
  return (
    <div
      className={classNames(
        'font-normal text-sm leading-[17px] flex items-center justify-between text-gray-600 min-w-[210px]',
        {
          'mx-7': !onHandleDropdown
        }
      )}
    >
      <div className="flex items-center">
        <input
          type="checkbox"
          name="Tree-subject"
          checked={item.current}
          onChange={onClick}
          className="opacity-0 absolute w-[16px] h-[16px] hover:cursor-pointer"
        />
        <div className="bg-white border rounded-[4px] border-orange-700 w-[16px] h-[16px] flex flex-shrink-0 justify-center items-center mr-2.5">
          <CheckFillIcon />
        </div>
        <p
          className="font-circularMedium font-[450] text-base text-gray-400 hover:cursor-pointer capitalize"
          onClick={onHandleDropdown}
        >
          {item.name}
        </p>
        {item.children && item.children.length > 0 && <ChevronRight className={openMenu ? 'mx-2 rotate-90' : 'mx-2'} />}
        {item.children && item.children.length > 0 && <span className="text-gray-400">{item?.children?.length}</span>}
      </div>
      {item.count && <span className="text-gray-400">{item?.count}</span>}
    </div>
  );
};

export const TreeParentItem = ({ item, onClick, children }: IProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (children) {
      setOpen(!open);
    } else {
      onClick();
    }
  };

  return (
    <div onClick={handleClick}>
      <TreeItem item={item} openMenu={open} onHandleDropdown={() => setOpen(!open)} onClick={onClick} />
      {open && children}
    </div>
  );
};

export default TreeItem;
