import { SVGProps } from 'react';

export * from './ArrowDownIcon';
export * from './ArrowIcon';
export * from './ChevronRight';
export * from './ArrowThin';
export * from './CheckIcon';
export * from './CopyIcon';
export * from './DocumentIcon';
export * from './EditIcon';
export * from './KeyIcon';
export * from './LogOutIcon';
export * from './ThemeIcon';
export * from './QAIcon';
export * from './FeedbackIcon';
export * from './MoreIcon';
export * from './CloseIcon';
export * from './ContractIcon';
export * from './GearIcon';
export * from './LockIcon';
export * from './FolderIcon';
export * from './PencilIcon';
export * from './TrashIcon';
export * from './WalletIcon';
export * from './MetamaskIcon';
export * from './CoinbaseIcon';
export * from './WalletConnectIcon';
export * from './GoogleIcon';
export * from './TwitterIcon';
export * from './DiscordIcon';
export * from './GithubIcon';
export * from './DragHandler';
export * from './LoadingIcon';
export * from './RecipientsIcon';
export * from './ReviewIcon';
export * from './SearchIcon';
export * from './UploadIcon';
export * from './HeavyCheckIcon';
export * from './CrossIcon';
export * from './QuestionMarkCircleIcon';
export * from './SettingIcon';
export * from './PenIcon';
export * from './EyeIcon';
export * from './TriIcon';
export * from './PendingLoadingIcon';
export * from './ArrowToolbar';
export * from './PlusOutlined';
export * from './Signature';
export * from './DateFilled';
export * from './Wallet';
export * from './CheckboxIcon';
export * from './TextFieldIcon';
export * from './LayoutIcon';
export * from './AlignHBottom';
export * from './AlignHMiddle';
export * from './AlignHTop';
export * from './AlignVBottom';
export * from './AlignVMiddle';
export * from './AlignVTop';
export * from './Archive';
export * from './History';
export * from './Info';
export * from './JoinDiscord';
export * from './SignatureScribble';
export * from './DiscordSettingIcon';
export * from './TwitterSettingIcon';
export * from './ErrorIcon';
export * from './WarningIcon';
export * from './SuccessIcon';
export * from './ErrorOutline';
export * from './SignedIcon';
export * from './ClockIcon';
export * from './Spinner';
export * from './FileIcon';
export * from './InfoIcon';

/* === Welcome === */
export * from './Welcome/ArrowRight';

export enum Status {
  READY = 'ready',
  IN_PROGRESS = 'in_progress',
  DONE = 'done'
}

export interface IconProps extends SVGProps<SVGSVGElement> {
  status?: Status;
  width?: number;
  height?: number;
}
