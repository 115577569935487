import React from 'react';

import { IMenu } from 'components/Menu/MenuItem';
import TreeItem, { TreeParentItem } from './TreeItem';
import { v4 as uuid } from 'uuid';
import './index.scss';

export interface ITreeMenuProps {
  setData: React.Dispatch<React.SetStateAction<Array<IMenu>>>;
  data: IMenu[];
}

export const TreeMenu: React.FC<ITreeMenuProps> = ({ data, setData }) => {
  const onClickParent = (parent_index: number) => {
    setData((prevData) => {
      const data = [...prevData];
      const checked = !data[parent_index].current;
      data[parent_index].current = checked;
      (data[parent_index]?.children || []).forEach((item) => {
        item.current = checked;
      });
      return data;
    });
  };

  const onClickChildren = (parent_index: number, child_index: number) => {
    setData((prevData) => {
      const data = [...prevData];
      const childData = data?.[parent_index]?.children;
      if (childData) {
        childData[child_index]['current'] = !childData[child_index]['current'];
        if ((data[parent_index]?.children || []).filter((item) => item.current).length) {
          data[parent_index].current = true;
        } else {
          data[parent_index].current = false;
        }
      }
      return data;
    });
  };

  return (
    <div className="flex flex-col flex-grow border-gray-500 rounded-2.5xl border-1 w-fit pl-6 pr-4 pt-5 pb-5 bg-white overflow-y-auto font-lato">
      <div className="flex-grow flex flex-col">
        <nav className="flex-1 bg-white space-y-6" aria-label="Sidebar">
          {data.map((item, item_idx) => (
            <TreeParentItem key={uuid()} item={item} onClick={() => onClickParent(item_idx)}>
              {item.children && item.children.length > 0 && (
                <div className="space-y-6 mt-5">
                  {item.children.map((subItem, subitem_idx) => (
                    <TreeItem key={uuid()} item={subItem} onClick={() => onClickChildren(item_idx, subitem_idx)} />
                  ))}
                </div>
              )}
            </TreeParentItem>
          ))}
        </nav>
      </div>
    </div>
  );
};
