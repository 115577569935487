import { bufferToHex } from 'ethereumjs-util';
import { encrypt } from '@metamask/eth-sig-util';

export const getEncryptedMessage = (publicKey: string, data: string): string => {
  return bufferToHex(
    Buffer.from(
      JSON.stringify(
        encrypt({
          publicKey: publicKey,
          data: data,
          version: 'x25519-xsalsa20-poly1305'
        })
      ),
      'utf8'
    )
  );
};
