import React from 'react';
import './index.scss';

interface ISwitchProps {
  disabled?: boolean;
  value?: boolean;
  label: string;
  labelVisible?: boolean;
  type?: 'orange' | 'green';
  onChange?: (e?: boolean) => void;
}

export const Switch = ({ disabled, value, label, labelVisible, type = 'orange', onChange }: ISwitchProps) => {
  const labelDisplay =
    type === 'orange' ? (
      <label
        className="label orange block cursor-pointer rounded-3xl overflow-hidden border border-2 border-solid border-gray-400"
        htmlFor={label}
      >
        <span className="inner w-24 ml-7 block before:content-[''] after:content-[''] before:w-1/2 after:w-1/2 before:h-3 after:h-3 after:p-0 after:box-border before:p-0 before:box-border after:float-left before:float-left before:bg-inherit after:bg-inherit transition-all ease-in duration-200" />
        <span className="switch orange w-2 left-1 absolute block top-1 bottom-1 rounded-full bg-gray-400 transition-all ease-in duration-200" />
      </label>
    ) : (
      <label className="label block cursor-pointer rounded-3xl overflow-hidden" htmlFor={label}>
        <span className="inner w-14 -ml-7 block before:content-[''] after:content-[''] before:w-1/2 after:w-1/2 before:h-4 after:h-4 after:p-0 after:box-border before:p-0 before:box-border after:float-left before:float-left before:bg-gray-100 after:bg-gray-100 transition-all ease-in duration-200" />
        <span className="switch green w-4 left-0 absolute block top-0 bottom-0 rounded-3xl bg-gray-400 transition-all ease-in duration-200" />
      </label>
    );

  return (
    <div className="inline-flex items-center">
      {labelVisible && <div className="font-lato text-sm font-normal pr-4">{label}</div>}
      <div className="toggle-switch relative inline-block w-7">
        <input
          type="checkbox"
          className="checkbox hidden"
          name={label}
          id={label}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const { checked } = event.target;
            onChange?.(checked);
          }}
          disabled={disabled}
          checked={value}
        />
        {labelDisplay}
      </div>
    </div>
  );
};

Switch.defaultProps = {
  disabled: false,
  value: false,
  labelVisible: false
};
