interface IChainName {
  [chainId: number]: {
    name: string;
    chain: string;
  };
}

export const chainNames: IChainName = {
  1: {
    name: 'Ethereum Mainnet',
    chain: 'ETH'
  },
  2: {
    name: 'Expanse Network',
    chain: 'EXP'
  },
  3: {
    name: 'Ropsten',
    chain: 'ETH'
  },
  4: {
    name: 'Rinkeby',
    chain: 'ETH'
  },
  5: {
    name: 'Görli',
    chain: 'ETH'
  },
  6: {
    name: 'Ethereum Classic Testnet Kotti',
    chain: 'ETC'
  },
  7: {
    name: 'ThaiChain',
    chain: 'TCH'
  },
  8: {
    name: 'Ubiq',
    chain: 'UBQ'
  },
  9: {
    name: 'Ubiq Network Testnet',
    chain: 'UBQ'
  },
  10: {
    name: 'Optimism',
    chain: 'ETH'
  },
  11: {
    name: 'Metadium Mainnet',
    chain: 'META'
  },
  12: {
    name: 'Metadium Testnet',
    chain: 'META'
  },
  13: {
    name: 'Diode Testnet Staging',
    chain: 'DIODE'
  },
  14: {
    name: 'Flare Mainnet',
    chain: 'FLR'
  },
  15: {
    name: 'Diode Prenet',
    chain: 'DIODE'
  },
  16: {
    name: 'Flare Testnet Coston',
    chain: 'FLR'
  },
  17: {
    name: 'ThaiChain 2.0 ThaiFi',
    chain: 'TCH'
  },
  18: {
    name: 'ThunderCore Testnet',
    chain: 'TST'
  },
  19: {
    name: 'Songbird Canary-Network',
    chain: 'SGB'
  },
  20: {
    name: 'Elastos Smart Chain',
    chain: 'ETH'
  },
  21: {
    name: 'ELA-ETH-Sidechain Testnet',
    chain: 'ETH'
  },
  22: {
    name: 'ELA-DID-Sidechain Mainnet',
    chain: 'ETH'
  },
  23: {
    name: 'ELA-DID-Sidechain Testnet',
    chain: 'ETH'
  },
  24: {
    name: 'Dithereum Mainnet',
    chain: 'DTH'
  },
  25: {
    name: 'Cronos Mainnet Beta',
    chain: 'CRO'
  },
  26: {
    name: 'Genesis L1 testnet',
    chain: 'genesis'
  },
  27: {
    name: 'ShibaChain',
    chain: 'SHIB'
  },
  28: {
    name: 'Boba Network Rinkeby Testnet',
    chain: 'ETH'
  },
  29: {
    name: 'Genesis L1',
    chain: 'genesis'
  },
  30: {
    name: 'RSK Mainnet',
    chain: 'RSK'
  },
  31: {
    name: 'RSK Testnet',
    chain: 'RSK'
  },
  32: {
    name: 'GoodData Testnet',
    chain: 'GooD'
  },
  33: {
    name: 'GoodData Mainnet',
    chain: 'GooD'
  },
  34: {
    name: 'Dithereum Testnet',
    chain: 'DTH'
  },
  35: {
    name: 'TBWG Chain',
    chain: 'TBWG'
  },
  36: {
    name: 'Dxchain Mainnet',
    chain: 'Dxchain'
  },
  38: {
    name: 'Valorbit',
    chain: 'VAL'
  },
  40: {
    name: 'Telos EVM Mainnet',
    chain: 'TLOS'
  },
  41: {
    name: 'Telos EVM Testnet',
    chain: 'TLOS'
  },
  42: {
    name: 'Kovan',
    chain: 'ETH'
  },
  43: {
    name: 'Darwinia Pangolin Testnet',
    chain: 'pangolin'
  },
  44: {
    name: 'Darwinia Crab Network',
    chain: 'crab'
  },
  45: {
    name: 'Darwinia Pangoro Testnet',
    chain: 'pangoro'
  },
  46: {
    name: 'Darwinia Network',
    chain: 'darwinia'
  },
  50: {
    name: 'XinFin Network Mainnet',
    chain: 'XDC'
  },
  51: {
    name: 'XinFin Apothem Testnet',
    chain: 'TXDC'
  },
  52: {
    name: 'CoinEx Smart Chain Mainnet',
    chain: 'CSC'
  },
  53: {
    name: 'CoinEx Smart Chain Testnet',
    chain: 'CSC'
  },
  54: {
    name: 'Openpiece Mainnet',
    chain: 'OPENPIECE'
  },
  55: {
    name: 'Zyx Mainnet',
    chain: 'ZYX'
  },
  56: {
    name: 'Binance Smart Chain Mainnet',
    chain: 'BSC'
  },
  57: {
    name: 'Syscoin Mainnet',
    chain: 'SYS'
  },
  58: {
    name: 'Ontology Mainnet',
    chain: 'Ontology'
  },
  59: {
    name: 'EOS Mainnet',
    chain: 'EOS'
  },
  60: {
    name: 'GoChain',
    chain: 'GO'
  },
  61: {
    name: 'Ethereum Classic Mainnet',
    chain: 'ETC'
  },
  62: {
    name: 'Ethereum Classic Testnet Morden',
    chain: 'ETC'
  },
  63: {
    name: 'Ethereum Classic Testnet Mordor',
    chain: 'ETC'
  },
  64: {
    name: 'Ellaism',
    chain: 'ELLA'
  },
  65: {
    name: 'OKExChain Testnet',
    chain: 'okexchain'
  },
  66: {
    name: 'OKXChain Mainnet',
    chain: 'okxchain'
  },
  67: {
    name: 'DBChain Testnet',
    chain: 'DBM'
  },
  68: {
    name: 'SoterOne Mainnet',
    chain: 'SOTER'
  },
  69: {
    name: 'Optimism Kovan',
    chain: 'ETH'
  },
  70: {
    name: 'Hoo Smart Chain',
    chain: 'HSC'
  },
  71: {
    name: 'Conflux eSpace (Testnet)',
    chain: 'Conflux'
  },
  72: {
    name: 'DxChain Testnet',
    chain: 'DxChain'
  },
  74: {
    name: 'IDChain Mainnet',
    chain: 'IDChain'
  },
  76: {
    name: 'Mix',
    chain: 'MIX'
  },
  77: {
    name: 'POA Network Sokol',
    chain: 'POA'
  },
  78: {
    name: 'PrimusChain mainnet',
    chain: 'PC'
  },
  79: {
    name: 'Zenith Mainnet',
    chain: 'Zenith'
  },
  80: {
    name: 'GeneChain',
    chain: 'GeneChain'
  },
  81: {
    name: 'Zenith Testnet (Vilnius)',
    chain: 'Zenith'
  },
  82: {
    name: 'Meter Mainnet',
    chain: 'METER'
  },
  83: {
    name: 'Meter Testnet',
    chain: 'METER Testnet'
  },
  85: {
    name: 'GateChain Testnet',
    chain: 'GTTEST'
  },
  86: {
    name: 'GateChain Mainnet',
    chain: 'GT'
  },
  87: {
    name: 'Nova Network',
    chain: 'NNW'
  },
  88: {
    name: 'TomoChain',
    chain: 'TOMO'
  },
  89: {
    name: 'TomoChain Testnet',
    chain: 'TOMO'
  },
  90: {
    name: 'Garizon Stage0',
    chain: 'GAR'
  },
  91: {
    name: 'Garizon Stage1',
    chain: 'GAR'
  },
  92: {
    name: 'Garizon Stage2',
    chain: 'GAR'
  },
  93: {
    name: 'Garizon Stage3',
    chain: 'GAR'
  },
  95: {
    name: 'CryptoKylin Testnet',
    chain: 'EOS'
  },
  96: {
    name: 'NEXT Smart Chain',
    chain: 'NSC'
  },
  97: {
    name: 'Binance Smart Chain Testnet',
    chain: 'BSC'
  },
  99: {
    name: 'POA Network Core',
    chain: 'POA'
  },
  100: {
    name: 'Gnosis Chain',
    chain: 'GNO'
  },
  101: {
    name: 'EtherInc',
    chain: 'ETI'
  },
  102: {
    name: 'Web3Games Testnet',
    chain: 'Web3Games'
  },
  104: {
    name: 'Kaiba Lightning Chain Testnet',
    chain: 'tKLC'
  },
  105: {
    name: 'Web3Games Devnet',
    chain: 'Web3Games'
  },
  106: {
    name: 'Velas EVM Mainnet',
    chain: 'Velas'
  },
  107: {
    name: 'Nebula Testnet',
    chain: 'NTN'
  },
  108: {
    name: 'ThunderCore Mainnet',
    chain: 'TT'
  },
  110: {
    name: 'Proton Testnet',
    chain: 'XPR'
  },
  111: {
    name: 'EtherLite Chain',
    chain: 'ETL'
  },
  122: {
    name: 'Fuse Mainnet',
    chain: 'FUSE'
  },
  123: {
    name: 'Fuse Sparknet',
    chain: 'fuse'
  },
  124: {
    name: 'Decentralized Web Mainnet',
    chain: 'DWU'
  },
  125: {
    name: 'OYchain Testnet',
    chain: 'OYchain'
  },
  126: {
    name: 'OYchain Mainnet',
    chain: 'OYchain'
  },
  127: {
    name: 'Factory 127 Mainnet',
    chain: 'FETH'
  },
  128: {
    name: 'Huobi ECO Chain Mainnet',
    chain: 'Heco'
  },
  137: {
    name: 'Polygon Mainnet',
    chain: 'Polygon'
  },
  141: {
    name: 'Openpiece Testnet',
    chain: 'OPENPIECE'
  },
  142: {
    name: 'DAX CHAIN',
    chain: 'DAX'
  },
  162: {
    name: 'Lightstreams Testnet',
    chain: 'PHT'
  },
  163: {
    name: 'Lightstreams Mainnet',
    chain: 'PHT'
  },
  168: {
    name: 'AIOZ Network',
    chain: 'AIOZ'
  },
  170: {
    name: 'HOO Smart Chain Testnet',
    chain: 'ETH'
  },
  172: {
    name: 'Latam-Blockchain Resil Testnet',
    chain: 'Resil'
  },
  180: {
    name: 'AME Chain Mainnet',
    chain: 'AME'
  },
  186: {
    name: 'Seele Mainnet',
    chain: 'Seele'
  },
  188: {
    name: 'BMC Mainnet',
    chain: 'BMC'
  },
  189: {
    name: 'BMC Testnet',
    chain: 'BMC'
  },
  193: {
    name: 'Crypto Emergency',
    chain: 'CEM'
  },
  199: {
    name: 'BitTorrent Chain Mainnet',
    chain: 'BTTC'
  },
  200: {
    name: 'Arbitrum on xDai',
    chain: 'AOX'
  },
  211: {
    name: 'Freight Trust Network',
    chain: 'EDI'
  },
  218: {
    name: 'SoterOne Mainnet old',
    chain: 'SOTER'
  },
  222: {
    name: 'Permission',
    chain: 'ASK'
  },
  225: {
    name: 'LACHAIN Mainnet',
    chain: 'LA'
  },
  226: {
    name: 'LACHAIN Testnet',
    chain: 'TLA'
  },
  246: {
    name: 'Energy Web Chain',
    chain: 'Energy Web Chain'
  },
  250: {
    name: 'Fantom Opera',
    chain: 'FTM'
  },
  256: {
    name: 'Huobi ECO Chain Testnet',
    chain: 'Heco'
  },
  258: {
    name: 'Setheum',
    chain: 'Setheum'
  },
  262: {
    name: 'SUR Blockchain Network',
    chain: 'SUR'
  },
  269: {
    name: 'High Performance Blockchain',
    chain: 'HPB'
  },
  280: {
    name: 'zkSync alpha testnet',
    chain: 'ETH'
  },
  288: {
    name: 'Boba Network',
    chain: 'ETH'
  },
  300: {
    name: 'Optimism on Gnosis Chain',
    chain: 'OGC'
  },
  321: {
    name: 'KCC Mainnet',
    chain: 'KCC'
  },
  322: {
    name: 'KCC Testnet',
    chain: 'KCC'
  },
  333: {
    name: 'Web3Q Mainnet',
    chain: 'Web3Q'
  },
  335: {
    name: 'DFK Chain Test',
    chain: 'DFK'
  },
  336: {
    name: 'Shiden',
    chain: 'SDN'
  },
  338: {
    name: 'Cronos Testnet',
    chain: 'CRO'
  },
  361: {
    name: 'Theta Mainnet',
    chain: 'Theta'
  },
  363: {
    name: 'Theta Sapphire Testnet',
    chain: 'Theta'
  },
  364: {
    name: 'Theta Amber Testnet',
    chain: 'Theta'
  },
  365: {
    name: 'Theta Testnet',
    chain: 'Theta'
  },
  369: {
    name: 'PulseChain Mainnet',
    chain: 'PLS'
  },
  385: {
    name: 'Lisinski',
    chain: 'CRO'
  },
  416: {
    name: 'SX Network Mainnet',
    chain: 'SX'
  },
  420: {
    name: 'Optimism Goerli Testnet',
    chain: 'ETH'
  },
  499: {
    name: 'Rupaya',
    chain: 'RUPX'
  },
  512: {
    name: 'Double-A Chain Mainnet',
    chain: 'AAC'
  },
  513: {
    name: 'Double-A Chain Testnet',
    chain: 'AAC'
  },
  520: {
    name: 'XT Smart Chain Mainnet',
    chain: 'XSC'
  },
  530: {
    name: 'F(x)Core Mainnet Network',
    chain: 'Fxcore'
  },
  534: {
    name: 'Candle',
    chain: 'Candle'
  },
  555: {
    name: 'Vela1 Chain Mainnet',
    chain: 'VELA1'
  },
  558: {
    name: 'Tao Network',
    chain: 'TAO'
  },
  588: {
    name: 'Metis Stardust Testnet',
    chain: 'ETH'
  },
  592: {
    name: 'Astar',
    chain: 'ASTR'
  },
  595: {
    name: 'Acala Mandala Testnet',
    chain: 'mACA'
  },
  596: {
    name: 'Karura Network Testnet',
    chain: 'KAR'
  },
  597: {
    name: 'Acala Network Testnet',
    chain: 'ACA'
  },
  600: {
    name: 'Meshnyan testnet',
    chain: 'MeshTestChain'
  },
  647: {
    name: 'SX Network Testnet',
    chain: 'SX'
  },
  666: {
    name: 'Pixie Chain Testnet',
    chain: 'PixieChain'
  },
  686: {
    name: 'Karura Network',
    chain: 'KAR'
  },
  700: {
    name: 'Star Social Testnet',
    chain: 'SNS'
  },
  707: {
    name: 'BlockChain Station Mainnet',
    chain: 'BCS'
  },
  708: {
    name: 'BlockChain Station Testnet',
    chain: 'BCS'
  },
  721: {
    name: 'Factory 127 Testnet',
    chain: 'FETH'
  },
  776: {
    name: 'OpenChain Testnet',
    chain: 'OpenChain Testnet'
  },
  777: {
    name: 'cheapETH',
    chain: 'cheapETH'
  },
  787: {
    name: 'Acala Network',
    chain: 'ACA'
  },
  788: {
    name: 'Aerochain Testnet',
    chain: 'Aerochain'
  },
  803: {
    name: 'Haic',
    chain: 'Haic'
  },
  808: {
    name: 'Portal Fantasy Chain Test',
    chain: 'PF'
  },
  820: {
    name: 'Callisto Mainnet',
    chain: 'CLO'
  },
  821: {
    name: 'Callisto Testnet',
    chain: 'CLO'
  },
  880: {
    name: 'Ambros Chain Mainnet',
    chain: 'ambroschain'
  },
  888: {
    name: 'Wanchain',
    chain: 'WAN'
  },
  900: {
    name: 'Garizon Testnet Stage0',
    chain: 'GAR'
  },
  901: {
    name: 'Garizon Testnet Stage1',
    chain: 'GAR'
  },
  902: {
    name: 'Garizon Testnet Stage2',
    chain: 'GAR'
  },
  903: {
    name: 'Garizon Testnet Stage3',
    chain: 'GAR'
  },
  909: {
    name: 'Portal Fantasy Chain',
    chain: 'PF'
  },
  940: {
    name: 'PulseChain Testnet',
    chain: 'tPLS'
  },
  941: {
    name: 'PulseChain Testnet v2b',
    chain: 't2bPLS'
  },
  942: {
    name: 'PulseChain Testnet v3',
    chain: 't3PLS'
  },
  977: {
    name: 'Nepal Blockchain Network',
    chain: 'YETI'
  },
  980: {
    name: 'TOP Mainnet EVM',
    chain: 'TOP'
  },
  989: {
    name: 'TOP Mainnet',
    chain: 'TOP'
  },
  998: {
    name: 'Lucky Network',
    chain: 'LN'
  },
  999: {
    name: 'Wanchain Testnet',
    chain: 'WAN'
  },
  1000: {
    name: 'GTON Mainnet',
    chain: 'GTON'
  },
  1001: {
    name: 'Klaytn Testnet Baobab',
    chain: 'KLAY'
  },
  1007: {
    name: 'Newton Testnet',
    chain: 'NEW'
  },
  1008: {
    name: 'Eurus Mainnet',
    chain: 'EUN'
  },
  1010: {
    name: 'Evrice Network',
    chain: 'EVC'
  },
  1012: {
    name: 'Newton',
    chain: 'NEW'
  },
  1022: {
    name: 'Sakura',
    chain: 'Sakura'
  },
  1023: {
    name: 'Clover Testnet',
    chain: 'Clover'
  },
  1024: {
    name: 'CLV Parachain',
    chain: 'CLV'
  },
  1028: {
    name: 'BitTorrent Chain Testnet',
    chain: 'BTTC'
  },
  1030: {
    name: 'Conflux eSpace',
    chain: 'Conflux'
  },
  1088: {
    name: 'Metis Andromeda Mainnet',
    chain: 'ETH'
  },
  1139: {
    name: 'MathChain',
    chain: 'MATH'
  },
  1140: {
    name: 'MathChain Testnet',
    chain: 'MATH'
  },
  1197: {
    name: 'Iora Chain',
    chain: 'IORA'
  },
  1201: {
    name: 'Evanesco Testnet',
    chain: 'Evanesco Testnet'
  },
  1202: {
    name: 'World Trade Technical Chain Mainnet',
    chain: 'WTT'
  },
  1213: {
    name: 'Popcateum Mainnet',
    chain: 'POPCATEUM'
  },
  1214: {
    name: 'EnterChain Mainnet',
    chain: 'ENTER'
  },
  1246: {
    name: 'OM Platform Mainnet',
    chain: 'omplatform'
  },
  1280: {
    name: 'HALO Mainnet',
    chain: 'HALO'
  },
  1284: {
    name: 'Moonbeam',
    chain: 'MOON'
  },
  1285: {
    name: 'Moonriver',
    chain: 'MOON'
  },
  1286: {
    name: 'Moonrock old',
    chain: 'MOON'
  },
  1287: {
    name: 'Moonbase Alpha',
    chain: 'MOON'
  },
  1288: {
    name: 'Moonrock',
    chain: 'MOON'
  },
  1297: {
    name: 'Boba Network Bobabase',
    chain: 'Bobabase'
  },
  1319: {
    name: 'Aitd Mainnet',
    chain: 'AITD'
  },
  1320: {
    name: 'Aitd Testnet',
    chain: 'AITD'
  },
  1337: {
    name: 'CENNZnet old',
    chain: 'CENNZnet'
  },
  1506: {
    name: 'Sherpax Mainnet',
    chain: 'Sherpax Mainnet'
  },
  1507: {
    name: 'Sherpax Testnet',
    chain: 'Sherpax Testnet'
  },
  1618: {
    name: 'Catecoin Chain Mainnet',
    chain: 'Catechain'
  },
  1620: {
    name: 'Atheios',
    chain: 'ATH'
  },
  1657: {
    name: 'Btachain',
    chain: 'btachain'
  },
  1688: {
    name: 'LUDAN Mainnet',
    chain: 'LUDAN'
  },
  1818: {
    name: 'Cube Chain Mainnet',
    chain: 'Cube'
  },
  1819: {
    name: 'Cube Chain Testnet',
    chain: 'Cube'
  },
  1856: {
    name: 'Teslafunds',
    chain: 'TSF'
  },
  1898: {
    name: 'BON Network',
    chain: 'BON'
  },
  1984: {
    name: 'Eurus Testnet',
    chain: 'EUN'
  },
  1987: {
    name: 'EtherGem',
    chain: 'EGEM'
  },
  2001: {
    name: 'Milkomeda C1 Mainnet',
    chain: 'milkAda'
  },
  2008: {
    name: 'CloudWalk Testnet',
    chain: 'CloudWalk Testnet'
  },
  2009: {
    name: 'CloudWalk Mainnet',
    chain: 'CloudWalk Mainnet'
  },
  2020: {
    name: '420coin',
    chain: '420'
  },
  2021: {
    name: 'Edgeware Mainnet',
    chain: 'EDG'
  },
  2022: {
    name: 'Beresheet Testnet',
    chain: 'EDG'
  },
  2023: {
    name: 'Taycan Testnet',
    chain: 'Taycan'
  },
  2025: {
    name: 'Rangers Protocol Mainnet',
    chain: 'Rangers'
  },
  2100: {
    name: 'Ecoball Mainnet',
    chain: 'ECO'
  },
  2101: {
    name: 'Ecoball Testnet Espuma',
    chain: 'ECO'
  },
  2152: {
    name: 'Findora Mainnet',
    chain: 'Findora'
  },
  2153: {
    name: 'Findora Testnet',
    chain: 'Testnet-anvil'
  },
  2213: {
    name: 'Evanesco Mainnet',
    chain: 'EVA'
  },
  2221: {
    name: 'Kava EVM Testnet',
    chain: 'KAVA'
  },
  2222: {
    name: 'Kava EVM',
    chain: 'KAVA'
  },
  2223: {
    name: 'VChain Mainnet',
    chain: 'VChain'
  },
  2559: {
    name: 'Kortho Mainnet',
    chain: 'Kortho Chain'
  },
  2569: {
    name: 'TechPay Mainnet',
    chain: 'TPC'
  },
  2612: {
    name: 'EZChain C-Chain Mainnet',
    chain: 'EZC'
  },
  2613: {
    name: 'EZChain C-Chain Testnet',
    chain: 'EZC'
  },
  3000: {
    name: 'CENNZnet Rata',
    chain: 'CENNZnet'
  },
  3001: {
    name: 'CENNZnet Nikau',
    chain: 'CENNZnet'
  },
  3331: {
    name: 'ZCore Testnet',
    chain: 'Beach'
  },
  3333: {
    name: 'Web3Q Testnet',
    chain: 'Web3Q'
  },
  3334: {
    name: 'Web3Q Galileo',
    chain: 'Web3Q'
  },
  3400: {
    name: 'Paribu Net Mainnet',
    chain: 'PRB'
  },
  3500: {
    name: 'Paribu Net Testnet',
    chain: 'PRB'
  },
  3501: {
    name: 'JFIN Chain',
    chain: 'JFIN'
  },
  3690: {
    name: 'Bittex Mainnet',
    chain: 'BTX'
  },
  3737: {
    name: 'Crossbell',
    chain: 'Crossbell'
  },
  3966: {
    name: 'DYNO Mainnet',
    chain: 'DYNO'
  },
  3967: {
    name: 'DYNO Testnet',
    chain: 'DYNO'
  },
  3999: {
    name: 'YuanChain Mainnet',
    chain: 'YCC'
  },
  4002: {
    name: 'Fantom Testnet',
    chain: 'FTM'
  },
  4051: {
    name: 'Boba Network Bobaopera Testnet',
    chain: 'Bobaopera Testnet'
  },
  4102: {
    name: 'AIOZ Network Testnet',
    chain: 'AIOZ'
  },
  4181: {
    name: 'PHI Network',
    chain: 'PHI'
  },
  4689: {
    name: 'IoTeX Network Mainnet',
    chain: 'iotex.io'
  },
  4690: {
    name: 'IoTeX Network Testnet',
    chain: 'iotex.io'
  },
  4918: {
    name: 'Venidium Testnet',
    chain: 'XVM'
  },
  4919: {
    name: 'Venidium Mainnet',
    chain: 'XVM'
  },
  5177: {
    name: 'TLChain Network Mainnet',
    chain: 'TLC'
  },
  5197: {
    name: 'EraSwap Mainnet',
    chain: 'ESN'
  },
  5315: {
    name: 'Uzmi Network Mainnet',
    chain: 'UZMI'
  },
  5551: {
    name: 'Nahmii Mainnet',
    chain: 'Nahmii'
  },
  5553: {
    name: 'Nahmii Testnet',
    chain: 'Nahmii'
  },
  5700: {
    name: 'Syscoin Tanenbaum Testnet',
    chain: 'SYS'
  },
  5777: {
    name: 'Digest Swarm Chain',
    chain: 'DSC'
  },
  5851: {
    name: 'Ontology Testnet',
    chain: 'Ontology'
  },
  5869: {
    name: 'Wegochain Rubidium Mainnet',
    chain: 'RBD'
  },
  6626: {
    name: 'Pixie Chain Mainnet',
    chain: 'PixieChain'
  },
  6969: {
    name: 'Tomb Chain Mainnet',
    chain: 'Tomb Chain'
  },
  7027: {
    name: 'Ella the heart',
    chain: 'ella'
  },
  7341: {
    name: 'Shyft Mainnet',
    chain: 'SHYFT'
  },
  7777: {
    name: 'Rise of the Warbots Testnet',
    chain: 'nmactest'
  },
  7878: {
    name: 'Hazlor Testnet',
    chain: 'SCAS'
  },
  8000: {
    name: 'Teleport',
    chain: 'Teleport'
  },
  8001: {
    name: 'Teleport Testnet',
    chain: 'Teleport'
  },
  8029: {
    name: 'MDGL Testnet',
    chain: 'MDGL'
  },
  8080: {
    name: 'GeneChain Adenine Testnet',
    chain: 'GeneChain'
  },
  8217: {
    name: 'Klaytn Mainnet Cypress',
    chain: 'KLAY'
  },
  8285: {
    name: 'KorthoTest',
    chain: 'Kortho'
  },
  8723: {
    name: 'TOOL Global Mainnet',
    chain: 'OLO'
  },
  8724: {
    name: 'TOOL Global Testnet',
    chain: 'OLO'
  },
  8888: {
    name: 'Ambros Chain Testnet',
    chain: 'ambroschain'
  },
  8898: {
    name: 'Mammoth Mainnet',
    chain: 'MMT'
  },
  8995: {
    name: 'bloxberg',
    chain: 'bloxberg'
  },
  9000: {
    name: 'Evmos Testnet',
    chain: 'Evmos'
  },
  9001: {
    name: 'Evmos',
    chain: 'Evmos'
  },
  9012: {
    name: 'BerylBit Mainnet',
    chain: 'BRB'
  },
  9100: {
    name: 'Genesis Coin',
    chain: 'Genesis'
  },
  9527: {
    name: 'Rangers Protocol Testnet Robin',
    chain: 'Rangers'
  },
  9999: {
    name: 'myOwn Testnet',
    chain: 'myOwn'
  },
  10000: {
    name: 'Smart Bitcoin Cash',
    chain: 'smartBCH'
  },
  10001: {
    name: 'Smart Bitcoin Cash Testnet',
    chain: 'smartBCHTest'
  },
  10101: {
    name: 'Blockchain Genesis Mainnet',
    chain: 'GEN'
  },
  10823: {
    name: 'CryptoCoinPay',
    chain: 'CCP'
  },
  10946: {
    name: 'Quadrans Blockchain',
    chain: 'QDC'
  },
  10947: {
    name: 'Quadrans Blockchain Testnet',
    chain: 'tQDC'
  },
  11111: {
    name: 'WAGMI',
    chain: 'WAGMI'
  },
  11437: {
    name: 'Shyft Testnet',
    chain: 'SHYFTT'
  },
  12051: {
    name: 'Singularity ZERO Testnet',
    chain: 'ZERO'
  },
  12052: {
    name: 'Singularity ZERO Mainnet',
    chain: 'ZERO'
  },
  13381: {
    name: 'Phoenix Mainnet',
    chain: 'Phoenix'
  },
  16000: {
    name: 'MetaDot Mainnet',
    chain: 'MTT'
  },
  16001: {
    name: 'MetaDot Testnet',
    chain: 'MTTTest'
  },
  16888: {
    name: 'IVAR Chain Testnet',
    chain: 'IVAR'
  },
  19845: {
    name: 'BTCIX Network',
    chain: 'BTCIX'
  },
  21337: {
    name: 'CENNZnet Azalea',
    chain: 'CENNZnet'
  },
  21816: {
    name: 'omChain Mainnet',
    chain: 'OML'
  },
  22023: {
    name: 'Taycan',
    chain: 'Taycan'
  },
  24484: {
    name: 'Webchain',
    chain: 'WEB'
  },
  24734: {
    name: 'MintMe.com Coin',
    chain: 'MINTME'
  },
  26863: {
    name: 'OasisChain Mainnet',
    chain: 'OasisChain'
  },
  30067: {
    name: 'Piece testnet',
    chain: 'PieceNetwork'
  },
  31102: {
    name: 'Ethersocial Network',
    chain: 'ESN'
  },
  31337: {
    name: 'GoChain Testnet',
    chain: 'GO'
  },
  32520: {
    name: 'Bitgert Mainnet',
    chain: 'Brise'
  },
  32659: {
    name: 'Fusion Mainnet',
    chain: 'FSN'
  },
  35441: {
    name: 'Q Mainnet',
    chain: 'Q'
  },
  35443: {
    name: 'Q Testnet',
    chain: 'Q'
  },
  39797: {
    name: 'Energi Mainnet',
    chain: 'NRG'
  },
  42069: {
    name: 'pegglecoin',
    chain: '42069'
  },
  42161: {
    name: 'Arbitrum One',
    chain: 'ETH'
  },
  42170: {
    name: 'Arbitrum Nova',
    chain: 'ETH'
  },
  42220: {
    name: 'Celo Mainnet',
    chain: 'CELO'
  },
  42261: {
    name: 'Emerald Paratime Testnet',
    chain: 'Emerald'
  },
  42262: {
    name: 'Emerald Paratime Mainnet',
    chain: 'Emerald'
  },
  43110: {
    name: 'Athereum',
    chain: 'ATH'
  },
  43113: {
    name: 'Avalanche Fuji Testnet',
    chain: 'AVAX'
  },
  43114: {
    name: 'Avalanche C-Chain',
    chain: 'AVAX'
  },
  44787: {
    name: 'Celo Alfajores Testnet',
    chain: 'CELO'
  },
  45000: {
    name: 'Autobahn Network',
    chain: 'TXL'
  },
  47805: {
    name: 'REI Network',
    chain: 'REI'
  },
  49797: {
    name: 'Energi Testnet',
    chain: 'NRG'
  },
  50021: {
    name: 'GTON Testnet',
    chain: 'GTON Testnet'
  },
  53935: {
    name: 'DFK Chain',
    chain: 'DFK'
  },
  55555: {
    name: 'REI Chain Mainnet',
    chain: 'REI'
  },
  55556: {
    name: 'REI Chain Testnet',
    chain: 'REI'
  },
  60000: {
    name: 'Thinkium Testnet Chain 0',
    chain: 'Thinkium'
  },
  60001: {
    name: 'Thinkium Testnet Chain 1',
    chain: 'Thinkium'
  },
  60002: {
    name: 'Thinkium Testnet Chain 2',
    chain: 'Thinkium'
  },
  60103: {
    name: 'Thinkium Testnet Chain 103',
    chain: 'Thinkium'
  },
  62320: {
    name: 'Celo Baklava Testnet',
    chain: 'CELO'
  },
  62621: {
    name: 'MultiVAC Mainnet',
    chain: 'MultiVAC'
  },
  63000: {
    name: 'eCredits Mainnet',
    chain: 'ECS'
  },
  63001: {
    name: 'eCredits Testnet',
    chain: 'ECS'
  },
  69420: {
    name: 'Condrieu',
    chain: 'ETH'
  },
  70000: {
    name: 'Thinkium Mainnet Chain 0',
    chain: 'Thinkium'
  },
  70001: {
    name: 'Thinkium Mainnet Chain 1',
    chain: 'Thinkium'
  },
  70002: {
    name: 'Thinkium Mainnet Chain 2',
    chain: 'Thinkium'
  },
  70103: {
    name: 'Thinkium Mainnet Chain 103',
    chain: 'Thinkium'
  },
  71393: {
    name: 'Polyjuice Testnet',
    chain: 'CKB'
  },
  71401: {
    name: 'Godwoken Testnet (V1.1)',
    chain: 'GWT'
  },
  71402: {
    name: 'Godwoken Mainnet',
    chain: 'GWT'
  },
  73799: {
    name: 'Energy Web Volta Testnet',
    chain: 'Volta'
  },
  73927: {
    name: 'Mixin Virtual Machine',
    chain: 'MVM'
  },
  75000: {
    name: 'ResinCoin Mainnet',
    chain: 'RESIN'
  },
  78110: {
    name: 'Firenze test network',
    chain: 'ETH'
  },
  80001: {
    name: 'Mumbai',
    chain: 'Polygon'
  },
  88888: {
    name: 'IVAR Chain Mainnet',
    chain: 'IVAR'
  },
  99998: {
    name: 'UB Smart Chain(testnet)',
    chain: 'USC'
  },
  99999: {
    name: 'UB Smart Chain',
    chain: 'USC'
  },
  100000: {
    name: 'QuarkChain Mainnet Root',
    chain: 'QuarkChain'
  },
  100001: {
    name: 'QuarkChain Mainnet Shard 0',
    chain: 'QuarkChain'
  },
  100002: {
    name: 'QuarkChain Mainnet Shard 1',
    chain: 'QuarkChain'
  },
  100003: {
    name: 'QuarkChain Mainnet Shard 2',
    chain: 'QuarkChain'
  },
  100004: {
    name: 'QuarkChain Mainnet Shard 3',
    chain: 'QuarkChain'
  },
  100005: {
    name: 'QuarkChain Mainnet Shard 4',
    chain: 'QuarkChain'
  },
  100006: {
    name: 'QuarkChain Mainnet Shard 5',
    chain: 'QuarkChain'
  },
  100007: {
    name: 'QuarkChain Mainnet Shard 6',
    chain: 'QuarkChain'
  },
  100008: {
    name: 'QuarkChain Mainnet Shard 7',
    chain: 'QuarkChain'
  },
  103090: {
    name: 'Crystaleum',
    chain: 'crystal'
  },
  108801: {
    name: 'BROChain Mainnet',
    chain: 'BRO'
  },
  110000: {
    name: 'QuarkChain Devnet Root',
    chain: 'QuarkChain'
  },
  110001: {
    name: 'QuarkChain Devnet Shard 0',
    chain: 'QuarkChain'
  },
  110002: {
    name: 'QuarkChain Devnet Shard 1',
    chain: 'QuarkChain'
  },
  110003: {
    name: 'QuarkChain Devnet Shard 2',
    chain: 'QuarkChain'
  },
  110004: {
    name: 'QuarkChain Devnet Shard 3',
    chain: 'QuarkChain'
  },
  110005: {
    name: 'QuarkChain Devnet Shard 4',
    chain: 'QuarkChain'
  },
  110006: {
    name: 'QuarkChain Devnet Shard 5',
    chain: 'QuarkChain'
  },
  110007: {
    name: 'QuarkChain Devnet Shard 6',
    chain: 'QuarkChain'
  },
  110008: {
    name: 'QuarkChain Devnet Shard 7',
    chain: 'QuarkChain'
  },
  131419: {
    name: 'ETND Chain Mainnets',
    chain: 'ETND'
  },
  200101: {
    name: 'Milkomeda C1 Testnet',
    chain: 'milkTAda'
  },
  200625: {
    name: 'Akroma',
    chain: 'AKA'
  },
  201018: {
    name: 'Alaya Mainnet',
    chain: 'Alaya'
  },
  201030: {
    name: 'Alaya Dev Testnet',
    chain: 'Alaya'
  },
  210425: {
    name: 'PlatON Mainnet',
    chain: 'PlatON'
  },
  234666: {
    name: 'Haymo Testnet',
    chain: 'tHYM'
  },
  246529: {
    name: 'ARTIS sigma1',
    chain: 'ARTIS'
  },
  246785: {
    name: 'ARTIS Testnet tau1',
    chain: 'ARTIS'
  },
  281121: {
    name: 'Social Smart Chain Mainnet',
    chain: 'SoChain'
  },
  333888: {
    name: 'Polis Testnet',
    chain: 'Sparta'
  },
  333999: {
    name: 'Polis Mainnet',
    chain: 'Olympus'
  },
  421611: {
    name: 'Arbitrum Rinkeby',
    chain: 'ETH'
  },
  421613: {
    name: 'Arbitrum Görli',
    chain: 'ETH'
  },
  432201: {
    name: 'Dexalot Testnet',
    chain: 'DEXALOT'
  },
  444900: {
    name: 'Weelink Testnet',
    chain: 'WLK'
  },
  474142: {
    name: 'OpenChain Mainnet',
    chain: 'OpenChain'
  },
  512512: {
    name: 'CMP-Testnet',
    chain: 'CMP'
  },
  666666: {
    name: 'Vision - Vpioneer Test Chain',
    chain: 'Vision-Vpioneer'
  },
  846000: {
    name: '4GoodNetwork',
    chain: '4GN'
  },
  888888: {
    name: 'Vision - Mainnet',
    chain: 'Vision'
  },
  955305: {
    name: 'Eluvio Content Fabric',
    chain: 'Eluvio'
  },
  1313114: {
    name: 'Etho Protocol',
    chain: 'ETHO'
  },
  1313500: {
    name: 'Xerom',
    chain: 'XERO'
  },
  1337702: {
    name: 'Kintsugi',
    chain: 'ETH'
  },
  1337802: {
    name: 'Kiln',
    chain: 'ETH'
  },
  2203181: {
    name: 'PlatON Dev Testnet',
    chain: 'PlatON'
  },
  2206132: {
    name: 'PlatON Dev Testnet2',
    chain: 'PlatON'
  },
  7762959: {
    name: 'Musicoin',
    chain: 'MUSIC'
  },
  11155111: {
    name: 'Sepolia',
    chain: 'ETH'
  },
  13371337: {
    name: 'PepChain Churchill',
    chain: 'PEP'
  },
  18289463: {
    name: 'IOLite',
    chain: 'ILT'
  },
  20180430: {
    name: 'SmartMesh Mainnet',
    chain: 'Spectrum'
  },
  20181205: {
    name: 'quarkblockchain',
    chain: 'QKI'
  },
  28945486: {
    name: 'Auxilium Network Mainnet',
    chain: 'AUX'
  },
  35855456: {
    name: 'Joys Digital Mainnet',
    chain: 'JOYS'
  },
  61717561: {
    name: 'Aquachain',
    chain: 'AQUA'
  },
  99415706: {
    name: 'Joys Digital TestNet',
    chain: 'TOYS'
  },
  192837465: {
    name: 'Gather Mainnet Network',
    chain: 'GTH'
  },
  245022926: {
    name: 'Neon EVM DevNet',
    chain: 'Solana'
  },
  245022934: {
    name: 'Neon EVM MainNet',
    chain: 'Solana'
  },
  245022940: {
    name: 'Neon EVM TestNet',
    chain: 'Solana'
  },
  311752642: {
    name: 'OneLedger Mainnet',
    chain: 'OLT'
  },
  356256156: {
    name: 'Gather Testnet Network',
    chain: 'GTH'
  },
  486217935: {
    name: 'Gather Devnet Network',
    chain: 'GTH'
  },
  1122334455: {
    name: 'IPOS Network',
    chain: 'IPOS'
  },
  1313161554: {
    name: 'Aurora Mainnet',
    chain: 'NEAR'
  },
  1313161555: {
    name: 'Aurora Testnet',
    chain: 'NEAR'
  },
  1313161556: {
    name: 'Aurora Betanet',
    chain: 'NEAR'
  },
  1666600000: {
    name: 'Harmony Mainnet Shard 0',
    chain: 'Harmony'
  },
  1666600001: {
    name: 'Harmony Mainnet Shard 1',
    chain: 'Harmony'
  },
  1666600002: {
    name: 'Harmony Mainnet Shard 2',
    chain: 'Harmony'
  },
  1666600003: {
    name: 'Harmony Mainnet Shard 3',
    chain: 'Harmony'
  },
  1666700000: {
    name: 'Harmony Testnet Shard 0',
    chain: 'Harmony'
  },
  1666700001: {
    name: 'Harmony Testnet Shard 1',
    chain: 'Harmony'
  },
  1666700002: {
    name: 'Harmony Testnet Shard 2',
    chain: 'Harmony'
  },
  1666700003: {
    name: 'Harmony Testnet Shard 3',
    chain: 'Harmony'
  },
  2021121117: {
    name: 'DataHopper',
    chain: 'HOP'
  },
  3125659152: {
    name: 'Pirl',
    chain: 'PIRL'
  },
  4216137055: {
    name: 'OneLedger Testnet Frankenstein',
    chain: 'OLT'
  },
  11297108099: {
    name: 'Palm Testnet',
    chain: 'Palm'
  },
  11297108109: {
    name: 'Palm',
    chain: 'Palm'
  },
  197710212030: {
    name: 'Ntity Mainnet',
    chain: 'Ntity'
  },
  197710212031: {
    name: 'Haradev Testnet',
    chain: 'Ntity'
  },
  6022140761023: {
    name: 'Molereum Network',
    chain: 'ETH'
  },
  868455272153094: {
    name: 'Godwoken Testnet (V1)',
    chain: 'GWT'
  }
};
