import React, { createContext, useContext, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import EthSignPublicEncryptionKeyRegistry from '../artifacts/EthSignPublicEncryptionKeyRegistry.json';
import { useToast } from './useToast';
import { NUMBER_OF_CONFIRMATIONS, POLYGON_CHAIN_ID } from 'utils/constants';
import { useBiconomy } from './useBiconomy';
import { useDispatch } from 'react-redux';
import { setOneTapEnabled } from 'store/userSlice';
import { useWeb3React } from '@web3-react/core';
import { connectors } from 'utils/connectors';

interface RegistryContract {
  provider: ethers.providers.Web3Provider;
  ethAccount: string;
  networkId: number;
  contract: ethers.Contract | null;
}

interface ContextProps {
  loading: boolean;
  contract: ethers.Contract | null;
  provider: ethers.providers.Web3Provider | null;
  register: () => Promise<boolean>;
  getRegistryKey: (address: string) => Promise<string | null>;
}

const ETHSIGN_ONETAP_REGISTRY_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_REGISTER_ADDRESS;

const RegistryContractContext = createContext<ContextProps | null>(null);

const RegistryContractProvider: React.FC = (props) => {
  const dispatch = useDispatch();
  const { library, connector, account, chainId, active } = useWeb3React();
  const { showError } = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [contract, setContract] = useState<ethers.Contract | null>(null);
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const { biconomy, isBiconomyReady } = useBiconomy();

  const isPolygonChain = chainId === POLYGON_CHAIN_ID;
  const byPassBiconomy =
    active && (connector === connectors.safeApp || process.env.REACT_APP_BYPASS_BICONOMY === 'true');

  useEffect(() => {
    const init = async () => {
      await initalize();
    };

    if (!contract && isPolygonChain && isBiconomyReady) {
      init();
    }
  }, [active, account, contract, isBiconomyReady]);

  const initalize = async (): Promise<RegistryContract | null> => {
    if (!account || !ETHSIGN_ONETAP_REGISTRY_ADDRESS) {
      return null;
    }
    try {
      const provider: ethers.providers.Web3Provider = byPassBiconomy
        ? new ethers.providers.Web3Provider(await connector?.getProvider(), 'any')
        : new ethers.providers.Web3Provider(biconomy.provider, 'any');

      const contract = byPassBiconomy
        ? new ethers.Contract(ETHSIGN_ONETAP_REGISTRY_ADDRESS, EthSignPublicEncryptionKeyRegistry.abi, provider)
        : new ethers.Contract(
            ETHSIGN_ONETAP_REGISTRY_ADDRESS,
            EthSignPublicEncryptionKeyRegistry.abi,
            biconomy.ethersProvider
          );

      setContract(contract);
      setProvider(provider);

      return {
        provider: provider,
        ethAccount: account?.toLowerCase(),
        networkId: POLYGON_CHAIN_ID,
        contract: contract
      };
    } catch (err) {
      showError((err as Error).message);
      return null;
    }
  };

  const register = async (): Promise<boolean> => {
    if (!contract || !account || !biconomy) {
      return false;
    }

    const provider: ethers.providers.Web3Provider = byPassBiconomy
      ? new ethers.providers.Web3Provider(await connector?.getProvider(), 'any')
      : new ethers.providers.Web3Provider(biconomy.provider, 'any');

    if (!provider) {
      return false;
    }

    try {
      setLoading(true);
      const signer = provider.getSigner(account);

      const instance = contract.connect(signer);

      const encryptionPublicKey = await library.send('eth_getEncryptionPublicKey', [account]);

      if (!encryptionPublicKey) {
        setLoading(false);
        return false;
      }

      if (byPassBiconomy) {
        const transaction = await instance.register(encryptionPublicKey);

        if (transaction) {
          await transaction.wait(NUMBER_OF_CONFIRMATIONS);
          setLoading(false);
          dispatch(setOneTapEnabled({ oneTapEnabled: true }));
          return true;
        }
      } else {
        const { data } = await instance.populateTransaction.register(encryptionPublicKey);

        const txParams = {
          data: data,
          to: ETHSIGN_ONETAP_REGISTRY_ADDRESS,
          from: account,
          signatureType: 'EIP712_SIGN'
        };

        const { transactionId } = await provider.send('eth_sendTransaction', [txParams]);

        if (transactionId) {
          biconomy.on('txMined', (data: { msg: string; id: string; hash: string; receipt: string }) => {
            if (data && data.id && data.receipt) {
              setLoading(false);
              dispatch(setOneTapEnabled({ oneTapEnabled: true }));
            }
            return true;
          });
        }
      }
      return false;
    } catch (err) {
      setLoading(false);
      showError((err as Error).message);
      return false;
    }
  };

  const getRegistryKey = async (address: string): Promise<string | null> => {
    if (!contract || !account || !biconomy) {
      return null;
    }

    const provider: ethers.providers.Web3Provider = byPassBiconomy
      ? new ethers.providers.Web3Provider(await connector?.getProvider(), 'any')
      : new ethers.providers.Web3Provider(biconomy.provider, 'any');

    if (!provider) {
      return null;
    }

    try {
      const signer = provider.getSigner(account);
      const instance = contract.connect(signer);
      const key = await instance.registry(address);

      if (key) {
        return key;
      }

      return null;
    } catch (err) {
      showError((err as Error).message);
      return null;
    }
  };

  return (
    <RegistryContractContext.Provider
      value={{
        loading,
        contract,
        provider,
        register,
        getRegistryKey
      }}
      {...props}
    />
  );
};

const useRegistryContract = (): ContextProps => {
  const hookData = useContext(RegistryContractContext);
  if (!hookData) throw new Error('Hook used without provider');
  return hookData;
};

export { RegistryContractProvider, useRegistryContract };
