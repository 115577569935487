import { WebViewerInstance, Core } from '@pdftron/webviewer';
import { SignerColor, AnnotationOptions, AnnotationField } from 'types';
import { isMobile, truncateAddress } from './utils';

export const ANNOTATION_SUBJECT_PREFIX = 'ethsign';

const SIGNER_COLORS: SignerColor[] = [
  {
    border: '#EAF1FF',
    default: '#0045F6',
    hover: '#003EDE'
  },
  {
    border: '#EEFBF5',
    default: '#14955D',
    hover: '#00704E'
  },
  {
    border: '#F7F4FA',
    default: '#5B2990',
    hover: '#521395'
  },
  {
    border: '#FEF9F9',
    default: '#E48C82',
    hover: '#CF5C10'
  },
  {
    border: '#FEFCF9',
    default: '#ECC18A',
    hover: '#F3BA00'
  }
];

export const getSignerColor = (index: number): SignerColor => {
  const new_index = index % SIGNER_COLORS.length;
  return SIGNER_COLORS[new_index];
};

export const getSignerCursor = (field: AnnotationField, index: number): string => {
  return `url(/lib/ui/assets/cursor/click_to_place_${field === AnnotationField.Signature ? 'signature' : 'rest'}_${
    (index % SIGNER_COLORS.length) + 1
  }.svg), crosshair`;
};

export const getDefaultTool = (): string => {
  return isMobile() ? 'Pan' : 'AnnotationEdit';
};

export const getAnnotContentWidth = (text: string, fontSize = 12): number => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  let width = 0;
  if (ctx) {
    ctx.font = `${fontSize}px san-serif`;
    width = ctx?.measureText(text).width;
  }
  return width;
};

export const getFontSize = (text: string, annotWidth: number, sigfield: boolean): number => {
  annotWidth = annotWidth - 16;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  let width = 0;
  let fontSize = 1;
  if (ctx) {
    if (sigfield) {
      while (width < annotWidth - fontSize - fontSize / 2) {
        fontSize = fontSize + 0.5;
        ctx.font = `${fontSize}px san-serif`;
        width = ctx?.measureText(text).width;
      }
    } else {
      while (width < annotWidth) {
        fontSize = fontSize + 0.5;
        ctx.font = `${fontSize}px san-serif`;
        width = ctx?.measureText(text).width;
      }
    }
  }
  return fontSize;
};

export const getAnnotationContent = (options: AnnotationOptions): string => {
  const { field, signer } = options;
  let text = signer.user.nickname || truncateAddress(signer.user.address, 4);
  switch (field) {
    case AnnotationField.Signature: {
      text = text + ' Signature';
      break;
    }
    case AnnotationField.Address: {
      text = signer.user.address;
      break;
    }
    case AnnotationField.DateSigned: {
      text = text + ' date signed';
      break;
    }
    case AnnotationField.Text: {
      text = text + ' text field';
      break;
    }
    case AnnotationField.Checkbox: {
      text = '';
    }
  }
  return text;
};

export const drawRoundRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
): void => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
};

export const downloadBlob = (blob: Blob, fileName: string): void => {
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.setAttribute('target', '_blank');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const getThumbnail = (instance: WebViewerInstance): Promise<HTMLCanvasElement> => {
  const { Core } = instance;
  const { documentViewer } = Core;
  const doc = documentViewer.getDocument();
  const pageNum = 1;

  return new Promise((resolve) => {
    doc.loadThumbnail(pageNum, (thumbnail: HTMLCanvasElement) => {
      resolve(thumbnail);
    });
  });
};

export const createSignatureWidget = (
  instance: WebViewerInstance,
  annotation: Core.Annotations.Annotation,
  index: number
): Core.Annotations.WidgetAnnotation => {
  const { Core } = instance;
  const field = new Core.Annotations.Forms.Field('EthSign Signature Field' + Date.now() + index, {
    type: 'Sig'
  });

  const signatureAnnotation = new Core.Annotations.SignatureWidgetAnnotation(field, {
    appearance: '_DEFAULT',
    appearances: {
      _DEFAULT: {
        Normal: {
          data: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
          offset: {
            x: 0,
            y: 0
          }
        }
      }
    }
  });

  signatureAnnotation.createSignHereElement = () => {
    const div = document.createElement('div');
    div.style.height = '100%';
    div.style.width = '100%';
    div.style.cursor = 'pointer';
    const inlineImg = `<img src="assets/sign_here.svg" style="width:inherit; height:100%;"></img>`;
    div.innerHTML = inlineImg;
    return div;
  };

  signatureAnnotation.setX(annotation.getX());
  signatureAnnotation.Y = annotation.getY();
  signatureAnnotation.Width = annotation.getWidth();
  signatureAnnotation.Height = annotation.getHeight();

  return signatureAnnotation;
};

export const createTextWidget = (
  instance: WebViewerInstance,
  annotation: Core.Annotations.Annotation,
  required: boolean
): Core.Annotations.WidgetAnnotation => {
  const { Core } = instance;
  const flags = new Core.Annotations.WidgetFlags({ Multiline: true, Required: required, ReadOnly: false });
  const field = new Core.Annotations.Forms.Field('ReadOnlyText', { type: 'Tx', value: 'Click to add text', flags });

  const textAnnotation = new Core.Annotations.TextWidgetAnnotation(field, {});
  textAnnotation.X = annotation.X;
  textAnnotation.Y = annotation.Y;
  textAnnotation.Width = annotation.Width;
  textAnnotation.Height = annotation.Height;

  return textAnnotation;
};

export const createCheckButtonWidget = (
  instance: WebViewerInstance,
  annotation: Core.Annotations.Annotation,
  required: boolean
): Core.Annotations.WidgetAnnotation => {
  const { Core } = instance;
  const flags = new Core.Annotations.WidgetFlags({ Required: required, Edit: true });
  const field = new Core.Annotations.Forms.Field('Checkbox', { type: 'Btn', value: 'off', flags });

  const checkboxAnnotation = new Core.Annotations.CheckButtonWidgetAnnotation(field, {
    appearance: 'Off',
    appearances: { Off: {}, Yes: {} },
    captions: { Normal: '' }
  });
  checkboxAnnotation.X = annotation.X + annotation.Width / 2 - 10;
  checkboxAnnotation.Y = annotation.Y + annotation.Height / 2 - 10;
  checkboxAnnotation.Width = 20;
  checkboxAnnotation.Height = 20;

  return checkboxAnnotation;
};

export default { getSignerColor, getDefaultTool, getAnnotationContent, getFontSize, downloadBlob };
