import './LoadingIcon.scss';

export const LoadingIcon = ({ ...props }) => {
  return (
    <div className="lds-ring" {...props}>
      <div></div>
      <div></div>
    </div>
  );
};
