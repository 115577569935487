/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { ethers } from 'ethers';
import { useBiconomy } from './useBiconomy';
import Ethsign from '../artifacts/EthSignV4.json';
import { useWebViewer } from './useWebViewer';
import { getEncryptedStringFromFile } from 'utils/file-utils';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { ArweavePayload, ContractProgress, EncryptMethod, RecipientType, StoragePayload } from 'types';
import { clearTxState, updateTxContractProgress } from 'store/txStateSlice';
import { savePendingTransaction } from 'store/contractSlice';
import { useToast } from './useToast';
import { getEncryptedMessage } from 'utils/eth-utils';
import CryptoJS from 'crypto-js';
import { useDecrypt } from './useDecrypt';
import {
  GAS_LIMIT,
  NUMBER_OF_CONFIRMATIONS,
  POLYGON_CHAIN_ID,
  SIGNATURE_VERSION,
  UPLOAD_SIGNATURE_STATEMENT
} from 'utils/constants';
import { postUploadToStorage } from 'services/storage';
import { useWeb3React } from '@web3-react/core';
import { connectors } from 'utils/connectors';
import { useGnosisSafeSDK } from './useGnosisSafeSDK';

interface EthSignContract {
  provider: ethers.providers.Web3Provider;
  ethAccount: string;
  networkId: number;
  contract: ethers.Contract | null;
}

interface ContextProps {
  contract: ethers.Contract | null;
  initialize: () => Promise<EthSignContract | null>;
  create: () => Promise<boolean>;
  sign: () => Promise<boolean>;
}

export const EIP712_SAFE_TX_TYPE = {
  // "SafeTx(address to,uint256 value,bytes data,uint8 operation,uint256 safeTxGas,uint256 baseGas,uint256 gasPrice,address gasToken,address refundReceiver,uint256 nonce)"
  SafeTx: [
    { type: 'address', name: 'to' },
    { type: 'uint256', name: 'value' },
    { type: 'bytes', name: 'data' },
    { type: 'uint8', name: 'operation' },
    { type: 'uint256', name: 'safeTxGas' },
    { type: 'uint256', name: 'baseGas' },
    { type: 'uint256', name: 'gasPrice' },
    { type: 'address', name: 'gasToken' },
    { type: 'address', name: 'refundReceiver' },
    { type: 'uint256', name: 'nonce' }
  ]
};

const ETHSIGN_CONTRACT_SIGN_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_SIGN_ADDRESS;

const EthSignContractContext = createContext<ContextProps | null>(null);

const EthSignContractProvider: React.FC = (props) => {
  const dispatch = useDispatch();
  const { showError } = useToast();
  const { connector, active, account, chainId } = useWeb3React();
  const { biconomy, isBiconomyReady } = useBiconomy();
  const { docViewer, exportAnnotationsForNewContract, exportAnnotationsForSigner, getOptimizedDocument } =
    useWebViewer();
  const { AESKey } = useDecrypt();
  const { isGnosisSafeSDKReady, sdkInstance } = useGnosisSafeSDK();

  const [contract, setContract] = useState<ethers.Contract | null>(null);
  const {
    recipients,
    passPhase,
    passwordEncryption,
    contractName,
    contractExpiry,
    current,
    oneTapEncryption,
    pendingTransaction
  } = useSelector((state: RootState) => state.contracts);

  const isPolygonChain = chainId === POLYGON_CHAIN_ID;
  const byPassBiconomy =
    active && (connector === connectors.safeApp || process.env.REACT_APP_BYPASS_BICONOMY === 'true');

  useEffect(() => {
    const init = async () => {
      await initialize();
    };

    if (isBiconomyReady && !contract && isPolygonChain) {
      init();
    }
  }, [account, contract, biconomy, isBiconomyReady, isPolygonChain]);

  const initialize = async (): Promise<EthSignContract | null> => {
    if (!account || !ETHSIGN_CONTRACT_SIGN_ADDRESS || !biconomy) {
      return null;
    }

    try {
      const provider: ethers.providers.Web3Provider = byPassBiconomy
        ? new ethers.providers.Web3Provider(await connector?.getProvider(), 'any')
        : new ethers.providers.Web3Provider(biconomy.provider, 'any');

      if (!provider) {
        return null;
      }

      const contract = byPassBiconomy
        ? new ethers.Contract(ETHSIGN_CONTRACT_SIGN_ADDRESS, Ethsign.abi, provider)
        : new ethers.Contract(ETHSIGN_CONTRACT_SIGN_ADDRESS, Ethsign.abi, biconomy.ethersProvider);

      setContract(contract);

      return {
        provider: provider,
        ethAccount: account?.toLowerCase(),
        networkId: POLYGON_CHAIN_ID,
        contract: contract
      };
    } catch (err) {
      showError((err as Error).message);
      return null;
    }
  };

  const create = async (): Promise<boolean> => {
    if (!docViewer || !contract) {
      return false;
    }

    const provider = byPassBiconomy
      ? new ethers.providers.Web3Provider(await connector?.getProvider(), 'any')
      : new ethers.providers.Web3Provider(biconomy.provider, 'any');

    if (!provider) {
      return false;
    }

    dispatch(updateTxContractProgress(ContractProgress.INITIATED));
    try {
      dispatch(updateTxContractProgress(ContractProgress.ENCRYPTING_FILE));
      const doc = docViewer.getDocument();

      let rawDataHash = '';

      if (!pendingTransaction || !pendingTransaction.transactionId) {
        // get xfdf string from pdfviewer via export function
        const fdfString = await exportAnnotationsForNewContract();

        // options to get file data from pdfviewer
        const options = {
          flatten: false,
          finishedWithDocument: false,
          printDocument: false,
          downloadType: 'pdf',
          includeAnnotations: false
        };

        // get original pdf file data
        // no xfdf string will be included with the file data
        // in case we need to use the original pdf file to be uploaded ???
        const fileData = await doc.getFileData(options);

        // generate buffer string Unit8Array from file
        const buffer = await getOptimizedDocument(new Uint8Array(fileData));

        // file blob to be upload with json structure to arweave network
        const fileBlob = new Blob([buffer], { type: 'application/pdf' });

        let encryptionKey = ''; // AES encryption key

        // encrypted recipient public keys
        let recipientKeys: {
          [key: string]: string;
        }[] = [];

        if (oneTapEncryption) {
          encryptionKey = CryptoJS.lib.WordArray.random(16).toString();
          recipientKeys = recipients.map((recipient) => ({
            [recipient.user.address]: getEncryptedMessage(recipient.user.regKey as string, encryptionKey)
          }));
        } else if (passwordEncryption) {
          encryptionKey = passPhase;
        }

        // encrypted file string
        const encryptedFile = await getEncryptedStringFromFile(fileBlob, encryptionKey);
        // encrypted signature string
        const encryptedSignature = CryptoJS.AES.encrypt(fdfString, encryptionKey).toString();

        // payload to be uploaded on arweave via new storage service
        // breaking changes introduced for one-tap encryption
        // ver 4.1 indicates one-tap encryption breaking on payload
        const payload: ArweavePayload = {
          recipientKeys: recipientKeys,
          fileStr: encryptedFile,
          fdfString: encryptedSignature,
          meta: {
            version: '4.1' // increase the version when breaking changes introduced
          }
        };

        dispatch(updateTxContractProgress(ContractProgress.UPLOADING_TO_ARWEAVE));

        const transactionId = await getTransactionIdFromStorageUpload(payload);

        if (!transactionId) {
          dispatch(updateTxContractProgress(ContractProgress.UPLOADING_FAILED));
          setTimeout(() => dispatch(clearTxState()), 5000);
          return false;
        }

        dispatch(savePendingTransaction({ transactionId }));

        rawDataHash = transactionId;
      } else {
        rawDataHash = pendingTransaction.transactionId;
      }

      // ethsign contract
      const signers = recipients.filter((item) => item.type === RecipientType.SIGNER).map((item) => item.user.address);
      const viewers = recipients.filter((item) => item.type === RecipientType.VIEWER).map((item) => item.user.address);

      const instance = contract.connect(provider.getSigner());

      const signerStep = Array(signers.length).fill(0);
      const signersPerStep = [signers.length];
      const signersData: ethers.BigNumber[] = [];

      for (let i = 0; i < signers.length; ++i) {
        const data = await contract.encodeSignerData(signers[i], signerStep[i]);
        signersData.push(data);
      }

      dispatch(updateTxContractProgress(ContractProgress.AWAITING_TRANSACION));

      const { maxFeePerGas, maxPriorityFeePerGas } = await getMaxFeePerGas();

      const encryptionMethod: EncryptMethod = oneTapEncryption
        ? EncryptMethod.ONETAP
        : passwordEncryption
        ? EncryptMethod.PASSWORD
        : EncryptMethod.DEFAULT;

      try {
        if (byPassBiconomy) {
          const transaction = await instance.create(
            contractName,
            contractExpiry,
            rawDataHash,
            signersPerStep,
            signersData,
            viewers,
            encryptionMethod,
            { maxFeePerGas, maxPriorityFeePerGas, gasLimit: GAS_LIMIT }
          );

          dispatch(updateTxContractProgress(ContractProgress.WAITING_FOR_CONFIRMATIONS));

          if (transaction) {
            await transaction.wait(NUMBER_OF_CONFIRMATIONS);
            dispatch(updateTxContractProgress(ContractProgress.SUCCESS));
            dispatch(savePendingTransaction(null));

            return true;
          }
        } else {
          const { data } = await instance.populateTransaction.create(
            contractName,
            contractExpiry,
            rawDataHash,
            signersPerStep,
            signersData,
            viewers,
            encryptionMethod,
            { maxFeePerGas, maxPriorityFeePerGas, gasLimit: GAS_LIMIT }
          );

          const txParams = {
            data: data,
            to: ETHSIGN_CONTRACT_SIGN_ADDRESS,
            from: account,
            signatureType: 'EIP712_SIGN'
          };

          const { transactionId } = await provider.send('eth_sendTransaction', [txParams]);

          dispatch(updateTxContractProgress(ContractProgress.WAITING_FOR_CONFIRMATIONS));

          if (transactionId) {
            biconomy.on('txMined', (data: { msg: string; id: string; hash: string; receipt: string }) => {
              if (data && data.id && data.receipt) {
                dispatch(updateTxContractProgress(ContractProgress.SUCCESS));
                dispatch(savePendingTransaction(null));
              }
            });
          }
        }
      } catch (err) {
        showError((err as Error).message);
        dispatch(updateTxContractProgress(ContractProgress.FAILED));
        setTimeout(() => dispatch(clearTxState()), 5000);
      }
    } catch (err) {
      console.log(err);
      showError((err as Error).message);
      dispatch(updateTxContractProgress(ContractProgress.INITIATING_FAILED));
      setTimeout(() => dispatch(clearTxState()), 5000);
    }

    return false;
  };

  const sign = async (): Promise<boolean> => {
    if (!docViewer || !contract || !account) {
      return false;
    }

    const provider = byPassBiconomy
      ? new ethers.providers.Web3Provider(await connector?.getProvider(), 'any')
      : new ethers.providers.Web3Provider(biconomy.provider, 'any');

    if (!provider) {
      return false;
    }

    if (!current || !current.id) {
      return false;
    }

    dispatch(updateTxContractProgress(ContractProgress.INITIATED));
    try {
      if (!AESKey && current.encrypted === EncryptMethod.PASSWORD) {
        dispatch(updateTxContractProgress(ContractProgress.INITIATING_FAILED));
        setTimeout(() => dispatch(clearTxState()), 5000);
        return false;
      }

      const encryptionKey = AESKey || '';

      dispatch(updateTxContractProgress(ContractProgress.ENCRYPTING_FILE));

      const instance = contract.connect(provider.getSigner());
      const index = current.recipients.findIndex((item) => item.id.toLowerCase() === account.toLowerCase());
      const rawDataHash = current.pdfTxId;
      let rawSignatureDataHash = '';

      if (!pendingTransaction || !pendingTransaction.transactionId) {
        const fdfString = await exportAnnotationsForSigner();
        const encryptedSignature = CryptoJS.AES.encrypt(fdfString, encryptionKey).toString();

        const payload: ArweavePayload = {
          recipientKeys: [],
          fileStr: '',
          fdfString: encryptedSignature,
          meta: {
            version: '4.1' // increse the version when breaking changes introduced
          }
        };

        dispatch(updateTxContractProgress(ContractProgress.UPLOADING_TO_ARWEAVE));

        const transactionId = await getTransactionIdFromStorageUpload(payload);

        if (!transactionId) {
          dispatch(updateTxContractProgress(ContractProgress.UPLOADING_FAILED));
          setTimeout(() => dispatch(clearTxState()), 5000);
          return false;
        }

        dispatch(savePendingTransaction({ transactionId }));

        rawSignatureDataHash = transactionId;
      } else {
        rawSignatureDataHash = pendingTransaction.transactionId;
      }

      dispatch(updateTxContractProgress(ContractProgress.AWAITING_TRANSACION));

      const EIP712_CONSTANTS = {
        DOMAIN_DATA: {
          name: 'EthSign',
          version: '4',
          chainId: POLYGON_CHAIN_ID,
          verifyingContract: ETHSIGN_CONTRACT_SIGN_ADDRESS,
          salt: '0xad27b301e5f37100ff157cc76d31929cff6e67812684f9f8bc3d7f70865dd810'
        },
        STRUCT_TYPES: {
          Contract: [
            { name: 'contractId', type: 'bytes32' },
            { name: 'rawDataHash', type: 'string' },
            { name: 'rawSignatureDataHash', type: 'string' }
          ]
        }
      };

      const message = {
        contractId: current.id,
        rawDataHash: rawDataHash,
        rawSignatureDataHash: rawSignatureDataHash
      };

      try {
        const { maxFeePerGas, maxPriorityFeePerGas } = await getMaxFeePerGas();

        try {
          if (byPassBiconomy) {
            const signature = await await provider
              .getSigner()
              ._signTypedData(EIP712_CONSTANTS.DOMAIN_DATA, EIP712_CONSTANTS.STRUCT_TYPES, message);

            const transaction = await instance.sign(current.id, index, signature, rawSignatureDataHash, {
              maxFeePerGas,
              maxPriorityFeePerGas,
              gasLimit: GAS_LIMIT
            });

            dispatch(updateTxContractProgress(ContractProgress.WAITING_FOR_CONFIRMATIONS));

            if (transaction) {
              await transaction.wait(NUMBER_OF_CONFIRMATIONS);
              dispatch(updateTxContractProgress(ContractProgress.SUCCESS));
              dispatch(savePendingTransaction(null));

              return true;
            }

            dispatch(updateTxContractProgress(ContractProgress.FAILED));
            setTimeout(() => dispatch(clearTxState()), 5000);

            return false;
          } else {
            const signature = await provider
              .getSigner()
              ._signTypedData(EIP712_CONSTANTS.DOMAIN_DATA, EIP712_CONSTANTS.STRUCT_TYPES, message);

            const { data } = await instance.populateTransaction.sign(
              current.id,
              index,
              signature,
              rawSignatureDataHash,
              {
                maxFeePerGas,
                maxPriorityFeePerGas,
                gasLimit: GAS_LIMIT
              }
            );

            const txParams = {
              data: data,
              to: ETHSIGN_CONTRACT_SIGN_ADDRESS,
              from: account,
              signatureType: 'EIP712_SIGN'
            };

            const { transactionId } = await provider.send('eth_sendTransaction', [txParams]);

            dispatch(updateTxContractProgress(ContractProgress.WAITING_FOR_CONFIRMATIONS));

            if (transactionId) {
              biconomy.on('txMined', (data: { msg: string; id: string; hash: string; receipt: string }) => {
                if (data && data.id && data.receipt) {
                  dispatch(updateTxContractProgress(ContractProgress.SUCCESS));
                  dispatch(savePendingTransaction(null));
                }
              });
              return true;
            } else {
              dispatch(updateTxContractProgress(ContractProgress.FAILED));
              setTimeout(() => dispatch(clearTxState()), 5000);

              return false;
            }
          }
        } catch (err) {
          showError((err as Error).message);
          dispatch(updateTxContractProgress(ContractProgress.FAILED));
          setTimeout(() => dispatch(clearTxState()), 5000);

          return false;
        }
      } catch (err) {
        dispatch(updateTxContractProgress(ContractProgress.FAILED));
        setTimeout(() => dispatch(clearTxState()), 5000);
        return false;
      }
    } catch (err) {
      dispatch(updateTxContractProgress(ContractProgress.INITIATING_FAILED));
      setTimeout(() => dispatch(clearTxState()), 5000);

      return false;
    }
  };

  const getTransactionIdFromStorageUpload = async (payload: ArweavePayload) => {
    const provider = byPassBiconomy
      ? new ethers.providers.Web3Provider(await connector?.getProvider(), 'any')
      : new ethers.providers.Web3Provider(biconomy.provider, 'any');

    if (!provider) {
      return null;
    }

    // prepare message to sign before upload
    const messagePayload = {
      address: await provider.getSigner().getAddress(),
      timestamp: new Date().toISOString(),
      version: SIGNATURE_VERSION,
      hash: ethers.utils.hashMessage(JSON.stringify(payload))
    };

    // messages converted to string before sign with statement prefix
    const message = `${UPLOAD_SIGNATURE_STATEMENT}\n\n~\n\n${JSON.stringify(messagePayload, null, 2)}`;

    // sign signature with the messages in details
    const signature = await provider.getSigner().signMessage(message);

    if (isGnosisSafeSDKReady && sdkInstance && byPassBiconomy) {
      let messageIsSigned = false;
      while (!messageIsSigned) {
        messageIsSigned = await sdkInstance.safe.isMessageSigned(message);
      }
    }

    // payload to upload arweave storage
    const storagePayload: StoragePayload = {
      signature,
      message,
      data: JSON.stringify(payload)
    };

    console.log('==== storagePayload ====', storagePayload);

    const response = await postUploadToStorage(storagePayload);

    return response.data.transaction.itemId;
  };

  const getMaxFeePerGas = async () => {
    let maxFeePerGas = ethers.BigNumber.from(40000000000); // fallback to 40 gwei
    let maxPriorityFeePerGas = ethers.BigNumber.from(40000000000); // fallback to 40 gwei
    try {
      const { data } = await axios({
        method: 'get',
        url: 'https://gasstation.polygon.technology/v2'
      });
      maxFeePerGas = ethers.utils.parseUnits(Math.ceil(data.fast.maxFee) + '', 'gwei');
      maxPriorityFeePerGas = ethers.utils.parseUnits(Math.ceil(data.fast.maxPriorityFee) + '', 'gwei');
    } catch (err) {
      showError((err as Error).message);
    }

    return {
      maxFeePerGas,
      maxPriorityFeePerGas
    };
  };

  return (
    <EthSignContractContext.Provider
      value={{
        contract,
        initialize,
        create,
        sign
      }}
      {...props}
    />
  );
};

const useEthSignContract = (): ContextProps => {
  const hookData = useContext(EthSignContractContext);
  if (!hookData) throw new Error('Hook used without provider');
  return hookData;
};

export { EthSignContractProvider, useEthSignContract };
