import React from 'react';

import {
  ArrowDownIcon,
  CopyIcon,
  EditIcon,
  KeyIcon,
  LogOutIcon,
  ThemeIcon,
  QAIcon,
  FeedbackIcon,
  MoreIcon,
  CloseIcon,
  ContractIcon,
  GearIcon,
  LockIcon,
  FolderIcon,
  PencilIcon,
  TrashIcon,
  WalletIcon,
  MetamaskIcon,
  CoinbaseIcon,
  WalletConnectIcon,
  GoogleIcon,
  TwitterIcon,
  DiscordIcon,
  GithubIcon,
  LoadingIcon,
  ArrowIcon,
  ChevronRight,
  ArrowThin,
  CheckIcon,
  SearchIcon,
  HeavyCheckIcon,
  PendingLoadingIcon,
  PlusOutlined,
  LayoutIcon
} from 'components/Icons';

const IconPanel = () => {
  return (
    <div className="flex flex-wrap">
      <div className="flex align-center min-w-[150px]">
        <ArrowDownIcon />
        <span className="py-4">ArrowDownIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <CopyIcon />
        <span className="py-4">CopyIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <EditIcon />
        <span className="py-4">EditIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <KeyIcon />
        <span className="py-4">KeyIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <LogOutIcon />
        <span className="py-4">LogOutIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <ThemeIcon />
        <span className="py-4">ThemeIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <QAIcon />
        <span className="py-4">QAIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <FeedbackIcon />
        <span className="py-4">FeedbackIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <MoreIcon />
        <span className="py-4">MoreIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <CloseIcon />
        <span className="py-4">CloseIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <ContractIcon />
        <span className="py-4">ContractIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <GearIcon />
        <span className="py-4">GearIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <LockIcon />
        <span className="py-4">LockIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <FolderIcon />
        <span className="py-4">FolderIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <PencilIcon />
        <span className="py-4">PencilIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <TrashIcon />
        <span className="py-4">TrashIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <WalletIcon />
        <span className="py-4">WalletIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <MetamaskIcon />
        <span className="py-4">MetamaskIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <CoinbaseIcon />
        <span className="py-4">CoinbaseIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <WalletConnectIcon />
        <span className="py-4">WalletConnectIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <GoogleIcon />
        <span className="py-4">GoogleIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <TwitterIcon />
        <span className="py-4">TwitterIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <DiscordIcon />
        <span className="py-4">DiscordIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <GithubIcon />
        <span className="py-4">GithubIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <LoadingIcon />
        <span className="py-4">LoadingIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <ArrowIcon />
        <span className="py-4">ArrowIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <ChevronRight />
        <span className="py-4">ChevronRight</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <ArrowThin />
        <span className="py-4">ArrowThin</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <CheckIcon />
        <span className="py-4">CheckIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <SearchIcon />
        <span className="py-4">SearchIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <HeavyCheckIcon />
        <span className="py-4">HeavyCheckIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <PendingLoadingIcon />
        <span className="py-4">PendingLoadingIcon</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <PlusOutlined />
        <span className="py-4">PlusOutlined</span>
      </div>
      <div className="flex align-center min-w-[150px]">
        <LayoutIcon />
        <span className="py-4">LayoutIcon</span>
      </div>
    </div>
  );
};

export default IconPanel;
