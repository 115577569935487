import { Identifier, XYCoord } from 'dnd-core';
import { FC, HTMLAttributes, useRef } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';

import { DragHandlerIcon } from 'components/Icons';
import { RecipientType } from 'types/Recipient';
import { DragItem } from './RecipientList';

export interface RecipientDragWrapperProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
  index: number;
  type: RecipientType;
  onSwap: (dragIndex: number, hoverIndex: number) => void;
  onTypeChange: (id: string, type: RecipientType) => void;
}

export const RecipientDragWrapper: FC<RecipientDragWrapperProps> = ({
  id,
  type,
  index,
  onSwap,
  onTypeChange,
  children,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'signer',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }

      if (type !== item.recipientType) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onSwap(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
    drop(item: DragItem) {
      if (type !== item.recipientType) {
        onTypeChange(item.id, type);
        return;
      }
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'signer',
    item: () => {
      return { id, index, recipientType: type };
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId} {...rest}>
      <DragHandlerIcon className="-ml-3 -mr-2 cursor-move text-gray-600" />
      {children}
    </div>
  );
};
