import { SVGProps } from 'react';

export const WalletIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="77" height="74" viewBox="0 0 77 74" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M61.5398 22.1992H14.0398V20.3492L55.8398 17.0932V20.3492H61.5398V14.7992C61.5398 10.7292 58.154 7.87282 54.0196 8.44632L15.8638 13.7521C11.7256 14.3293 8.33984 18.1292 8.33984 22.1992V59.1992C8.33984 61.1618 9.14056 63.044 10.5658 64.4318C11.9911 65.8196 13.9242 66.5992 15.9398 66.5992H61.5398C63.5555 66.5992 65.4886 65.8196 66.9139 64.4318C68.3391 63.044 69.1398 61.1618 69.1398 59.1992V29.5992C69.1398 27.6366 68.3391 25.7544 66.9139 24.3666C65.4886 22.9789 63.5555 22.1992 61.5398 22.1992ZM55.8398 48.1214C55.0911 48.1212 54.3497 47.9773 53.658 47.6981C52.9663 47.4189 52.3378 47.0097 51.8086 46.494C51.2793 45.9783 50.8595 45.3661 50.5731 44.6925C50.2868 44.0188 50.1396 43.2968 50.1398 42.5677C50.1401 41.8386 50.2878 41.1167 50.5746 40.4433C50.8614 39.7698 51.2816 39.1579 51.8112 38.6425C52.3409 38.1271 52.9696 37.7184 53.6615 37.4396C54.3534 37.1608 55.0949 37.0175 55.8436 37.0177C57.3559 37.0182 58.806 37.6036 59.8749 38.6451C60.9439 39.6866 61.5441 41.099 61.5436 42.5714C61.5431 44.0439 60.9419 45.4558 59.8723 46.4966C58.8026 47.5375 57.3521 48.1219 55.8398 48.1214Z"
        fill="currentColor"
      />
    </svg>
  );
};
