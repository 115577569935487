import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { DataTable, DataType, Order, TableColumnData } from 'components/DataTable';
import { ArrowIcon } from 'components/Icons';
import { MenuWrapper } from 'components/Menu';
import { useEffect, useState } from 'react';
import { isEmpty, get } from 'lodash';

import {
  ContractNameCell,
  ContractStatusCell,
  ContractDepartmentCell,
  ContractDateCell,
  ContractActionCell
} from './ContractTableCells';
import { StatusFilter } from './ContractTableFilters';
import { IMenu } from 'components/Menu/MenuItem';
import { Contract, ContractStatus } from 'types';

export interface ContractDataTableProps {
  contracts: Contract[];
  onDownloadContract?: (row: Contract) => void;
  openLoginModal?: () => void;
}

export const STATUS_FILTERS = {
  [ContractStatus.COMPLETED]: 'Completed',
  [ContractStatus.EXPIRED]: 'Expired',
  [ContractStatus.SIGN]: 'Pending Signature',
  [ContractStatus.VIEW]: 'Waiting for Others'
};

const sortItems = (contracts: Contract[], columns: TableColumnData[], order: Record<string, Order>) => {
  if (!isEmpty(order)) {
    const column = columns.find((column: TableColumnData) => column.index === Object.keys(order)[0]);

    if (column?.dataType === DataType.DATE) {
      return contracts.sort((a, b) => {
        const aDate = new Date(get(a, [column.index]));
        const bDate = new Date(get(b, [column.index]));
        return order?.[column.index] === Order.DESC
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      });
    }
    if (column?.dataType === DataType.STRING) {
      return contracts.sort((a, b) => {
        return order[column.index] === Order.ASC
          ? get(a, [column.index]).localeCompare(get(b, [column.index]))
          : get(b, [column.index]).localeCompare(get(a, [column.index]));
      });
    }
    if (column?.dataType === DataType.NUMBER) {
      return contracts.sort((a, b) => {
        return order[column.index] === Order.DESC
          ? get(a, [column.index]) - get(b, [column.index])
          : get(b, [column.index]) - get(a, [column.index]);
      });
    }
    return contracts;
  } else {
    return contracts;
  }
};

const filterItem = (contracts: Contract[], filter: Record<string, string[]>) => {
  let newContracts = [...contracts];
  if (!isEmpty(filter)) {
    Object.keys(filter).forEach((filterIndex) => {
      if (filterIndex === 'network' && filter?.[filterIndex].length > 0) {
        // When index === 'network' and at least one option is selected
        newContracts = newContracts.filter((contract) => filter?.[filterIndex].includes(contract.network));
      }
      if (filterIndex === 'status' && filter?.[filterIndex].length > 0) {
        newContracts = newContracts.filter((contract) => {
          // When index === 'status' and at least one option is selected
          return filter?.[filterIndex].includes(STATUS_FILTERS[contract.meta.status]);
        });
      }
    });
  }

  return newContracts;
};

const getNetworkFilters = (contracts: Contract[]) => {
  const treeMenus: IMenu[] = [];
  contracts.forEach((contract) => {
    const item = treeMenus.find((menu) => menu.name === contract.network);
    if (!item) {
      treeMenus.push({
        id: uuid(),
        name: contract.network,
        current: false,
        children: []
      });
    }
  });
  return treeMenus;
};

const getStatusFilters = (contracts: Contract[]) => {
  const treeMenus: IMenu[] = [];

  contracts.forEach((contract) => {
    const status = STATUS_FILTERS[contract.meta.status];

    const item = treeMenus.find((menu) => menu.name === status);
    if (!item) {
      treeMenus.push({
        id: uuid(),
        name: status,
        count: contracts.filter((c: Contract) => c.meta.status === contract.meta.status).length,
        current: false,
        children: []
      });
    }
  });

  return treeMenus;
};

export const ContractDataTable = ({ contracts, openLoginModal, onDownloadContract }: ContractDataTableProps) => {
  const [order, setOrder] = useState<Record<string, Order>>({});
  const [filter, setFilter] = useState<Record<string, string[]>>({});
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentRowOpen, setCurrentRowOpen] = useState<string>('');

  useEffect(() => {
    setCurrentPage(0);
  }, [filter]);

  const columns = [
    {
      index: 'name',
      title: 'Name',
      dataType: DataType.STRING,
      width: '300px',
      cellComponent: ContractNameCell,
      sortItem: (
        <MenuWrapper header={<ArrowIcon className={classNames('table-head-sort-icon')} />}>
          <div className="pl-5 py-5">
            <fieldset>
              <div className="space-y-4">
                <div className="flex items-center">
                  <input
                    id="string_asc"
                    name="string_asc"
                    type="radio"
                    checked={order?.['name'] === Order.ASC}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.checked) {
                        setOrder({ name: Order.ASC });
                      }
                    }}
                    className="focus:ring-orange-500 h-4 w-4 text-orange-500 border-gray-300"
                  />
                  <label htmlFor="string_asc" className="ml-3 block text-caption text-gray-600">
                    From A-Z
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    id="string_desc"
                    name="string_desc"
                    type="radio"
                    checked={order?.['name'] === Order.DESC}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.checked) {
                        setOrder({ name: Order.DESC });
                      }
                    }}
                    className="focus:ring-orange-500 h-4 w-4 text-orange-500 border-gray-300"
                  />
                  <label htmlFor="string_desc" className="ml-3 block text-caption text-gray-600">
                    From Z-A
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
        </MenuWrapper>
      )
    },
    {
      index: 'status',
      title: 'Status',
      width: '215px',
      dataType: DataType.STRING,
      cellComponent: ContractStatusCell,
      filterItem: (
        <StatusFilter
          menuData={getStatusFilters(contracts)}
          filter={filter?.status}
          setFilter={(e: string[]) => setFilter({ ...filter, status: e })}
        />
      )
    },
    {
      index: 'network',
      title: 'Network',
      dataType: DataType.DATE,
      cellComponent: ContractDepartmentCell,
      filterItem: (
        <StatusFilter
          menuData={getNetworkFilters(contracts)}
          filter={filter?.network}
          setFilter={(e: string[]) => setFilter({ ...filter, network: e })}
        />
      )
    },
    {
      index: 'date',
      title: 'Modified',
      dataType: DataType.DATE,
      sortItem: (
        <MenuWrapper header={<ArrowIcon className={classNames('table-head-sort-icon')} />}>
          <div className="pl-5 py-5">
            <fieldset>
              <div className="space-y-4">
                <div className="flex items-center">
                  <input
                    id="date_asc"
                    name="date_asc"
                    type="radio"
                    checked={order?.['date'] === Order.ASC}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.checked) {
                        setOrder({ date: Order.ASC });
                      }
                    }}
                    className="focus:ring-orange-500 h-4 w-4 text-orange-500 border-gray-300"
                  />
                  <label htmlFor="date_asc" className="ml-3 block text-caption text-gray-600">
                    Newest First
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    id="date_desc"
                    name="date_desc"
                    type="radio"
                    checked={order?.['date'] === Order.DESC}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.checked) {
                        setOrder({ date: Order.DESC });
                      }
                    }}
                    className="focus:ring-orange-500 h-4 w-4 text-orange-500 border-gray-300"
                  />
                  <label htmlFor="date_desc" className="ml-3 block text-caption text-gray-500">
                    Oldest First
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
        </MenuWrapper>
      ),
      cellComponent: ContractDateCell
    },
    {
      index: 'action',
      title: 'Action',
      dataType: DataType.STRING,
      cellComponent: ({ row }: { row: Contract }) => (
        <ContractActionCell
          currentRowOpen={currentRowOpen}
          row={row}
          onDownload={onDownloadContract}
          setCurrentRowOpen={setCurrentRowOpen}
        />
      )
    }
  ];

  useEffect(() => {
    let defaultOrder: Record<string, Order> = {};
    columns.forEach((column: TableColumnData) => {
      if (column.sortItem) {
        defaultOrder = { [column.index]: Order.ASC };
      }
    });
    setOrder(defaultOrder);
  }, []);

  return (
    <DataTable
      columns={columns}
      data={sortItems(filterItem(contracts, filter), columns, order)}
      openLoginModal={openLoginModal}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  );
};
