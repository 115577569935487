import { useState, useEffect, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';

import { connectors } from 'utils/connectors';
import { toHex } from 'utils/utils';
import { setOpenLoginModal } from 'store/userSlice';
import { useDispatch } from 'react-redux';
import { ProviderType } from 'types';
import { get } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { POLYGON_CHAIN_ID } from 'utils/constants';

type ConnectReturnType = {
  tried: boolean;
  switchToPolygon: () => void;
};

export function useEagerConnect(): ConnectReturnType {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigateTo = useNavigate();
  const { activate, deactivate, active, library, chainId } = useWeb3React();
  const loginType = window.localStorage.getItem('provider') as ProviderType;

  const [tried, setTried] = useState(false);

  useEffect(() => {
    (async () => {
      if (location.pathname === '/') {
        return;
      }

      if (loginType === ProviderType.INJECTED) {
        const { ethereum } = window;

        let provider;

        const hasMultipleProviders = !!ethereum.providers;

        if (hasMultipleProviders) {
          provider = ethereum.providers.find(({ isMetaMask }: { isMetaMask: boolean }) => isMetaMask);
          ethereum.setSelectedProvider(provider);
        } else {
          provider = ethereum;
        }

        if (!provider || !ethereum.isMetaMask) {
          return;
        }

        const isAuthorized = await connectors[loginType].isAuthorized();

        if (isAuthorized) {
          await activate(connectors[loginType]);
          dispatch(setOpenLoginModal(false));
          setTried(true);
        }
      } else if (location.pathname !== '/') {
        const connector = get(connectors, [loginType]);

        if (connector) {
          await activate(connector);
          dispatch(setOpenLoginModal(false));
          setTried(true);
        }
      }
    })();
  }, [activate, loginType]);

  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  const switchToPolygon = useCallback(() => {
    (async () => {
      if (!library && !active) {
        return;
      }

      if (chainId !== POLYGON_CHAIN_ID) {
        try {
          await library.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: toHex(POLYGON_CHAIN_ID) }]
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902) {
            await library.provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainName: 'Polygon Mainnet',
                  chainId: toHex(POLYGON_CHAIN_ID),
                  nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
                  rpcUrls: ['https://polygon-rpc.com/']
                }
              ]
            });
          } else if (err.code === 4001) {
            dispatch(setOpenLoginModal(false));
            deactivate();

            if (loginType === ProviderType.TORUS) {
              await connectors[loginType].close();
            }

            window.localStorage.clear();
            navigateTo('/');
          }
        }
      }
    })();
  }, [library, active, chainId]);

  return { tried, switchToPolygon };
}
