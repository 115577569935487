export const HeavyCheckIcon = ({ ...props }) => {
  return (
    <svg width="15" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.9194 1.22218C13.0047 1.13514 13.1065 1.06599 13.2189 1.01879C13.3312 0.97158 13.4519 0.947266 13.5737 0.947266C13.6956 0.947266 13.8163 0.97158 13.9286 1.01879C14.041 1.06599 14.1428 1.13514 14.2281 1.22218C14.5856 1.58343 14.5906 2.16718 14.2406 2.53468L6.84937 11.2722C6.76545 11.3643 6.66361 11.4384 6.55009 11.4898C6.43656 11.5413 6.31374 11.569 6.18912 11.5714C6.06451 11.5737 5.94073 11.5506 5.82535 11.5034C5.70998 11.4563 5.60543 11.3861 5.51812 11.2972L1.02061 6.73968C0.847161 6.56279 0.75 6.32493 0.75 6.07718C0.75 5.82944 0.847161 5.59158 1.02061 5.41468C1.10592 5.32764 1.20774 5.25849 1.3201 5.21129C1.43246 5.16408 1.55311 5.13977 1.67499 5.13977C1.79687 5.13977 1.91752 5.16408 2.02988 5.21129C2.14224 5.25849 2.24406 5.32764 2.32936 5.41468L6.14437 9.28093L12.8944 1.24968C12.9021 1.24002 12.9105 1.23084 12.9194 1.22218Z"
        fill="currentColor"
      />
    </svg>
  );
};
