import { Provider, Providers, ProviderType } from 'types/Provider';
import { injected, torus, walletConnect, walletLink } from './connectors';

import {
  MetamaskIcon,
  CoinbaseIcon,
  WalletConnectIcon,
  GoogleIcon,
  DiscordIcon,
  TwitterIcon,
  GithubIcon
} from 'components/Icons';

export const METAMASK: Provider = {
  id: ProviderType.INJECTED,
  name: Providers.METAMASK,
  type: ProviderType.INJECTED,
  connector: injected,
  logos: [MetamaskIcon]
};

export const TORUS: Provider = {
  id: ProviderType.TORUS,
  name: Providers.TORUS,
  type: ProviderType.TORUS,
  description: 'Supported by Web3Auth',
  connector: torus,
  logos: [DiscordIcon, TwitterIcon, GoogleIcon, GithubIcon]
};

export const COINBASE: Provider = {
  id: ProviderType.WALLETLINK,
  name: Providers.COINBASE_WALLET,
  type: ProviderType.WALLETLINK,
  connector: walletLink,
  logos: [CoinbaseIcon]
};

export const WALLET_CONNECT: Provider = {
  id: ProviderType.WALLETCONNECT,
  name: Providers.WALLETCONNECT,
  type: ProviderType.WALLETCONNECT,
  connector: walletConnect,
  logos: [WalletConnectIcon]
};
