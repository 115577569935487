import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { ArrowThin } from 'components/Icons';
import { useWebViewer } from 'hooks';
import { RootState } from 'store';
import { Annotation, AnnotationField } from 'types';

const FIELD_LABELS: Record<AnnotationField, string> = {
  [AnnotationField.Signature]: 'Signature Field',
  [AnnotationField.DateSigned]: 'Date Signed',
  [AnnotationField.Checkbox]: 'Checkbox',
  [AnnotationField.Text]: 'Text Field',
  [AnnotationField.Address]: 'Address Field',
  [AnnotationField.Name]: 'Name Field'
};

export const SignerStatusBar = () => {
  const { instance, documentStatus } = useWebViewer();
  const { signerAnnotations } = useSelector((state: RootState) => state.contracts);

  const numberOfFilledAnnotations = useMemo(() => {
    return signerAnnotations.filter(
      (annotation) =>
        annotation.filled &&
        annotation.options.required &&
        ![AnnotationField.Address, AnnotationField.DateSigned].includes(annotation.options.field) // TODO: remove this after sometime
    ).length;
  }, [signerAnnotations]);

  const pageFields = useMemo(() => {
    if (!instance || documentStatus !== 'rendered') return [];
    const total = instance.Core.documentViewer.getPageCount();
    return Array(total)
      .fill(0)
      .map((_, index) => ({
        page: index + 1,
        annotations: signerAnnotations.filter((annotation) => annotation.page === index + 1)
      }))
      .filter((field) => field.annotations.length);
  }, [instance?.Core.documentViewer, documentStatus, signerAnnotations]);

  const onClickButton = (page: number) => {
    instance?.Core.documentViewer.setCurrentPage(page, true);
  };

  const numberOfRequiredAnnotations = useMemo(
    () =>
      signerAnnotations.filter(
        (annotation) =>
          ![AnnotationField.Address, AnnotationField.DateSigned].includes(annotation.options.field) && // TODO: remove this after sometime
          annotation.options.required
      ).length,
    [signerAnnotations]
  );

  const renderAnnotationsPerPage = (page: number, annotations: Annotation[]) =>
    annotations
      .filter(
        (annotation) =>
          ![AnnotationField.Address, AnnotationField.DateSigned].includes(annotation.options.field) &&
          annotation.options.required
      )
      .map(({ options, filled }, index) => (
        <li
          key={index}
          className="w-full px-7 py-2.5 group flex items-center cursor-pointer"
          onClick={() => onClickButton(page)}
        >
          <span
            className={classNames('w-[0.875rem] h-[0.875rem] mr-2 flex justify-center items-center border-2 rounded', {
              'border-gray-500': !filled,
              'border-orange-400': filled
            })}
          >
            {filled ? <ArrowThin className="text-orange-400" /> : null}
          </span>
          <span className="text-p5 text-gray-500">{FIELD_LABELS[options.field]}</span>
        </li>
      ));

  return (
    <>
      <h3 className="px-7 py-2.5 font-nexa font-bold text-h4 text-gray-600">
        {numberOfFilledAnnotations}/{numberOfRequiredAnnotations} Required Fields
      </h3>
      <div className="relative w-full h-2 bg-background after:content-[' '] after:absolute after:top-0 after:w-1/2 after:h-full after:bg-orange-200" />
      <div className="flex-1 w-full overflow-auto">
        {pageFields.map(({ page, annotations }) => (
          <div key={page}>
            <h3 className="px-7 py-1.5 bg-background font-nexa font-bold text-h4 text-gray-600">Page {page}</h3>
            <ul>{renderAnnotationsPerPage(page, annotations)}</ul>
          </div>
        ))}
      </div>
    </>
  );
};
