import { AxiosResponse } from 'axios';
import { ArweavePayload, StoragePayload, StorageResponse } from 'types';
import BaseAPI from './base';

export const postUploadToStorage = (data: StoragePayload): Promise<AxiosResponse<StorageResponse>> => {
  return BaseAPI.post(`${process.env.REACT_APP_ETHSIGN_API_URL}/upload`, data);
};

export const fetchTxOnArweave = async (txId: string): Promise<ArweavePayload> => {
  return await BaseAPI.get<{ message: string; transaction: ArweavePayload }>(
    `${process.env.REACT_APP_ETHSIGN_API_URL}/transaction/${txId}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ).then((response) => response.data.transaction);
};
