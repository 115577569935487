/* eslint-disable @typescript-eslint/no-explicit-any */
import { WebViewerInstance, Core } from '@pdftron/webviewer';
import { AnnotationField, AnnotationOptions } from 'types';
import { getAnnotationContent } from 'utils/pdfviewer';
import { parseJSON } from 'utils/utils';

const NativeMath = Math;

export const createSignerField = (
  author: string,
  instance: WebViewerInstance,
  stamp?: boolean
): Promise<WebViewerInstance> => {
  const { Core } = instance;
  const { documentViewer, annotationManager, Annotations } = Core;

  class SignerAnnotation extends Annotations.CustomAnnotation {
    constructor() {
      super('SigFieldTxt'); // provide the custom XFDF element name
      this.StrokeThickness = 0;
    }
    serialize(element: Element, pageMatrix: any) {
      const el = super.serialize(element, pageMatrix);
      return el;
    }
    deserialize(element: Element, pageMatrix: any) {
      super.deserialize(element, pageMatrix);
    }
    draw(ctx: CanvasRenderingContext2D, pageMatrix: any) {
      const zoom = documentViewer.getZoomLevel();
      this.setStyles(ctx, pageMatrix);

      const options: AnnotationOptions = parseJSON(this.getCustomData('options'));
      const meta = parseJSON(this.getCustomData('meta'));

      if (!options) return;

      const { field, signer, required } = options;
      const width = this.Width;
      const height = this.Height;
      const x = this.X;
      const y = this.Y;

      if (signer?.user?.address.toLowerCase() === author || meta?.signed) {
        let content = '';
        if (field === AnnotationField.Address) {
          content = signer?.user?.address;
        } else if (field === AnnotationField.DateSigned) {
          content = new Date(meta?.date).toLocaleDateString();
        }

        ctx.lineWidth = 1 / zoom;
        ctx.beginPath();

        const rotation = +this.getCustomData('rotation');
        ctx.rotate((rotation / 180) * NativeMath.PI);
        ctx.fillStyle = '#000';
        ctx.font = `12px san-serif`;
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        if (rotation == -90 || rotation == -270) {
          if (rotation == -90) {
            ctx.fillText(content, -y - height / 2, x + width / 2);
          } else {
            ctx.fillText(content, y + height / 2, -x - width / 2);
          }
        } else {
          if (rotation == -180) {
            ctx.fillText(content, -x + (width / 2 - width), -y + height / 2 - height);
          } else {
            ctx.fillText(content, x + width / 2, y + height / 2);
          }
        }
        ctx.stroke();
        ctx.save();
      } else {
        ctx.fillStyle = '#fff';
        ctx.strokeStyle = '#B5B9C8';

        const radius = 4;

        ctx.beginPath();
        ctx.setLineDash([5, 3]);
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.save();

        if (field === AnnotationField.Checkbox) {
          const checkboxSize = 16;
          ctx.fillStyle = '#fff';
          ctx.setLineDash([]);
          ctx.beginPath();
          ctx.rect(x + width / 2 - checkboxSize / 2, y + height / 2 - checkboxSize / 2, checkboxSize, checkboxSize);
          ctx.closePath();
          ctx.stroke();
        }

        ctx.fillStyle = '#6E7179';
        ctx.font = `12px san-serif`;
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillText(getAnnotationContent(options), x + width / 2, y + height / 2);

        if ([AnnotationField.Signature, AnnotationField.Checkbox, AnnotationField.Text].includes(field) && required) {
          ctx.font = `16px san-serif`;
          ctx.fillText('*', x - 8, y + 8);
        }
      }
    }
  }
  // this is necessary to set the elementName before instantiation
  SignerAnnotation.prototype.elementName = 'SigFieldTxt';
  SignerAnnotation.SerializationType = stamp
    ? Annotations.CustomAnnotation.SerializationTypes.STAMP
    : Annotations.CustomAnnotation.SerializationTypes.CUSTOM;

  // register the annotation type so that it can be saved to XFDF files
  annotationManager.registerAnnotationType(SignerAnnotation.prototype.elementName, SignerAnnotation as any);

  (Annotations.WidgetAnnotation as any).getCustomStyles = (widget: Core.Annotations.WidgetAnnotation) => {
    const meta = parseJSON(widget.getCustomData('meta'));
    // Checkbox styling
    if (widget instanceof Annotations.CheckButtonWidgetAnnotation) {
      return meta?.signed && widget.Author !== author
        ? {}
        : {
            width: 'calc(100% - 8px)',
            height: 'calc(100% - 8px)',
            margin: '4px',
            'background-color': '#FDF7F3',
            border: '1px solid #D97D40',
            color: '#D97D40',
            'border-radius': '2px'
          };
    }
    // Custom text styling
    if (widget instanceof Annotations.TextWidgetAnnotation) {
      const borderType = widget.getCustomData('borderType');
      return meta?.signed && widget.Author !== author
        ? {}
        : {
            padding: '8px',
            'padding-top': '8px',
            'background-color': '#FDF7F3',
            border: borderType == '0' ? 'none' : '1px dashed #D97D40',
            color: '#D97D40',
            'border-radius': '4px'
          };
    }
  };

  (Annotations.WidgetAnnotation as any).getContainerCustomStyles = (widget: Core.Annotations.WidgetAnnotation) => {
    const meta = parseJSON(widget.getCustomData('meta'));
    // Checkbox styling
    if (widget instanceof Annotations.CheckButtonWidgetAnnotation) {
      return meta?.signed && widget.Author !== author
        ? {}
        : {
            'background-color': '#FDF7F3',
            border: '1px dashed #D97D40',
            'border-radius': '4px'
          };
    }
  };

  Annotations.setCustomCreateInnerElementHandler(
    Annotations.TextWidgetAnnotation as any,
    function (annotationManager, { annotation, originalCreateInnerElement }) {
      const el: HTMLElement = originalCreateInnerElement();
      el.addEventListener('click', () => {
        el.focus();
        annotation.setCustomData('borderType', '1');

        if (annotation.value === 'Click to add text') {
          annotation.setValue('');
          annotation.setWidth(30);
        }
      });
      el.addEventListener('focus', () => {
        annotation.setCustomData('borderType', '1');

        if (annotation.value === 'Click to add text') {
          annotation.setValue('');
          annotation.setWidth(30);
        }
      });
      el.addEventListener('blur', () => {
        annotation.setCustomData('borderType', '0');

        if (annotation.value === '') {
          annotation.setValue('Click to add text');
          annotation.setWidth(120);
          annotation.setCustomData('borderType', '1');
        }
      });
      el.addEventListener('keydown', () => {
        const newWidth = Math.max(30, 25 + (annotation.value as string).length * 6);
        annotation.setWidth(newWidth);
      });
      return el;
    }
  );

  return Promise.resolve(instance);
};
