import Dashboard from 'pages/Dashboard';
import Settings from 'pages/Settings';
import CreateContract from 'pages/Create';
import Recipients from 'pages/Recipients';
import Review from 'pages/Review';
import PrepareContract from 'pages/Prepare';

import { GearIcon, ContractIcon } from 'components/Icons';
import { Sign } from 'pages/Sign';

export interface IRoute {
  icon: ({ ...props }: { [x: string]: unknown }) => JSX.Element;
  url: string;
  title: string;
  isMenu: boolean;
  hasHeader: boolean;
  notInLayout?: boolean;
  component: () => JSX.Element;
}

export const routes: IRoute[] = [
  {
    icon: ContractIcon,
    url: '/',
    title: 'Contracts',
    isMenu: true,
    hasHeader: true,
    component: Dashboard
  },
  {
    icon: GearIcon,
    url: '/settings',
    title: 'Settings',
    hasHeader: false,
    isMenu: true,
    component: Settings
  },
  {
    icon: GearIcon,
    url: '/create',
    title: 'Create a contract',
    isMenu: false,
    hasHeader: false,
    component: CreateContract
  },
  {
    icon: GearIcon,
    url: '/recipients',
    title: 'Manage Recipients',
    isMenu: false,
    hasHeader: false,
    component: Recipients
  },
  {
    icon: GearIcon,
    url: '/prepare-document',
    title: 'Prepare Document',
    isMenu: false,
    hasHeader: false,
    component: PrepareContract
  },
  {
    icon: GearIcon,
    url: '/review',
    title: 'Review and Send',
    isMenu: false,
    hasHeader: false,
    component: Review
  },
  {
    icon: GearIcon,
    url: '/sign/:id',
    title: 'Sign Document',
    isMenu: false,
    hasHeader: false,
    component: Sign
  }
];
