export const SendIcon = ({ ...props }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.03208 32.0842C8.82779 31.2885 8.82779 30.2939 8.03208 29.4981C7.23637 28.7024 6.24174 28.7024 5.44603 29.4981L2.85997 32.0842C2.06426 32.8799 2.06426 33.8746 2.85997 34.6703C3.65568 35.466 4.65031 35.466 5.44603 34.6703L8.03208 32.0842Z"
      fill="currentColor"
    />
    <path
      d="M15.7906 32.0841C14.9949 31.2884 14.0003 31.2884 13.2046 32.0841L5.44639 39.8423C4.65068 40.638 4.65068 41.6326 5.44639 42.4283C6.2421 43.224 7.23674 43.224 8.03245 42.4283L15.5917 34.8691C16.3874 34.0734 16.3874 32.8798 15.7906 32.0841Z"
      fill="currentColor"
    />
    <path
      d="M42.4467 0.255301L2.66116 14.9759C-0.919536 16.3684 -0.919536 21.3416 2.86009 22.5352L13.6022 26.1159C15.5915 26.7127 17.7797 26.3148 19.57 25.1213L38.0703 10.0028L22.9518 28.503C21.5593 30.0944 21.1614 32.2826 21.9571 34.4709L25.5378 45.2129C26.7314 48.7936 31.9035 48.9926 33.0971 45.4119L47.6188 5.82527C48.8124 2.24458 45.6295 -0.938265 42.4467 0.255301Z"
      fill="currentColor"
    />
    <path
      d="M15.7903 39.8419L13.2042 42.428C12.4085 43.2237 12.4085 44.2183 13.2042 45.014C13.9999 45.8097 14.9946 45.8097 15.7903 45.014L18.3763 42.428C19.172 41.6322 19.172 40.6376 18.3763 39.8419C17.5806 39.0462 16.387 39.0462 15.7903 39.8419Z"
      fill="currentColor"
    />
  </svg>
);
