import { SVGProps } from 'react';

export const EyeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.4375 8C14.4375 8.91168 14.0753 9.78602 13.4307 10.4307C12.786 11.0753 11.9117 11.4375 11 11.4375C10.0883 11.4375 9.21398 11.0753 8.56932 10.4307C7.92466 9.78602 7.5625 8.91168 7.5625 8C7.5625 7.08832 7.92466 6.21398 8.56932 5.56932C9.21398 4.92466 10.0883 4.5625 11 4.5625C11.9117 4.5625 12.786 4.92466 13.4307 5.56932C14.0753 6.21398 14.4375 7.08832 14.4375 8Z"
        fill="currentColor"
      />
      <path
        d="M0 8C0 8 4.125 0.4375 11 0.4375C17.875 0.4375 22 8 22 8C22 8 17.875 15.5625 11 15.5625C4.125 15.5625 0 8 0 8ZM11 12.8125C12.2764 12.8125 13.5004 12.3055 14.403 11.403C15.3055 10.5004 15.8125 9.27635 15.8125 8C15.8125 6.72365 15.3055 5.49957 14.403 4.59705C13.5004 3.69453 12.2764 3.1875 11 3.1875C9.72365 3.1875 8.49957 3.69453 7.59705 4.59705C6.69453 5.49957 6.1875 6.72365 6.1875 8C6.1875 9.27635 6.69453 10.5004 7.59705 11.403C8.49957 12.3055 9.72365 12.8125 11 12.8125Z"
        fill="currentColor"
      />
    </svg>
  );
};
