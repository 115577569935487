import { SVGProps } from 'react';

export const MoreIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.125 9.75C4.46972 9.75 4.81106 9.8179 5.12954 9.94982C5.44802 10.0817 5.7374 10.2751 5.98116 10.5188C6.22491 10.7626 6.41827 11.052 6.55018 11.3705C6.6821 11.6889 6.75 12.0303 6.75 12.375C6.75 12.7197 6.6821 13.0611 6.55018 13.3795C6.41827 13.698 6.22491 13.9874 5.98116 14.2312C5.7374 14.4749 5.44802 14.6683 5.12954 14.8002C4.81106 14.9321 4.46972 15 4.125 15C3.42881 15 2.76113 14.7234 2.26884 14.2312C1.77656 13.7389 1.5 13.0712 1.5 12.375C1.5 11.6788 1.77656 11.0111 2.26884 10.5188C2.76113 10.0266 3.42881 9.75 4.125 9.75ZM12 9.75C12.3447 9.75 12.6861 9.8179 13.0045 9.94982C13.323 10.0817 13.6124 10.2751 13.8562 10.5188C14.0999 10.7626 14.2933 11.052 14.4252 11.3705C14.5571 11.6889 14.625 12.0303 14.625 12.375C14.625 12.7197 14.5571 13.0611 14.4252 13.3795C14.2933 13.698 14.0999 13.9874 13.8562 14.2312C13.6124 14.4749 13.323 14.6683 13.0045 14.8002C12.6861 14.9321 12.3447 15 12 15C11.3038 15 10.6361 14.7234 10.1438 14.2312C9.65156 13.7389 9.375 13.0712 9.375 12.375C9.375 11.6788 9.65156 11.0111 10.1438 10.5188C10.6361 10.0266 11.3038 9.75 12 9.75ZM19.875 9.75C20.2197 9.75 20.5611 9.8179 20.8795 9.94982C21.198 10.0817 21.4874 10.2751 21.7312 10.5188C21.9749 10.7626 22.1683 11.052 22.3002 11.3705C22.4321 11.6889 22.5 12.0303 22.5 12.375C22.5 12.7197 22.4321 13.0611 22.3002 13.3795C22.1683 13.698 21.9749 13.9874 21.7312 14.2312C21.4874 14.4749 21.198 14.6683 20.8795 14.8002C20.5611 14.9321 20.2197 15 19.875 15C19.1788 15 18.5111 14.7234 18.0188 14.2312C17.5266 13.7389 17.25 13.0712 17.25 12.375C17.25 11.6788 17.5266 11.0111 18.0188 10.5188C18.5111 10.0266 19.1788 9.75 19.875 9.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
