import { createSlice } from '@reduxjs/toolkit';
import { ProviderType } from 'types';

export interface UserState {
  alias?: string;
  avatar?: string;
  loginType?: ProviderType;
  oneTapEnabled: boolean;
  regKey: string | null;
  email?: string;
  darkTheme: boolean;
  openLoginModal: boolean;
}

const userSlice = createSlice({
  name: 'auth',
  initialState: {
    oneTapEnabled: false,
    regKey: null,
    darkTheme: false,
    openLoginModal: false,
    loginType: undefined
  } as UserState,
  reducers: {
    setAlias(state, action) {
      state.alias = action.payload;
    },
    setAvatar(state, action) {
      state.avatar = action.payload;
    },
    setTheme(state, action) {
      state.darkTheme = action.payload;
    },
    setLoginType(state, action) {
      state.loginType = action.payload;
    },
    setOpenLoginModal(state, action) {
      state.openLoginModal = action.payload;
    },
    setOneTapEnabled(state, action) {
      state.oneTapEnabled = action.payload.oneTapEnabled;
      state.regKey = action.payload.regKey;
    },
    setEmail(state, action) {
      state.email = action.payload;
    }
  }
});

export const { setAlias, setAvatar, setLoginType, setOneTapEnabled, setEmail, setTheme, setOpenLoginModal } =
  userSlice.actions;

export const userReducer = userSlice.reducer;
