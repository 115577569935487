import { IconProps } from '.';

export const ChevronRight = ({ width, height, ...props }: IconProps) => {
  return (
    <svg
      width={width || 6}
      height={height || 9}
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.977192 8.07975C0.87174 7.97427 0.8125 7.83122 0.8125 7.68207C0.8125 7.53291 0.87174 7.38986 0.977192 7.28438L3.76157 4.50001L0.977192 1.71564C0.874728 1.60955 0.818031 1.46746 0.819313 1.31998C0.820594 1.17249 0.879752 1.03141 0.984044 0.927117C1.08834 0.822825 1.22942 0.763669 1.3769 0.762387C1.52439 0.761106 1.66648 0.817802 1.77257 0.920266L4.95463 4.10232C5.06008 4.20781 5.11932 4.35085 5.11932 4.50001C5.11932 4.64916 5.06008 4.79221 4.95463 4.8977L1.77257 8.07975C1.66708 8.18521 1.52403 8.24445 1.37488 8.24445C1.22572 8.24445 1.08268 8.18521 0.977192 8.07975Z"
        fill="currentColor"
      />
    </svg>
  );
};
