import AccountSection from './AccountSection';
import ConnectionSection from './ConnectionSection';
import KeylessSection from './KeylessSection';

const Settings = (): JSX.Element => {
  return (
    <div className="bg-settings-background bg-no-repeat bg-right-bottom">
      <h2 className="py-4 text-heading3 text-gray-600">Settings</h2>
      <div className="divide-y divide-solid relative">
        <AccountSection />
        <ConnectionSection />
        <KeylessSection />
      </div>
    </div>
  );
};

export default Settings;
