export const FolderIcon = ({ ...props }) => {
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.172855 7.5C0.172855 5.51088 0.862452 3.60322 2.08994 2.1967C3.31743 0.790176 4.98227 0 6.7182 0H13.6929C14.5438 0 15.3685 0.315 16.0493 0.9L20.2383 4.5H32.8891C34.6251 4.5 36.2899 5.29018 37.5174 6.6967C38.7449 8.10322 39.4345 10.0109 39.4345 12V12.006H10.1846C9.27269 12.0048 8.38882 12.3673 7.68467 13.0313C6.98051 13.6953 6.49997 14.6194 6.32548 15.645L2.19668 39.915C1.55636 39.2157 1.04678 38.3746 0.698877 37.4427C0.350974 36.5108 0.17201 35.5076 0.172855 34.494V7.5ZM4.81482 40.221C4.77814 40.4381 4.78383 40.6619 4.83147 40.8762C4.8791 41.0905 4.96751 41.2901 5.09036 41.4607C5.21321 41.6313 5.36746 41.7687 5.54207 41.8629C5.71667 41.9572 5.90731 42.0061 6.10032 42.006H34.6799C35.5913 42.0057 36.4743 41.6421 37.1774 40.9776C37.8805 40.3132 38.3601 39.3892 38.5338 38.364L42.1861 16.794C42.2233 16.5766 42.2179 16.3526 42.1705 16.1379C42.123 15.9232 42.0347 15.7232 41.9118 15.5522C41.7889 15.3813 41.6345 15.2437 41.4597 15.1492C41.2848 15.0548 41.0939 15.0059 40.9006 15.006H10.1846C9.88092 15.0058 9.5866 15.1266 9.35208 15.3477C9.11756 15.5688 8.95743 15.8765 8.89912 16.218L4.81482 40.218V40.221Z"
        fill="#FE8E5B"
      />
    </svg>
  );
};
