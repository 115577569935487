import { AxiosResponse } from 'axios';
import API from './api';
import { contractSignedEvent, contracts, viewerContracts, contractCompletedEvent } from 'graphql/queries';
import { Event, EthContract } from 'types/Event';

export const fetchContracts = (address: string): Promise<AxiosResponse<{ data: { contracts: EthContract[] } }>> => {
  return API.post('', { query: contracts(address) });
};

export const fetchViewerContracts = (
  address: string
): Promise<AxiosResponse<{ data: { contracts: EthContract[] } }>> => {
  return API.post('', { query: viewerContracts(address) });
};

export const fetchContractSignedEvent = (contractId: string): Promise<AxiosResponse<{ data: { events: Event[] } }>> => {
  return API.post('', { query: contractSignedEvent(contractId) });
};

export const fetchCompletedContract = (contractId: string): Promise<AxiosResponse<{ data: { events: Event[] } }>> => {
  return API.post('', { query: contractCompletedEvent(contractId) });
};
