import { FC, HTMLAttributes, useRef } from 'react';
import { Identifier } from 'dnd-core';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';

type DragItem = {
  id: string;
  index: number;
};

export interface FileCardDragWrapperProps extends HTMLAttributes<HTMLDivElement> {
  data: DragItem;
  onSwap: (dragIndex: number, hoverIndex: number) => void;
}

export const FileCardDragWrapper: FC<FileCardDragWrapperProps> = ({ data, onSwap, children, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'file',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: DragItem) {
      const dragIndex = item.index;
      const hoverIndex = data.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Time to actually perform the action
      onSwap(dragIndex, hoverIndex);

      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'file',
    item: () => {
      return data;
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId} {...rest}>
      {children}
    </div>
  );
};
