import { Modal, ModalContent, IModalProps } from 'components/Modal';

export const AwaitingTransactionModal = ({ ...rest }: IModalProps) => {
  return (
    <Modal {...rest}>
      <ModalContent hasCloseButton={false} className="w-1/2">
        <div className="md:px-12">
          <div className="text-center">
            <h3 className="text-heading2 text-gray-600" id="modal-title">
              Awaiting Transaction
            </h3>
            <div className="mt-4 mb-6">
              <p className="text-body text-gray-500">Please complete the transaction on your wallet</p>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
