export const ArrowIcon = ({ ...props }) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.3561 3.47731C1.46158 3.37186 1.60463 3.31262 1.75379 3.31262C1.90294 3.31262 2.04599 3.37186 2.15147 3.47731L4.93584 6.26169L7.72021 3.47731C7.8263 3.37485 7.96839 3.31815 8.11588 3.31944C8.26336 3.32072 8.40444 3.37987 8.50873 3.48417C8.61303 3.58846 8.67218 3.72954 8.67346 3.87703C8.67475 4.02451 8.61805 4.1666 8.51559 4.27269L5.33353 7.45475C5.22804 7.5602 5.085 7.61944 4.93584 7.61944C4.78669 7.61944 4.64364 7.5602 4.53816 7.45475L1.3561 4.27269C1.25065 4.1672 1.19141 4.02416 1.19141 3.875C1.19141 3.72585 1.25065 3.5828 1.3561 3.47731Z"
        fill="#6E7179"
      />
    </svg>
  );
};
