import { forwardRef, ReactElement } from 'react';
import classNames from 'classnames';

import { CloseIcon } from 'components/Icons';

interface IModalContentProps {
  className?: string;

  hasCloseButton?: boolean;

  hasPadding?: boolean;

  /**
   * Close callback
   *
   * You don't have to set this prop inside Modal component
   */
  onClose?: VoidFunction;
  children?: ReactElement;
}

export const ModalContent = forwardRef<HTMLDivElement, IModalContentProps>(function ModalContent(
  { className, hasCloseButton = true, hasPadding = true, children, onClose, ...rest },
  ref
) {
  const closeButtonDisplay = hasCloseButton ? (
    <button type="button" className="absolute top-1.5 right-1.5 sm:top-2.5 sm:right-4 p-1" onClick={onClose}>
      <CloseIcon />
    </button>
  ) : (
    //Focus-trap needs at least 1 focusable element
    <button type="button" className="cursor-default" onClick={onClose} />
  );

  return (
    <div
      ref={ref}
      className={classNames('relative w-fit overflow-hidden rounded-[1.25rem] bg-background', className, {
        'p-5 sm:p-10': hasPadding
      })}
      {...rest}
    >
      {closeButtonDisplay}
      {children}
    </div>
  );
});
