import { InjectedConnector } from '@web3-react/injected-connector';
import { TorusConnector } from '@web3-react/torus-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react';
import { POLYGON_CHAIN_ID } from './constants';

const CHAIN_IDS = [
  0, 1, 2, 3, 8, 10, 19, 20, 25, 30, 40, 52, 55, 56, 57, 60, 61, 66, 70, 82, 88, 100, 106, 108, 122, 128, 137, 200, 246,
  250, 269, 288, 321, 336, 361, 534, 592, 820, 888, 1088, 1284, 1285, 2020, 2612, 4181, 4689, 5050, 5551, 8217, 10000,
  32659, 42161, 42220, 42262, 43114, 71402, 200625, 333999, 1313161554, 1666600000, 11297108109, 836542336838601
];

export const injected = new InjectedConnector({ supportedChainIds: CHAIN_IDS });

export const torus = new TorusConnector({
  chainId: POLYGON_CHAIN_ID,
  initOptions: {
    network: {
      host: 'matic',
      chainId: POLYGON_CHAIN_ID,
      networkName: 'Matic Mainnet',
      blockExplorer: 'https://polygon-rpc.com',
      ticker: 'MATIC',
      tickerName: 'Matic'
    },
    showTorusButton: false
  }
});

export const walletConnect = new WalletConnectConnector({
  rpc: { [POLYGON_CHAIN_ID]: 'https://polygon-rpc.com' },
  chainId: POLYGON_CHAIN_ID,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true
});

export const walletLink = new WalletLinkConnector({
  url: `https://polygon-rpc.com`,
  appName: 'ethsign'
});

const safeMultisig = new SafeAppConnector();

export const connectors = {
  injected: injected,
  walletConnect: walletConnect,
  walletLink: walletLink,
  torus: torus,
  safeApp: safeMultisig
};
