export const LockIcon = ({ ...props }) => {
  return (
    <svg width="73" height="84" viewBox="0 0 73 84" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M69.3887 37.0781H62.416V14.1094C62.416 6.85986 56.5405 0.984375 49.291 0.984375H23.8613C16.6118 0.984375 10.7363 6.85986 10.7363 14.1094V37.0781H3.76367C1.94873 37.0781 0.482422 38.5444 0.482422 40.3594V79.7344C0.482422 81.5493 1.94873 83.0156 3.76367 83.0156H69.3887C71.2036 83.0156 72.6699 81.5493 72.6699 79.7344V40.3594C72.6699 38.5444 71.2036 37.0781 69.3887 37.0781ZM39.4473 61.3799V66.8145C39.4473 67.2656 39.0781 67.6348 38.627 67.6348H34.5254C34.0742 67.6348 33.7051 67.2656 33.7051 66.8145V61.3799C32.8587 60.7722 32.2269 59.9116 31.9007 58.922C31.5745 57.9324 31.5707 56.8648 31.8899 55.8729C32.2091 54.881 32.8348 54.0159 33.6769 53.4023C34.5191 52.7886 35.5342 52.458 36.5762 52.458C37.6182 52.458 38.6333 52.7886 39.4754 53.4023C40.3175 54.0159 40.9432 54.881 41.2624 55.8729C41.5816 56.8648 41.5778 57.9324 41.2517 58.922C40.9255 59.9116 40.2937 60.7722 39.4473 61.3799ZM55.0332 37.0781H18.1191V14.1094C18.1191 10.9409 20.6929 8.36719 23.8613 8.36719H49.291C52.4595 8.36719 55.0332 10.9409 55.0332 14.1094V37.0781Z"
        fill="#CED1D9"
      />
    </svg>
  );
};
