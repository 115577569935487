import { SVGProps } from 'react';

export const GithubIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2553_8953)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5562 0.203125C9.50615 0.203125 0.556152 9.15312 0.556152 20.2031C0.556152 29.0531 6.28115 36.5281 14.2312 39.1781C15.2312 39.3531 15.6062 38.7531 15.6062 38.2281C15.6062 37.7531 15.5812 36.1781 15.5812 34.5031C10.5562 35.4281 9.25615 33.2781 8.85615 32.1531C8.63115 31.5781 7.65615 29.8031 6.80615 29.3281C6.10615 28.9531 5.10615 28.0281 6.78115 28.0031C8.35615 27.9781 9.48115 29.4531 9.85615 30.0531C11.6562 33.0781 14.5312 32.2281 15.6812 31.7031C15.8562 30.4031 16.3812 29.5281 16.9562 29.0281C12.5062 28.5281 7.85615 26.8031 7.85615 19.1531C7.85615 16.9781 8.63115 15.1781 9.90615 13.7781C9.70615 13.2781 9.00615 11.2281 10.1062 8.47812C10.1062 8.47812 11.7812 7.95312 15.6062 10.5281C17.2062 10.0781 18.9062 9.85312 20.6062 9.85312C22.3062 9.85312 24.0062 10.0781 25.6062 10.5281C29.4312 7.92813 31.1062 8.47812 31.1062 8.47812C32.2062 11.2281 31.5062 13.2781 31.3062 13.7781C32.5812 15.1781 33.3562 16.9531 33.3562 19.1531C33.3562 26.8281 28.6812 28.5281 24.2312 29.0281C24.9562 29.6531 25.5812 30.8531 25.5812 32.7281C25.5812 35.4031 25.5562 37.5531 25.5562 38.2281C25.5562 38.7531 25.9312 39.3781 26.9312 39.1781C30.9014 37.8377 34.3514 35.2859 36.7956 31.8821C39.2397 28.4782 40.555 24.3936 40.5562 20.2031C40.5562 9.15312 31.6062 0.203125 20.5562 0.203125Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2553_8953">
          <rect width="40" height="40" fill="white" transform="translate(0.556152 0.203125)" />
        </clipPath>
      </defs>
    </svg>
  );
};
