import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { Core } from '@pdftron/webviewer';
import classNames from 'classnames';

import { LoadSpinner } from 'components/LoadSpinner';
import { Button, ButtonSize, ButtonVariant } from 'components/Button';
import { useDecrypt, useEthSignContract, useWebViewer } from 'hooks';
import { RootState } from 'store';
import { setSignerAnnotations, updateSignerAnnotation, initContract, updateContract } from 'store/contractSlice';
import { createSignerField } from 'utils/annotations/createSignField';
import { ContractProgress, EncryptMethod, RecipientType } from 'types';
import { ProcessingSignatureModal } from 'pages/Review/ProcessingSignatureModal';
import { AwaitingTransactionModal } from 'pages/Review/AwaitingTransactionModal';
import { setOpenLoginModal } from 'store/userSlice';
import { showSignProgress } from 'utils/sign';

export const Sign = () => {
  const { id: contractId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { account } = useWeb3React();
  const { instance, documentStatus, loadSignFields } = useWebViewer();
  const { sign } = useEthSignContract();
  const { decryptContract } = useDecrypt();

  const [isAwaitingTransactionModalOpen, setIsAwaitingTransactionModalOpen] = useState<boolean>(false);
  const [isProcessingSignatureOpen, setIsProcessingSignatureOpen] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [hasSigned, setHasSigned] = useState<boolean>(false);
  const [isViewer, setIsViewer] = useState<boolean>(false);

  const { current, fdfString, signerAnnotations } = useSelector((state: RootState) => state.contracts);
  const { status } = useSelector((state: RootState) => state.txState);

  useEffect(() => {
    return () => {
      instance?.UI.closeDocument();
      instance?.Core.documentViewer.closeDocument();
      dispatch(updateContract({ current: undefined }));
    };
  }, []);

  useEffect(() => {
    if (!account) {
      dispatch(setOpenLoginModal(true));
      dispatch(initContract({}));
    }
  }, [account]);

  useEffect(() => {
    (async () => {
      if (contractId && !current) {
        await decryptContract(contractId, false, undefined);
      }
    })();
  }, [contractId, account, current]);

  useEffect(() => {
    if (!instance || !fdfString) return;
    createSignerField(account?.toLowerCase() || '', instance);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (instance.UI as any).setUserAddress(account || '');
    instance.Core.documentViewer.addEventListener('annotationsLoaded', () => {
      instance.UI.openElements(['leftPanel']);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (instance.UI as any).enableBookmarks();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (instance.UI as any).disableToolbar();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (instance.UI as any).setActiveLeftPanel('thumbnailsPanel');
      instance?.Core.annotationManager.importAnnotations(fdfString).then((annotations) => {
        const signerFields = loadSignFields(account?.toLowerCase() || '', annotations, current?.recipients || []);
        dispatch(setSignerAnnotations(signerFields));
        instance.Core.documentViewer.refreshAll();
      });
    });
    return () => {
      instance.Core.documentViewer.removeEventListener('annotationsLoaded');
      instance.UI.closeDocument();
    };
  }, [instance, account, fdfString]);

  useEffect(() => {
    if (!instance) return;
    const { Core } = instance;
    Core.annotationManager.addEventListener('annotationChanged', (changedAnnotations, action, { imported }) => {
      if (imported) return;

      changedAnnotations.forEach((_annotation: Core.Annotations.Annotation) => {
        // update signature field if stamp annotation is added / deleted
        if (_annotation instanceof Core.Annotations.StampAnnotation) {
          const annotations = Core.annotationManager.getAnnotationsList();
          annotations.forEach((annotation) => {
            if (
              annotation instanceof Core.Annotations.SignatureWidgetAnnotation &&
              annotation.getAssociatedSignatureAnnotation()?.Id === _annotation.Id
            ) {
              dispatch(
                updateSignerAnnotation({
                  id: annotation.Id,
                  annotation: { filled: action === 'add' || action === 'modify' }
                })
              );
              annotation.getAssociatedSignatureAnnotation().NoMove = true;
              annotation.getAssociatedSignatureAnnotation().NoResize = true;
            }
          });
        }
      });
    });

    Core.annotationManager.addEventListener('fieldChanged', (field, value) => {
      const widgetAnnotation = field.widgets?.[0];
      if (!widgetAnnotation) return;

      const annotations = Core.annotationManager.getAnnotationsList();
      annotations.forEach((annotation) => {
        if (annotation.Id === widgetAnnotation.Id)
          if (annotation instanceof Core.Annotations.CheckButtonWidgetAnnotation) {
            // Checkbox update
            dispatch(
              updateSignerAnnotation({
                id: annotation.Id,
                annotation: { filled: value === 'Yes' }
              })
            );
          } else if (annotation instanceof Core.Annotations.TextWidgetAnnotation) {
            // Text input update
            dispatch(
              updateSignerAnnotation({
                id: annotation.Id,
                annotation: { filled: value !== 'Click to add text' }
              })
            );
          }
      });
    });

    return () => {
      Core.annotationManager.removeEventListener('annotationChanged');
      Core.annotationManager.removeEventListener('fieldChanged');
    };
  }, [instance]);

  useEffect(() => {
    const hasEncryption = current?.encrypted !== EncryptMethod.DEFAULT;
    showSignProgress(
      status,
      hasEncryption,
      dispatch,
      null,
      setIsProcessing,
      setIsAwaitingTransactionModalOpen,
      navigate
    );
  }, [status]);

  useEffect(() => {
    if (status === ContractProgress.AWAITING_TRANSACION) {
      setIsAwaitingTransactionModalOpen(true);
      return;
    }
    if (status === ContractProgress.WAITING_FOR_CONFIRMATIONS) {
      setIsAwaitingTransactionModalOpen(false);
      setIsProcessingSignatureOpen(true);
      return;
    }
  }, [status]);

  useEffect(() => {
    if (account && current && current.recipients.length > 0) {
      const signed =
        current.recipients.find((item) => item.id.toLowerCase() === account.toLowerCase())?.signed ?? false;
      const signer = current.recipients.find((item) => item.id.toLowerCase() === account.toLowerCase());
      setHasSigned(signed);
      setIsViewer(signer ? signer.type === RecipientType.VIEWER : true);
    }
  }, [current, account]);

  const toggleAwaitingTransactionModal = () => {
    setIsAwaitingTransactionModalOpen(!isAwaitingTransactionModalOpen);
  };

  const toggleProcessingSignatureModal = () => {
    setIsProcessingSignatureOpen(!isProcessingSignatureOpen);
  };

  const handleFinish = async () => {
    if (hasSigned || isViewer) {
      navigate('/');
    } else {
      setIsProcessing(true);
      await sign();
      setIsProcessing(false);
    }
  };

  const isReadyToSign = useMemo(
    () =>
      signerAnnotations.filter((annotation) => annotation.options.required).every((annotation) => annotation.filled),
    [signerAnnotations]
  );

  if (!current) {
    return (
      <div className="absolute w-screen h-screen top-0 left-0 flex flex-col justify-center items-center">
        <div className="absolute w-screen h-screen bg-gray-200 opacity-50" />
        <LoadSpinner />
        <span className="font-lato font-normal text-base text-gray-600">Processing...</span>
      </div>
    );
  }

  const isFinished = !isProcessing && isReadyToSign;
  return (
    <>
      <div
        className={classNames(
          'fixed w-[320px] py-6 px-3 bottom-0 right-0 flex justify-center bg-white transition-transform delay-[1000ms] duration-300 ease-in-out',
          {
            'translate-x-full': !isFinished || documentStatus !== 'rendered',
            'translate-x-0': isFinished && documentStatus === 'rendered'
          }
        )}
      >
        <Button
          fullWidth
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.CTA}
          onClick={handleFinish}
          disabled={!isFinished}
        >
          {hasSigned || isViewer ? 'Close' : 'Finish'}
        </Button>
      </div>
      <AwaitingTransactionModal
        shouldCloseOnBackdropClick
        shouldCloseOnEsc
        isOpen={isAwaitingTransactionModalOpen}
        onClose={toggleAwaitingTransactionModal}
      />
      <ProcessingSignatureModal
        shouldCloseOnBackdropClick
        shouldCloseOnEsc
        isOpen={isProcessingSignatureOpen}
        onClose={toggleProcessingSignatureModal}
      />
    </>
  );
};
