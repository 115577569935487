export const ArrowThin = ({ ...props }) => {
  return (
    <svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 2.5L2.5 3.83333L5 1.16667"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
