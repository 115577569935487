import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Web3ReactProvider } from '@web3-react/core';
import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { store } from 'store';
import SafeProvider from '@gnosis.pm/safe-apps-react-sdk';

import {
  ModalProvider,
  BiconomyProvider,
  EthSignContractProvider,
  WebViewerProvider,
  RegistryContractProvider,
  ToastProvider,
  DecryptProvider,
  GnosisSafeSDKProvider
} from 'hooks';

import App from './App';
import reportWebVitals from './reportWebVitals';

import 'assets/css/tailwind.css';
import 'assets/i18n/config';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import './assets/css/typography.css';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    enabled: process.env.NODE_ENV === 'production' ? true : false
  });
}

const getLibrary = (provider: ExternalProvider) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
};

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Provider store={store}>
        <ToastProvider>
          <Web3ReactProvider getLibrary={getLibrary}>
            <SafeProvider>
              <GnosisSafeSDKProvider>
                <WebViewerProvider>
                  <BiconomyProvider>
                    <DecryptProvider>
                      <EthSignContractProvider>
                        <RegistryContractProvider>
                          <ModalProvider>
                            <DndProvider backend={HTML5Backend}>
                              <Router>
                                <App />
                              </Router>
                            </DndProvider>
                          </ModalProvider>
                        </RegistryContractProvider>
                      </EthSignContractProvider>
                    </DecryptProvider>
                  </BiconomyProvider>
                </WebViewerProvider>
              </GnosisSafeSDKProvider>
            </SafeProvider>
          </Web3ReactProvider>
        </ToastProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
