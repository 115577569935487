import classNames from 'classnames';
import React, { LegacyRef, PropsWithChildren, useEffect, useRef, useState } from 'react';

interface IProps {
  handleDrop: (files: FileList) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const DragAndDrop = (props: PropsWithChildren<IProps>) => {
  const { children, handleDrop, onClick } = props;
  const [drag, setDrag] = useState<boolean>(false);
  const dropRef = useRef<HTMLDivElement>();
  const pointerClass = onClick ? 'cursor-pointer ' : '';
  let dragCounter = 0;

  const handleDrag = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const hasAvailableAndValidFiles = (e: DragEvent): boolean => {
    return !!(e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0);
  };

  const handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter += 1;

    const hasData = hasAvailableAndValidFiles(e);

    if (hasData) {
      setDrag(true);
    }
  };

  const handleDragOut = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter -= 1;

    if (dragCounter === 0) {
      setDrag(false);
    }
  };

  const handleFileDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);

    const hasFiles = hasAvailableAndValidFiles(e);

    if (e.dataTransfer && hasFiles) {
      handleDrop(e.dataTransfer.files);

      dragCounter = 0;
    }
  };

  const handleDragStart = (e: DragEvent) => {
    if (e) {
      e.dataTransfer?.clearData();
    }
  };

  useEffect(() => {
    const div = dropRef.current;

    div?.addEventListener('dragstart', handleDragStart);
    div?.addEventListener('dragenter', handleDragIn);
    div?.addEventListener('dragleave', handleDragOut);
    div?.addEventListener('dragover', handleDrag);
    div?.addEventListener('drop', handleFileDrop);

    return () => {
      div?.removeEventListener('dragstart', handleDragStart);
      div?.removeEventListener('dragenter', handleDragIn);
      div?.removeEventListener('dragleave', handleDragOut);
      div?.removeEventListener('dragover', handleDrag);
      div?.removeEventListener('drop', handleFileDrop);
    };
  }, []);

  const dragDisplay = drag && (
    <div className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-white bg-opacity-80">
      <div className="absolute right-0 left-0 text-center text-gray-600 text-4xl top-[35%]">
        <span>Drop here...</span>
      </div>
    </div>
  );

  return (
    <div
      className={classNames(
        'border-spaced-dashed border-orange-600 bg-orange-100 rounded-lg h-96 lg:h-full py-11 w-full flex flex-col items-center mb-16 relative',
        pointerClass
      )}
      ref={dropRef as LegacyRef<HTMLDivElement>}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => onClick?.(event)}
    >
      {dragDisplay}
      {children}
    </div>
  );
};

export default DragAndDrop;
