import React, { createContext, useContext, useEffect, useState } from 'react';
import SafeAppsSDK from '@gnosis.pm/safe-apps-sdk';

interface IGnosisSafeSDKContext {
  sdkInstance: SafeAppsSDK | null;
  isGnosisSafeSDKReady: boolean;
}

type Opts = {
  allowedDomains?: RegExp[];
  debug?: boolean;
};

const GnosisSafeSDKContext = createContext<IGnosisSafeSDKContext | null>(null);

const GnosisSafeSDKProvider: React.FC = (props) => {
  const [sdkInstance, setSdkInstance] = useState<SafeAppsSDK | null>(null);
  const [isGnosisSafeSDKReady, setIsGnosisSafeSDKReady] = useState(false);

  useEffect(() => {
    (async () => {
      setIsGnosisSafeSDKReady(false);

      const opts: Opts = {
        allowedDomains: [/gnosis-safe.io/, /staging.ethsign.xyz/, /www.ethsign.xyz/],
        debug: false
      };

      // create new instance for SafeAppSDK with options
      const sdkInstance = new SafeAppsSDK(opts);

      // get chain id from sdk to validate the instace has been created correctly
      const chain = await sdkInstance.safe.getChainInfo();

      // check if the chain info matched with polygon network so the app could perform
      if (chain.chainId === '137') {
        // set sdk instance for use
        setSdkInstance(sdkInstance);
        // set the sdk instance ready for instance validation
        setIsGnosisSafeSDKReady(true);
      }
    })();
  }, []);

  return (
    <GnosisSafeSDKContext.Provider
      value={{
        sdkInstance,
        isGnosisSafeSDKReady
      }}
      {...props}
    />
  );
};

const useGnosisSafeSDK = () => {
  const hookData = useContext(GnosisSafeSDKContext);
  if (!hookData) throw new Error('Hook used without provider');
  return hookData;
};

export { GnosisSafeSDKProvider, useGnosisSafeSDK };
