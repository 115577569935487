import classNames from 'classnames';
import { useState, useRef, useEffect } from 'react';
import { MenuItem, IMenu } from './MenuItem';

interface IProps {
  menus: IMenu[];
  children: JSX.Element;
  align?: string;
}

export const MenuWrapper = ({
  header,
  children,
  align = 'left'
}: {
  header: JSX.Element;
  align?: string;
  children: JSX.Element;
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node)) {
        setExpanded(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className="relative inline-block text-left">
      <div onClick={() => setExpanded(!isExpanded)}>{header}</div>
      <div
        className={classNames(
          'overflow-hidden origin-top-left absolute mt-2 w-[264px] rounded-2xl bg-white border border-solid border-gray-100 shadow-lg focus:outline-none z-10',
          { 'left-0': align === 'left' },
          { 'right-0': align === 'right' }
        )}
        ref={wrapperRef}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex={-1}
        style={{ display: isExpanded ? 'block' : 'none' }}
      >
        {children}
      </div>
    </div>
  );
};

export const Menu = ({ menus, children, align }: IProps) => {
  return (
    <MenuWrapper header={children} align={align}>
      <>
        {menus.map((el: IMenu) => (
          <MenuItem key={el.id} {...el} />
        ))}
      </>
    </MenuWrapper>
  );
};
