import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSafeAppConnection } from '@gnosis.pm/safe-apps-web3-react';

import { MainLayout } from 'layouts/MainLayout';
import { IRoute, routes } from 'layouts/routes';

import { useEagerConnect, useInactiveListener } from 'hooks';
import { connectors } from 'utils/connectors';
import IconPanel from 'pages/Icons';
import { useToast } from 'hooks/useToast';
import Toast from 'components/Toast';
import { ProviderType } from 'types';

function App() {
  const navigate = useNavigate();
  const { connector, active, account } = useWeb3React();
  const [activatingConnector, setActivatingConnector] = useState();
  const { isActive, message, toastType, title, CTA, closeToast } = useToast();

  useSafeAppConnection(connectors.safeApp);

  useEffect(() => {
    window.gtag('config', process.env.REACT_APP_GTAG_MEASUREMENT_ID, {
      user_id: account
    });
  }, [account]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    if (active && connector == connectors.safeApp) {
      navigate('/');
      localStorage.setItem('provider', ProviderType.SAFE);
    }
  }, [active]);

  const tried = useEagerConnect();
  useInactiveListener(!tried || !!activatingConnector);

  return (
    <MainLayout>
      <Routes>
        {routes?.map((route: IRoute) => {
          return (
            // When you use index of an array as a key, React will optimize and not render as expected.
            <Route key={route.url} path={route.url} element={<route.component />} />
          );
        })}
        <Route path="/icons" element={<IconPanel />} />
      </Routes>
      {isActive && <Toast type={toastType} onClose={closeToast} message={message} title={title} customCTA={CTA} />}
    </MainLayout>
  );
}

export default App;
