export const TwitterSettingIcon = ({ ...props }) => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_280_7001)">
        <path
          d="M21.3663 4.11366C20.6224 4.42199 19.8234 4.63033 18.9834 4.72449C19.8501 4.23932 20.4986 3.47572 20.8077 2.57616C19.9934 3.02865 19.1021 3.34716 18.1727 3.51783C17.5477 2.89355 16.7199 2.47977 15.8177 2.34072C14.9156 2.20168 13.9896 2.34515 13.1835 2.74887C12.3775 3.15259 11.7365 3.79396 11.36 4.57341C10.9835 5.35287 10.8927 6.22679 11.1015 7.05949C9.45147 6.98199 7.83728 6.58079 6.3637 5.88192C4.89013 5.18304 3.59011 4.20213 2.54801 3.00283C2.19169 3.57783 1.9868 4.24449 1.9868 4.95449C1.98641 5.59366 2.15466 6.22303 2.47664 6.78677C2.79862 7.35051 3.26436 7.83119 3.83255 8.18616C3.1736 8.16655 2.52919 7.99998 1.95295 7.70033V7.75033C1.95289 8.64678 2.28436 9.51564 2.89113 10.2095C3.49791 10.9033 4.3426 11.3794 5.28189 11.557C4.67061 11.7118 4.02972 11.7346 3.40764 11.6237C3.67265 12.395 4.18887 13.0695 4.88403 13.5528C5.57919 14.036 6.41848 14.3038 7.28442 14.3187C5.81444 15.3982 3.99903 15.9837 2.13022 15.9812C1.79918 15.9812 1.46842 15.9632 1.13965 15.927C3.0366 17.068 5.24478 17.6735 7.49999 17.6712C15.1342 17.6712 19.3076 11.7562 19.3076 6.62616C19.3076 6.45949 19.3032 6.29116 19.2951 6.12449C20.1069 5.57531 20.8076 4.89524 21.3645 4.11616L21.3663 4.11366Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_280_7001">
          <rect width="21.3793" height="20" fill="white" transform="translate(0.304688)" />
        </clipPath>
      </defs>
    </svg>
  );
};
