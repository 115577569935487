export const QAIcon = ({ ...props }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.436 1.5C6.6376 1.5 1.93604 6.20156 1.93604 12C1.93604 17.7984 6.6376 22.5 12.436 22.5C18.2345 22.5 22.936 17.7984 22.936 12C22.936 6.20156 18.2345 1.5 12.436 1.5ZM12.436 18.0938C11.9181 18.0938 11.4985 17.6742 11.4985 17.1562C11.4985 16.6383 11.9181 16.2188 12.436 16.2188C12.954 16.2188 13.3735 16.6383 13.3735 17.1562C13.3735 17.6742 12.954 18.0938 12.436 18.0938ZM13.9103 12.9492C13.698 13.0312 13.5154 13.1752 13.3862 13.3625C13.257 13.5498 13.1872 13.7717 13.186 13.9992V14.5312C13.186 14.6344 13.1017 14.7188 12.9985 14.7188H11.8735C11.7704 14.7188 11.686 14.6344 11.686 14.5312V14.0273C11.686 13.4859 11.8431 12.9516 12.1524 12.5062C12.4548 12.0703 12.8767 11.7375 13.3735 11.5477C14.1704 11.2406 14.686 10.5727 14.686 9.84375C14.686 8.81016 13.6759 7.96875 12.436 7.96875C11.1962 7.96875 10.186 8.81016 10.186 9.84375V10.0219C10.186 10.125 10.1017 10.2094 9.99854 10.2094H8.87354C8.77041 10.2094 8.68604 10.125 8.68604 10.0219V9.84375C8.68604 8.92266 9.08916 8.0625 9.82041 7.42266C10.5235 6.80625 11.4517 6.46875 12.436 6.46875C13.4204 6.46875 14.3485 6.80859 15.0517 7.42266C15.7829 8.0625 16.186 8.92266 16.186 9.84375C16.186 11.1984 15.2931 12.4172 13.9103 12.9492Z"
        fill="#6E7179"
      />
    </svg>
  );
};
