import { FC } from 'react';
import classNames from 'classnames';
import { KeyIcon, QuestionMarkCircleIcon, Spinner } from 'components/Icons';
import ReactTooltip from 'react-tooltip';

export interface IProps {
  loading: boolean;
  enabled: boolean;
  onClick: () => void;
}

export const OneTapButton: FC<IProps> = ({ enabled, loading, onClick }) => {
  const handleClick = () => {
    if (!enabled) {
      onClick();
    }
  };

  return (
    <>
      <button
        type="button"
        data-tip="1_tap"
        onClick={handleClick}
        className={classNames(
          'min-w-[12rem] w-full inline-flex justify-center items-center rounded py-2 px-4 bg-green-200 hover:bg-green-300 focus:outline outline-2 outline-coolBlue-300',
          {
            'bg-transparent hover:bg-transparent focus:outline outline-0': enabled
          }
        )}
      >
        <span className={classNames('flex align-top text-caption text-white', { 'text-gray-500': enabled })}>
          {!enabled && loading ? (
            <Spinner className="animate-spin mr-1" />
          ) : (
            <KeyIcon className={classNames('w-[15px] text-white mr-1', { 'text-gray-500': enabled })} />
          )}
          {enabled ? (
            <span>1-Tap Encryption Enabled</span>
          ) : (
            <span>{loading ? 'Enabling' : 'Enable'} 1-Tap Encryption</span>
          )}

          {!enabled && (
            <QuestionMarkCircleIcon className={classNames('w-2 ml-1 text-white', { 'text-gray-500': enabled })} />
          )}
        </span>
      </button>
      <ReactTooltip
        border
        borderColor="#EBECEF"
        className="!rounded-lg w-[200px] !opacity-100"
        place="right"
        textColor="#6E7179"
        type="light"
        effect="solid"
      >
        1-Tap Encryption is an account-locked keyless encryption mechanism.
      </ReactTooltip>
    </>
  );
};
