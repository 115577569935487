export const FeedbackIcon = ({ ...props }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.0359 14.3999C13.5133 14.3999 13.9712 14.5895 14.3087 14.9271C14.6463 15.2647 14.8359 15.7225 14.8359 16.1999V16.7999C14.8359 19.1651 12.6039 21.5999 8.83594 21.5999C5.06794 21.5999 2.83594 19.1651 2.83594 16.7999V16.1999C2.83594 15.7225 3.02558 15.2647 3.36315 14.9271C3.70071 14.5895 4.15855 14.3999 4.63594 14.3999H13.0359ZM8.83594 6.5999C9.71115 6.5999 10.5505 6.94758 11.1694 7.56645C11.7883 8.18532 12.1359 9.02469 12.1359 9.8999C12.1359 10.7751 11.7883 11.6145 11.1694 12.2334C10.5505 12.8522 9.71115 13.1999 8.83594 13.1999C7.96072 13.1999 7.12135 12.8522 6.50248 12.2334C5.88361 11.6145 5.53594 10.7751 5.53594 9.8999C5.53594 9.02469 5.88361 8.18532 6.50248 7.56645C7.12135 6.94758 7.96072 6.5999 8.83594 6.5999ZM19.6359 2.3999C20.2412 2.40001 20.8241 2.62883 21.2678 3.04051C21.7116 3.45219 21.9833 4.01632 22.0287 4.6199L22.0359 4.7999V7.1999C22.0361 7.80539 21.8074 8.38858 21.3957 8.83256C20.984 9.27654 20.4197 9.54849 19.8159 9.5939L19.6359 9.5999H17.8347L16.3971 11.5199C15.7611 12.3671 14.4723 12.0239 14.2647 11.0687L14.2443 10.9355L14.2359 10.7999V9.5219L14.1423 9.4979C13.711 9.36722 13.3251 9.11791 13.0287 8.77841C12.7323 8.43892 12.5373 8.02292 12.4659 7.5779L12.4419 7.3787L12.4359 7.1999V4.7999C12.4357 4.19441 12.6644 3.61122 13.0761 3.16724C13.4878 2.72327 14.0522 2.45131 14.6559 2.4059L14.8359 2.3999H19.6359Z"
        fill="#6E7179"
      />
    </svg>
  );
};
