import { ButtonHTMLAttributes, forwardRef, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

export enum ButtonSize {
  BROWSE = 'browse',
  BOX = 'box',
  CARD = 'card',
  CTA = 'cta',
  CTA_LONG = 'cta-long',
  LARGE = 'large',
  MD = 'md'
}

export enum ButtonColor {
  BLUE = 'blue',
  DEFAULT = 'default',
  GREEN = 'green',
  ORANGE = 'orange',
  WHITE = 'white'
}

export enum ButtonVariant {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  QUATERNARY = 'quaternary'
}

export const ACTION_BUTTONS = {
  SIGN: {
    button: 'Sign Now',
    bgcolor: 'yellow',
    variant: ButtonVariant.PRIMARY,
    twColor: 'bg-orange-700',
    textColor: 'text-gray-600'
  },
  VIEW: {
    button: 'View',
    bgcolor: 'yellow',
    variant: ButtonVariant.SECONDARY,
    twColor: 'bg-coolBlue-200',
    textColor: 'text-gray-600'
  },
  COMPLETED: {
    button: 'Download',
    bgcolor: 'green',
    variant: ButtonVariant.TERTIARY,
    twColor: 'bg-green-200',
    textColor: 'text-gray-600'
  },
  EXPIRED: {
    button: 'Download',
    bgcolor: 'blue',
    variant: ButtonVariant.TERTIARY,
    twColor: 'bg-gray-400',
    textColor: 'text-gray-400'
  }
};

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  fullWidth?: boolean;
  rounded?: boolean;
  disableText?: string;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(function Button(
  {
    className,
    children,
    variant = ButtonVariant.FILLED,
    color = ButtonColor.DEFAULT,
    size = ButtonSize.MD,
    iconLeft,
    iconRight,
    fullWidth,
    rounded,
    disabled,
    disableText,
    ...rest
  },
  ref
) {
  const classes = useMemo(() => {
    const classes = [];
    switch (color) {
      case ButtonColor.ORANGE:
        if (variant === ButtonVariant.FILLED) {
          classes.push('text-white bg-orange-700');
          classes.push('hover:bg-orange-800');
          classes.push('disabled:bg-orange-300');
        } else if (variant === ButtonVariant.OUTLINED) {
          classes.push('text-orange-600 border border-orange-600');
          classes.push('hover:text-orange-700 hover:border-orange-700');
          classes.push('disabled:text-orange-300 disabled:border-orange-300');
        }
        break;
      case ButtonColor.BLUE:
        if (variant === ButtonVariant.FILLED) {
          classes.push('text-gray-600 bg-blue-200');
          classes.push('hover:bg-blue-300');
        } else if (variant === ButtonVariant.OUTLINED) {
          classes.push('text-blue-200 border border-blue-200');
          classes.push('hover:text-blue-300 hover:border-blue-300');
        }
        break;
      case ButtonColor.GREEN:
        if (variant === ButtonVariant.FILLED) {
          classes.push('text-gray-600 bg-green-200');
          classes.push('hover:bg-green-300');
        } else if (variant === ButtonVariant.OUTLINED) {
          classes.push('text-green-200 border border-green-200');
          classes.push('hover:text-green-300 hover:border-green-300');
        }
        break;
      case ButtonColor.WHITE:
        classes.push('text-gray-600 bg-white');
        classes.push('hover:bg-white');
        break;
      default:
        if (variant === ButtonVariant.FILLED) {
          classes.push('text-gray-600 bg-gray-100');
          classes.push('hover:bg-gray-200');
          classes.push('disabled:bg-gray-200');
        } else if (variant === ButtonVariant.OUTLINED) {
          classes.push('text-gray-500 border border-gray-500');
          classes.push('hover:text-gray-600 hover:border-gray-600');
        }
    }

    if (disabled) {
      classes.push('disabled:border-gray-200 disabled:text-gray-200');
    }

    switch (size) {
      case ButtonSize.CTA:
        classes.push('px-8 py-2');
        break;
      case ButtonSize.CARD:
        classes.push('px-4 py-2');
        break;
      case ButtonSize.BROWSE:
        classes.push('px-8 py-2');
        break;
      case ButtonSize.LARGE:
        classes.push('px-8 py-6');
        break;
      case ButtonSize.CTA_LONG:
        classes.push('px-12 py-2');
        break;
      case ButtonSize.BOX:
        classes.push('px-9 py-8');
        break;
      default:
        classes.push('px-4 py-2');
    }

    switch (variant) {
      case ButtonVariant.PRIMARY:
        classes.push('text-white border-none bg-orange-700 rounded-lg');
        classes.push('hover:bg-orange-800');
        classes.push('focus:border-coolBlue-300 focus:bg-orange-800');
        classes.push('disabled:text-gray-100 disabled:border-none disabled:bg-orange-300');
        break;
      case ButtonVariant.SECONDARY:
        classes.push('text-white border-none bg-coolBlue-200 rounded-lg');
        classes.push('hover:bg-coolBlue-300');
        classes.push('focus:border-orange-700 focus:bg-coolBlue-300');
        classes.push('disabled:text-gray-100 disabled:border-none disabled:bg-coolBlue-100');
        break;
      case ButtonVariant.TERTIARY:
        classes.push('text-gray-500 border border-gray-500 bg-transparent rounded-lg');
        classes.push('hover:text-gray-600 hover:border-gray-600');
        classes.push('focus:border-coolBlue-300');
        classes.push('disabled:text-gray-300 disabled:border-gray-300');
        break;
      case ButtonVariant.QUATERNARY:
        classes.push('text-gray-500 border-none bg-transparent rounded-lg');
        classes.push('hover:text-gray-600 hover:border-none');
        classes.push('focus:border-coolBlue-300');
        classes.push('disabled:text-gray-300 disabled:border-none');
        break;
    }

    return classes;
  }, [color, size, variant, disabled]);

  const leftIconDisplay = iconLeft && <div className="mr-1">{iconLeft}</div>;
  const rightIconDisplay = iconRight && <div className="ml-1">{iconRight}</div>;

  return (
    <>
      <div data-tip="React-tooltip" data-for={disabled && disableText ? 'tooltip' : null}>
        <button
          ref={ref}
          type="button"
          className={classNames(
            'min-w-[6.75rem] inline-flex justify-center items-center disabled:cursor-not-allowed',
            classes,
            className,
            {
              'w-full': fullWidth,
              'rounded-full': rounded,
              'rounded-[0.625rem]': !rounded
            },
            'text-body'
          )}
          disabled={disabled}
          {...rest}
        >
          {leftIconDisplay}
          {children}
          {rightIconDisplay}
        </button>
      </div>
      {disabled && disableText && (
        <ReactTooltip
          id="tooltip"
          border
          borderColor="#EBECEF"
          className="!rounded-lg w-[160px] !ml-0 !py-2 !px-3 text-center"
          place="top"
          textColor="#6E7179"
          type="light"
          effect="solid"
        >
          {disableText}
        </ReactTooltip>
      )}
    </>
  );
});
