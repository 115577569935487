import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Divider } from 'components/Divider';
import { RecipientCard } from 'components/RecipientCard';
import { Recipient, RecipientType } from 'types/Recipient';
import { updateRecipient, removeRecipient, swapRecipient } from 'store/contractSlice';
import { RootState } from 'store';

import { RecipientDragWrapper } from './RecipientDragWrapper';
import { RecipientPlaceholder } from './RecipientPlaceholder';

interface RecipientList {
  type: RecipientType;
  ordering?: boolean;
  draggable?: boolean;
}

export interface DragItem {
  index: number;
  id: string;
  type: string;
  recipientType: RecipientType;
}

export const LABELS = {
  [RecipientType.SIGNER]: 'Signer',
  [RecipientType.VIEWER]: 'Viewer'
};

export const RecipientList: FC<RecipientList> = ({ type, ordering, draggable }) => {
  const dispatch = useDispatch();

  const recipients = useSelector((state: RootState) =>
    state.contracts.recipients
      .map((recipient, index) => ({
        ...recipient,
        index: index
      }))
      .filter((recipient) => recipient.type === type)
  );

  const handleRemoveRecipient = (recipient: Recipient) => {
    dispatch(removeRecipient(recipient.id));
  };

  const handleUpdateRecipient = (id: string, data: Partial<Recipient>) => {
    dispatch(updateRecipient({ id, data }));
  };

  const handleSwap = (first: number, second: number) => {
    dispatch(swapRecipient({ first, second }));
  };

  const handleTypeChange = (id: string, type: RecipientType) => {
    dispatch(updateRecipient({ id, data: { type } }));
  };

  return (
    <div className="w-full">
      <Divider className="text-p5" label={LABELS[type] + 's'} align="left" />

      <div className="space-y-6">
        {recipients?.length === 0 ? <RecipientPlaceholder type={type} onTypeChange={handleTypeChange} /> : null}
        {recipients?.map((signer) =>
          draggable ? (
            <RecipientDragWrapper
              key={signer.id}
              className="flex items-center"
              id={signer.id}
              index={signer.index}
              type={signer.type}
              onSwap={handleSwap}
              onTypeChange={handleTypeChange}
            >
              {ordering ? (
                <input
                  className="mr-2 w-[2.5rem] p-2 rounded-md text-gray-600 text-p5 border border-gray-300"
                  value={signer.order || 1}
                />
              ) : null}
              <RecipientCard
                className="flex-1"
                recipient={{
                  ...signer,
                  user: { ...signer.user, nickname: signer.user.nickname }
                }}
                editable
                onRemove={handleRemoveRecipient}
                onUpdate={handleUpdateRecipient}
              />
            </RecipientDragWrapper>
          ) : (
            <RecipientCard
              key={signer.id}
              className="flex-1"
              recipient={signer}
              editable
              onRemove={handleRemoveRecipient}
              onUpdate={handleUpdateRecipient}
            />
          )
        )}
      </div>
    </div>
  );
};
