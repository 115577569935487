import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { ACTION_BUTTONS, Button, ButtonSize, ButtonVariant } from 'components/Button';
import { History, Info, Archive, MoreIcon, KeyIcon, SignedIcon, ClockIcon } from 'components/Icons';
import { Menu } from 'components/Menu';
import { Dispatch, SetStateAction } from 'react';
import { Contract, EncryptMethod, Recipient } from 'types';
import { updateContract } from 'store/contractSlice';
import { useDispatch } from 'react-redux';
import { useDecrypt } from 'hooks';
import { STATUS_FILTERS } from './';
import { truncateAddress } from 'utils/utils';

interface IProps {
  row: Contract;
}

export const TableCheckbox = () => {
  return (
    <div className="flex items-center h-5">
      <input
        id="comments"
        name="comments"
        type="checkbox"
        className="h-4 w-4 text-white checked:bg-checkbox-pattern border-2 border-gray-500 hover:border-gray-600 focus:ring-transparent focus:border-orange-500 checked:border-orange-500 rounded"
      />
    </div>
  );
};

export const ContractNameCell = ({ row }: IProps) => {
  return (
    <div className="flex items-center">
      <p className="text-gray-600 max-w-xs text-body truncate leading-5">{row.name}</p>
      {!!row.encrypted && <KeyIcon className="text-gray-400 ml-2" />}
    </div>
  );
};

export const ContractStatusCell = ({ row }: IProps) => {
  return (
    <Menu
      menus={[...row.recipients]
        .sort((recipient1: Recipient, recipient2: Recipient) => {
          return (
            (recipient2.signed ? 1 : -1 && recipient2.user.alias?.localeCompare(recipient1.user.alias || '')) || -1
          );
        })
        .map((recipient: Recipient) => {
          return {
            icon: recipient.signed ? (
              <SignedIcon className="mr-3 h-5 w-5 text-green-200 group-hover:text-primary-400" />
            ) : (
              <ClockIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-primary-400" />
            ),
            name: recipient.user.alias || recipient.user.email || truncateAddress(recipient.user.address),
            id: truncateAddress(recipient.user.address)
          };
        })}
    >
      <div className="flex items-center capitalize text-gray-600 text-body cursor-pointer">
        <div
          className={classNames(
            'w-[10px] min-w-[10px] h-[10px] rounded-lg mr-2',
            ACTION_BUTTONS[row.meta.status].twColor
          )}
        />
        {STATUS_FILTERS[row.meta.status]}
      </div>
    </Menu>
  );
};

export const ContractDepartmentCell = ({ row }: IProps) => {
  return <p className=" text-gray-600 text-body ml-auto">{row.network}</p>;
};

export const ContractDateCell = ({ row }: IProps) => {
  return <p className=" text-gray-600 text-body">{row.date}</p>;
};

interface ContractActionCellProps extends IProps {
  currentRowOpen: string;
  setCurrentRowOpen: Dispatch<SetStateAction<string>>;
  onDownload?: (row: Contract) => void;
}

export const ContractActionCell = ({ setCurrentRowOpen, row, onDownload }: ContractActionCellProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { decryptContract } = useDecrypt();

  const handleClick = async () => {
    dispatch(updateContract({ passPhase: '', current: undefined }));

    if (row.expired || row.signed) {
      onDownload?.(row);
      return;
    }

    if (row.encrypted === EncryptMethod.ONETAP || row.encrypted === EncryptMethod.PASSWORD) {
      setCurrentRowOpen(row.id);
      const { success } = await decryptContract(row.id);
      if (success) {
        navigate(`/sign/${row.id}`);
      }
      return;
    }

    navigate(`/sign/${row.id}`);
  };

  return (
    <div
      className={classNames(
        'w-[6.75rem] h-12 text-body leading-[1.0625rem]',
        ACTION_BUTTONS[row.meta.status].textColor
      )}
    >
      <Button
        size={ButtonSize.CARD}
        variant={ACTION_BUTTONS[row.meta.status].variant as ButtonVariant}
        onClick={handleClick}
        className="!py-2 text-body"
      >
        {ACTION_BUTTONS[row.meta.status].button}
      </Button>
    </div>
  );
};

// TODO: can use row item in props
export const ContractMoreCell = () => {
  const handleDocumentHistory = () => {
    // TODO: Document History actions here
  };

  const handleDocumentInfo = () => {
    // TODO: Document Info actions here
  };

  const handleArchive = () => {
    // TODO: Archive action here
  };

  const menus = [
    {
      id: uuid(),
      icon: <History className="mr-3 h-5 w-5 text-orange-500 group-hover:text-orange-600" />,
      name: 'Document History',
      onClick: handleDocumentHistory
    },
    {
      id: uuid(),
      icon: <Info className="mr-3 h-5 w-5 text-orange-500 group-hover:text-orange-600" />,
      name: 'Document Info',
      onClick: handleDocumentInfo
    },
    {
      id: uuid(),
      icon: <Archive className="mr-3 h-5 w-5 text-orange-500 group-hover:text-orange-600" />,
      name: 'Archive Contract',
      onClick: handleArchive
    }
  ];
  return (
    <Menu menus={menus} align="right">
      <MoreIcon className="cursor-pointer text-gray-500" />
    </Menu>
  );
};
