export const FileIcon = ({ ...props }) => {
  return (
    <svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.42773 39.0796V8C8.42773 5.79086 10.2186 4 12.4277 4H28.6102C29.5833 4 30.5229 4.35469 31.2533 4.99765L39.3785 12.1506C40.2411 12.91 40.7354 14.0037 40.7354 15.1529V39.0796C40.7354 41.2888 38.9446 43.0796 36.7354 43.0796H12.4277C10.2186 43.0796 8.42773 41.2888 8.42773 39.0796Z"
        stroke="#DD8D58"
        strokeWidth="3"
      />
      <path
        d="M27.3506 4V10.8938C27.3506 13.6552 29.5892 15.8938 32.3506 15.8938H40.7352"
        stroke="#DD8D58"
        strokeWidth="3"
      />
      <path
        d="M15.8125 19.4912C14.9841 19.4912 14.3125 20.1628 14.3125 20.9912C14.3125 21.8196 14.9841 22.4912 15.8125 22.4912V19.4912ZM32.8894 22.4912C33.7178 22.4912 34.3894 21.8196 34.3894 20.9912C34.3894 20.1628 33.7178 19.4912 32.8894 19.4912V22.4912ZM15.8125 22.4912H32.8894V19.4912H15.8125V22.4912Z"
        fill="#DD8D58"
      />
      <path
        d="M15.8125 24.5889C14.9841 24.5889 14.3125 25.2604 14.3125 26.0889C14.3125 26.9173 14.9841 27.5889 15.8125 27.5889V24.5889ZM32.8894 27.5889C33.7178 27.5889 34.3894 26.9173 34.3894 26.0889C34.3894 25.2604 33.7178 24.5889 32.8894 24.5889V27.5889ZM15.8125 27.5889H32.8894V24.5889H15.8125V27.5889Z"
        fill="#DD8D58"
      />
      <path
        d="M15.8125 29.6855C14.9841 29.6855 14.3125 30.3571 14.3125 31.1855C14.3125 32.014 14.9841 32.6855 15.8125 32.6855V29.6855ZM25.9663 32.6855C26.7948 32.6855 27.4663 32.014 27.4663 31.1855C27.4663 30.3571 26.7948 29.6855 25.9663 29.6855V32.6855ZM15.8125 32.6855H25.9663V29.6855H15.8125V32.6855Z"
        fill="#DD8D58"
      />
      <path
        d="M32.4277 49.451V31.1855L47.6585 43.0794H41.197L44.8893 50.3006L40.7354 51.9997L37.0431 44.7785L32.4277 49.451Z"
        fill="#DD8D58"
      />
    </svg>
  );
};
