import classNames from 'classnames';
import { HeavyCheckIcon, LoadingIcon, CloseIcon, PendingLoadingIcon } from 'components/Icons';

export enum ArchivingStatus {
  PROCESSING = 'processing',
  SUCCESS = 'success',
  FAILED = 'failed'
}

const StatusIcon = {
  pending: () => <PendingLoadingIcon />,
  processing: LoadingIcon,
  success: () => <HeavyCheckIcon className="text-green-200" />,
  failed: () => <CloseIcon className="text-red-200" />
};

interface IProps {
  isCurrentItem?: boolean;
  status: ArchivingStatus;
  title: string;
}

export const StateItem = ({ isCurrentItem, status, title }: IProps) => {
  const itemTextColor = isCurrentItem ? 'text-gray-600' : 'text-gray-500';
  let Icon = StatusIcon[status];

  if (status === ArchivingStatus.PROCESSING && !isCurrentItem) {
    Icon = StatusIcon.pending;
  }

  return (
    <div className="py-2 flex justify-between items-center min-w-[300px] max-w-[300px]">
      <span className={classNames('text-body', itemTextColor)}>{title}</span>
      <Icon />
    </div>
  );
};
