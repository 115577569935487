import { FC, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Logo } from 'components/Logo';
import { Button, ButtonColor } from 'components/Button';
import { LogOutIcon, WalletIcon, CopyIcon } from 'components/Icons';
import { ConnectAccountModal } from 'components/ConnectAccountModal';
import { RootState } from 'store';
import { setOneTapEnabled, setOpenLoginModal } from 'store/userSlice';
import classNames from 'classnames';
import { truncateAddress } from 'utils/utils';
import { useBiconomy, useRegistryContract, useToast } from 'hooks';
import { ProviderType } from 'types';
import { OneTapButton } from 'components/OneTapButton';
import { connectors } from 'utils/connectors';
import { POLYGON_CHAIN_ID } from 'utils/constants';

interface ISideBarProps {
  open?: boolean;
}

export const SideBar: FC<ISideBarProps> = ({ children }) => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { showError, openToast } = useToast();
  const { active, account, deactivate, chainId } = useWeb3React();
  const { loading: registering, contract, getRegistryKey, register } = useRegistryContract();
  const { oneTapEnabled, loginType, email, alias, darkTheme, openLoginModal, avatar } = useSelector(
    (state: RootState) => state.user
  );
  const { isBiconomyReady } = useBiconomy();

  const avatarPadding = account ? '' : 'p-2';
  const providerType = (loginType || window.localStorage.getItem('provider')) as ProviderType;
  const isPolygonChain = chainId === POLYGON_CHAIN_ID;

  const canEnableOneTap = providerType === ProviderType.INJECTED || providerType === ProviderType.TORUS;

  useEffect(() => {
    (async () => {
      if (account && active && contract && canEnableOneTap && isPolygonChain) {
        const key = await getRegistryKey(account);
        dispatch(setOneTapEnabled({ oneTapEnabled: !!key, regKey: key }));
      }
    })();
  }, [active, account, contract, isPolygonChain, isBiconomyReady]);

  const handleCopyAddress = () => {
    if (account) {
      try {
        navigator.clipboard.writeText(account);
        openToast('info', 'Copied Wallet Address');
      } catch (err) {
        showError((err as Error).message);
      }
    }
  };

  const handleEnableOneTapEncryption = async () => {
    await register();
  };

  const handleLogout = async () => {
    dispatch(setOpenLoginModal(false));
    deactivate();

    if (providerType === ProviderType.TORUS) {
      await connectors[providerType].close();
    }

    window.localStorage.clear();
    navigateTo('/');
  };

  const handleLogoClick = () => {
    navigateTo('/');
  };

  return (
    <>
      <div
        className={classNames(
          'h-screen w-[16rem] py-10 3xl:py-14 flex flex-col items-center',
          { 'bg-white': !darkTheme },
          { 'bg-darkSidebar': darkTheme }
        )}
      >
        <div className="mb-4 3xl:mb-7 flex justify-center">
          <Logo className="cursor-pointer" width={120} onClick={handleLogoClick} />
        </div>
        {children}

        {!active ? (
          <div className="mx-3 px-6 py-9 bg-orange-100 rounded-[20px] text-center">
            <WalletIcon className="mt-2 mx-auto text-orange-500" />
            <div className="text-body my-8 text-gray-500">
              Connect to your <span className="text-orange-500">wallet</span> or social account to log in.
            </div>
            <div className="px-3">
              <Button
                type="button"
                color={ButtonColor.ORANGE}
                fullWidth
                onClick={() => dispatch(setOpenLoginModal(true))}
              >
                Login
              </Button>
            </div>
          </div>
        ) : (
          <div className="px-6 w-full">
            <div className="border-t border-solid border-gray-100 pt-10">
              <div
                className={classNames(
                  'w-[4.125rem] h-[4.125rem] m-auto bg-gray-300 rounded-full overflow-hidden flex items-center justify-center',
                  avatarPadding
                )}
              >
                {avatar ? (
                  <img src={avatar} alt="Avatar" className="w-fit h-fit" />
                ) : (
                  <img src={'/avatar.png'} alt="Avatar" className="w-fit h-fit" />
                )}
              </div>
            </div>
            <h3 className={`text-heading3 ${alias ? 'text-gray-600' : 'text-white'} text-center mt-3 mb-9`}>{`${
              alias || 'alias'
            }`}</h3>
            <div className="space-y-4 border-b border-solid border-gray-100 pb-10">
              {email && (
                <div className="flex justify-between items-center">
                  <span className="flex-1 mr-4 text-gray-500">{email}</span>
                  <CopyIcon className="flex-none text-gray-400 cursor-pointer" />
                </div>
              )}
              {account && (
                <div className="text-body flex justify-between items-center">
                  <span className="flex-1 mr-4 text-ellipsis overflow-hidden text-gray-500 select-none">
                    {truncateAddress(account) || 'Not Connected'}
                  </span>
                  <CopyIcon className="flex-none text-gray-400 cursor-pointer" onClick={handleCopyAddress} />
                </div>
              )}
              {canEnableOneTap && (
                <OneTapButton enabled={oneTapEnabled} loading={registering} onClick={handleEnableOneTapEncryption} />
              )}
            </div>
            <div className="mt-5 2xl:mt-21 text-left">
              <div className="py-3">
                <button className="inline-flex items-center cursor-pointer py-3" onClick={handleLogout}>
                  <LogOutIcon className="text-gray-400" />
                  <span className="text-body text-gray-400 ml-2">log out</span>
                </button>
              </div>
            </div>
          </div>
        )}
        <pre className="text-body text-gray-400 mt-4">v4.1.3</pre>
      </div>

      <ConnectAccountModal isOpen={openLoginModal} onClose={() => dispatch(setOpenLoginModal(false))} />
    </>
  );
};
