/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { RootState } from 'store';
import TableHeadCell from './TableHeadCell';
import { ChevronRight } from 'components/Icons';
import { Contract } from 'types';
import { get } from 'lodash';
import { MAX_TABLE_ITEMS } from 'utils/constants';

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum DataType {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date'
}

export enum Alignment {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

export interface TableColumnData {
  index: string;
  title: string;
  sortItem?: JSX.Element;
  dataType: DataType;
  width?: string;
  hasFilter?: boolean;
  filterItem?: JSX.Element;
  align?: Alignment;
  cellComponent?: (e: any) => JSX.Element;
  headCellComponent?: (e: any) => JSX.Element;
}

type DataTableRow = Contract;

interface DataTableProps {
  columns: TableColumnData[];
  data: DataTableRow[];
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
  openLoginModal?: () => void;
}

export const DataTable = ({ columns, data, currentPage, setCurrentPage, openLoginModal }: DataTableProps) => {
  const { active } = useWeb3React();
  const { darkTheme } = useSelector((state: RootState) => state.user);

  const totalPages = useMemo(() => {
    return Math.ceil(data.length / MAX_TABLE_ITEMS);
  }, [data]);

  return (
    <div className="flex flex-col relative">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden sm:rounded-2xl bg-white px-4">
            <table className="min-w-full w-full divide-y divide-gray-100">
              <thead className="bg-transparent">
                <tr>
                  {columns.map((el: TableColumnData) => (
                    <TableHeadCell
                      key={el.index}
                      sortItem={el.sortItem}
                      filterItem={el.filterItem}
                      align={el.align}
                      width={el.width}
                    >
                      {el?.headCellComponent ? <el.headCellComponent /> : el.title}
                    </TableHeadCell>
                  ))}
                </tr>
              </thead>
              <tbody className={classNames({ 'bg-white': !darkTheme }, { 'bg-white': darkTheme })}>
                {data &&
                  data.length > 0 &&
                  data
                    .slice(MAX_TABLE_ITEMS * (currentPage || 0), MAX_TABLE_ITEMS * ((currentPage || 0) + 1))
                    .map((row: DataTableRow) => (
                      <tr key={`datatable-row-${row.id}`}>
                        {columns.map((column) => {
                          const CellComponent = column.cellComponent;
                          return (
                            <td
                              className="px-4 py-3"
                              key={`datatable-col-${column.index}`}
                              align={column.align || 'left'}
                            >
                              {CellComponent ? <CellComponent row={row} /> : get(row, [column.index])}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                {(!data || data.length < 1) && !active && (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className="my-8 text-gray-500 text-center text-body-bold">
                        To Start Using EthSign, Please{' '}
                        <span className="text-orange-800 cursor-pointer" onClick={openLoginModal}>
                          Log In
                        </span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {data.length > 0 && setCurrentPage && (
        <ReactPaginate
          className="absolute flex items-center left-4 -bottom-10"
          pageClassName="text-body cursor-pointer w-8 h-9 flex justify-center items-center rounded text-gray-600"
          pageLinkClassName="px-3 py-1"
          activeClassName="cursor-pointer text-body w-8 h-9 flex justify-center items-center rounded mx-1 text-white bg-orange-700 text-normal"
          breakLabel="..."
          nextLabel={
            <span className="cursor-pointer w-8 h-8 flex justify-center items-center rounded">
              <ChevronRight className="text-gray-600" width={17} height={13} />
            </span>
          }
          forcePage={currentPage}
          onPageChange={(selectedItem: { selected: number }) => setCurrentPage(selectedItem.selected)}
          pageRangeDisplayed={5}
          marginPagesDisplayed={1}
          pageCount={totalPages}
          previousLabel={
            <span className="cursor-pointer w-8 h-8 flex justify-center items-center rounded">
              <ChevronRight className="text-gray-600 rotate-180" width={17} height={13} />
            </span>
          }
        />
      )}
    </div>
  );
};
