import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './index.scss';

export const SkeletonCard = () => {
  return (
    <SkeletonTheme highlightColor="#d9dde7">
      <div className="p-4 w-[23rem] font-lato text-gray-600 rounded-2xl bg-white mr-7">
        <div className="mb-3 flex justify-between items-center">
          <div className="block w-full">
            <Skeleton className="custom-skeleton" />
          </div>
        </div>
        <div className="block w-full min-h-[8rem] mb-4">
          <Skeleton height={120} className="rounded-lg custom-skeleton" />
        </div>
        <div className="p-4 flex justify-between items-center rounded-xl bg-gray-100">
          <div className="relative pl-5 w-2/3 text-p6 before:content-[' '] before:absolute before:top-[0.25rem] before:left-0 before:w-[0.625rem] before:h-[0.625rem] before:bg-gray-200 before:rounded-full before:text-red-500">
            <div className="block w-full relative">
              <Skeleton className="mb-2 custom-skeleton level-2" />
              <Skeleton className="w-2/3 custom-skeleton level-2" />
            </div>
          </div>
          <div className="block w-1/3 pl-3 mb-1">
            <Skeleton height={40} className="rounded-lg custom-skeleton level-2" />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};
