import { PDFDocument } from 'pdf-lib';
import CryptoJS from 'crypto-js';

const FILE_SIZES = ['bytes', 'KB', 'MB', 'GB', 'TB'];

export const MAX_FILE_SIZE = 5; // MB

export const getPDFPageCount = async (file: File): Promise<number> => {
  const arrayBuffer = await file.arrayBuffer();
  const pdf = await PDFDocument.load(arrayBuffer);
  return pdf.getPages().length;
};

export const getStringFileSize = (file: File, decimalPrecision = 2): string => {
  const bytes = file.size;

  if (!bytes) {
    return '';
  }

  const kiloBytes = 1024;
  const precision = decimalPrecision < 0 ? 0 : decimalPrecision;
  const fileSizeIndex = Math.floor(Math.log(bytes) / Math.log(kiloBytes));
  let stringFileSize = '';

  if (fileSizeIndex && fileSizeIndex < FILE_SIZES.length) {
    stringFileSize =
      parseFloat((bytes / Math.pow(kiloBytes, fileSizeIndex)).toFixed(precision)) + ' ' + FILE_SIZES[fileSizeIndex];
  }

  return stringFileSize;
};

export const getEncryptedStringFromFile = async (file: Blob, password: string): Promise<string> => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = async (fileEvent) => {
      const buffer = Buffer.from(fileEvent.target?.result as ArrayBuffer);
      const encryptedString = getEncryptedStringFromBuffer(buffer, password);
      resolve(encryptedString);
    };
    reader.onerror = () => {
      reject('oops, something went wrong with the file reader.');
    };
    reader.readAsArrayBuffer(file);
  });
};

export const getEncryptedStringFromBuffer = (buffer: Buffer, key: string): string => {
  const encryptedString = CryptoJS.AES.encrypt(JSON.stringify(buffer), key).toString();
  return encryptedString;
};

export const decryptDataArrayFromStringAES = (encryptedString: string, key = ''): Uint8Array => {
  const bytes = CryptoJS.AES.decrypt(encryptedString, key);
  const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const decryptedDataArray = new Uint8Array(decrypted.data);
  return decryptedDataArray;
};

export const getFileName = (file: File): string => {
  if (file) {
    const lastIndex = file.name.lastIndexOf('.');
    const fileNameWithoutExtention = file.name.slice(0, lastIndex);
    return fileNameWithoutExtention;
  } else {
    return '';
  }
};
