import { FC, ChangeEvent, KeyboardEvent, useState, useEffect, useCallback, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { isEmpty } from 'lodash';

import { Button, ButtonSize, ButtonVariant } from 'components/Button';
import { CrossIcon, QuestionMarkCircleIcon, CloseIcon, LoadingIcon } from 'components/Icons';
import { User } from 'types/User';
import { getAddressByAlias, getAddressByEmail, MAINNET_PROVIDER } from 'utils/address';
import classNames from 'classnames';
import { useToast } from 'hooks';
import { Recipient } from 'types';
import { isValidEmail } from 'utils/utils';

interface RecipientTagProps {
  recipients: Recipient[];
  user: User;
  onDelete?: () => void;
  onUpdate: (user: User) => void;
}

const RecipientTag: FC<RecipientTagProps> = ({ recipients, user, onDelete, onUpdate }) => {
  const [isLoading, setLoading] = useState(false);
  const { showError } = useToast();
  const repeatFilter = useCallback(
    (r: Recipient): boolean =>
      (!!user.alias && user.alias === r.user.alias) ||
      (!!user.email && user.email === r.user.email) ||
      (!!user.address && user.address === r.user.address),
    [user]
  );

  const getUserInformation = async () => {
    setLoading(true);
    try {
      const notRepeated = !recipients.filter(repeatFilter).length;

      if (notRepeated && isEmpty(user.address)) {
        if (user.alias) {
          const { address, status } = await getAddressByAlias(user.alias || '');
          onUpdate({ ...user, address: address || 'Invalid Alias', isValid: status });
        } else if (user.email) {
          const { address, status } = await getAddressByEmail(user.email || '');
          onUpdate({ ...user, address: address || 'Invalid Email', isValid: status });
        }
      } else if (notRepeated && user.alias === undefined && user.email === undefined && !isEmpty(user.address)) {
        const alias = await MAINNET_PROVIDER.lookupAddress(user.address);
        onUpdate({ ...user, alias: alias || '', isValid: true });
      }
    } catch (err) {
      showError((err as Error).message);
      onUpdate({ ...user, isValid: false });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user.isValid === undefined) {
      getUserInformation();
    }
  }, [user]);

  return (
    <div className="inline-flex items-center">
      <div
        className={classNames(
          'mr-2 px-4 py-2 w-fit flex items-center space-x-1.5 rounded-[2rem] text-caption border',
          { 'bg-red-200': !user?.isValid && !isLoading },
          { 'border-red-200': !user?.isValid && !isLoading }
        )}
      >
        <span
          className={classNames(
            'text-p3 text-caption',
            { 'text-gray': user?.isValid && !isLoading },
            { 'text-white': !user?.isValid && !isLoading }
          )}
        >
          {user?.alias ? user?.alias : user?.email ? user?.email : user?.address || null}
        </span>
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <button onClick={() => onDelete?.()}>
            <CloseIcon
              className={classNames(
                { 'text-gray': user?.isValid && !isLoading },
                { 'text-white': !user?.isValid && !isLoading }
              )}
            />
          </button>
        )}
      </div>
    </div>
  );
};

interface RecipientInputProps {
  recipients: Recipient[];
  onAddUsers?: (users: User[]) => void;
}

export const RecipientInput: FC<RecipientInputProps> = ({ recipients, onAddUsers }) => {
  const [value, setValue] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [showError, setShowError] = useState(false);

  const { account } = useWeb3React();
  const shownUsers = useMemo(() => (showAll ? users : users.slice(0, 5)), [showAll, users]);

  useEffect(() => {
    if (users.length < 6 && showAll) {
      setShowAll(false);
    }
  }, [users]);

  const handleValidation = async () => {
    if (value.endsWith('.eth')) {
      setUsers((prev) => [...prev, { alias: value, address: '', oneTapEnabled: false, regKey: null }]);
    } else if (value.startsWith('0x') && value.length === 42) {
      setUsers((prev) => [...prev, { address: value, oneTapEnabled: false, regKey: null }]);
    } else if (isValidEmail(value)) {
      setUsers((prev) => [...prev, { email: value, address: '', oneTapEnabled: false, regKey: null }]);
    } else {
      return;
    }
    setValue('');
  };

  const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      handleValidation();
    }
  };

  const handleDeleteUser = (index: number) => {
    setUsers((prev) => prev.filter((_, i) => i !== index));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value?.replace(/\s|,/g, ''));
  };

  const handleAddClick = () => {
    onAddUsers?.(users.filter((el: User) => el.isValid));
    const invalidUsers = users.filter((el: User) => !el.isValid);
    setUsers(invalidUsers);
    setValue('');
    setShowError(invalidUsers.filter((user) => user.isValid === false).length > 0);
  };

  const handleUpdateUser = (_user: User) => {
    setUsers((prev) =>
      prev.map((user, index) => {
        const newUser =
          (user.alias && user.alias === _user.alias) ||
          (user.email && user.email === _user.email) ||
          (user.address && user.address === _user.address)
            ? _user
            : user;
        return {
          ...newUser,
          isValid:
            newUser.isValid &&
            !prev
              .slice(0, index)
              .some(
                (u) =>
                  (newUser.alias && newUser.alias === u.alias && newUser.address !== 'Invalid Alias') ||
                  (newUser.email && newUser.email === u.email && newUser.address !== 'Invalid Email') ||
                  (newUser.address && newUser.address === u.address && newUser.address !== 'Invalid Alias')
              )
        };
      })
    );
  };

  const handleSelfAddClick = () => {
    if (account && !users.find((el: User) => el.address === account)) {
      setUsers([...users, { address: account, oneTapEnabled: false, regKey: null }]);
    }
  };

  const handleOnBlur = () => {
    handleValidation();
  };

  const handleShowAll = () => {
    setShowAll(true);
  };

  const handleOpenHelpLink = () => {
    window.open(' https://docs.ens.domains', '_blank');
  };

  return (
    <>
      <div className="flex mb-4 space-x-6">
        <Button
          variant={ButtonVariant.TERTIARY}
          onClick={handleSelfAddClick}
          disabled={!account || !!users.find((el: User) => el.address === account)}
        >
          <CrossIcon className="mr-2" />
          Add Myself
        </Button>
        <Button onClick={handleOpenHelpLink} variant={ButtonVariant.QUATERNARY}>
          <QuestionMarkCircleIcon className="mr-2" />
          What is ENS Alias?
        </Button>
      </div>
      <div className="flex items-start">
        <div className="flex-1 mr-8 px-4 pt-2 rounded-[0.625rem] bg-white border border-gray-100 flex flex-wrap align-center">
          {shownUsers.map((user, index) => (
            <div className="mb-2" key={`${user.alias}${user.email}${user.address}${index}`}>
              <RecipientTag
                recipients={recipients}
                user={user}
                onDelete={() => handleDeleteUser(index)}
                onUpdate={handleUpdateUser}
              />
            </div>
          ))}
          {!showAll && users.length > 5 && (
            <span
              className="text-p5 rounded-[2rem] bg-gray-100 py-1.5 mb-2 mr-2 px-4 cursor-pointer"
              onClick={handleShowAll}
            >
              {users.length - 5} more
            </span>
          )}
          <input
            className="flex-1 text-p5 text-gray-500 outline-none mb-2 py-2"
            placeholder={users.length === 0 ? 'Email, ENS Alias, Wallet Address, comma separated' : ''}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleOnBlur}
          />
        </div>
        <Button className="mt-1" size={ButtonSize.CTA_LONG} variant={ButtonVariant.PRIMARY} onClick={handleAddClick}>
          Add
        </Button>
      </div>
      {showError && (
        <p className="text-red-200 mt-2">
          Few inputs were not recognized. Please make sure that all addresses are properly formed.
        </p>
      )}
    </>
  );
};
