import classNames from 'classnames';
import './TableHeadCell.scss';

interface ITableHeadCellProps {
  children: string | JSX.Element;
  hasFilter?: boolean;
  onClick?: () => void;
  sortItem?: JSX.Element;
  filterItem?: JSX.Element;
  width?: string;
  align?: 'left' | 'right' | 'center';
}

const TableHeadCell = ({ children, filterItem, onClick, sortItem, width, align }: ITableHeadCellProps) => {
  return (
    <th
      scope="col"
      className={classNames('px-4 py-6 text-left text-body-bold text-gray-600 tracking-wider', {
        'cursor-pointer': sortItem || filterItem
      })}
      style={{ width: width || 'auto', textAlign: align || 'left' }}
      onClick={onClick}
    >
      <div className="flex items-center">
        {children}
        {sortItem}
        {filterItem}
      </div>
    </th>
  );
};

export default TableHeadCell;
