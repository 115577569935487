import { CloseIcon, ErrorIcon, SuccessIcon, WarningIcon, InfoIcon } from 'components/Icons';
import classNames from 'classnames';

interface ToastProps {
  type: string;
  message: string;
  title?: string;
  customCTA?: JSX.Element;
  onClose: () => void;
}

const Toast = ({ type, message, onClose, title, customCTA }: ToastProps) => {
  return (
    <div className="fixed bottom-9 right-14 w-[420px] border border-gray-100 overflow-hidden z-[200] bg-white rounded-2xl">
      <div
        className={classNames(
          'w-full border-l-8 flex relative p-4 z-20',
          { 'border-red-200': type === 'error' },
          { 'border-green-200': type === 'success' },
          { 'border-yellow-200': type === 'warning' },
          { 'border-orange-700': type === 'info' }
        )}
      >
        <div className="icon">
          {type === 'error' && <ErrorIcon className="text-red-200" />}
          {type === 'warning' && <WarningIcon className="text-yellow-200" />}
          {type === 'success' && <SuccessIcon className="text-green-200" />}
          {type === 'info' && <InfoIcon className="text-orange-700" />}
        </div>
        <div className="flex flex-col w-full ml-4 mr-5">
          <h4 className="text-body-bold text-gray-600 capitalize">{title || type}</h4>
          <p className="text-body text-gray-600 max-w-[350px]">{message}</p>
          {customCTA}
        </div>
        <CloseIcon className="absolute top-3 right-4 cursor-pointer" width={24} height={24} onClick={onClose} />
      </div>
    </div>
  );
};

export default Toast;
