import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { DataTable, DataType } from 'components/DataTable';
import { MoreIcon } from 'components/Icons';
import { TableCheckbox } from 'components/ContractTable/ContractTableCells';
import './index.scss';

import './index.scss';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonTableCell = () => {
  return (
    <div className="block w-full">
      <Skeleton className="custom-skeleton" />
    </div>
  );
};

const SkeletonActionCell = () => {
  return (
    <div className="block w-full">
      <Skeleton height={40} className="rounded-lg custom-skeleton level-2 max-w-[7rem]" />
    </div>
  );
};

const columns = [
  {
    index: 'id',
    title: '',
    dataType: DataType.STRING,
    cellComponent: TableCheckbox,
    headCellComponent: TableCheckbox,
    sortable: false
  },
  {
    index: 'name',
    title: 'Name',
    dataType: DataType.STRING,
    cellComponent: SkeletonTableCell,
    sortable: true
  },
  {
    index: 'title',
    title: 'Title',
    dataType: DataType.STRING,
    cellComponent: SkeletonTableCell,
    sortable: true
  },
  {
    index: 'department',
    title: 'Department',
    dataType: DataType.DATE,
    cellComponent: SkeletonTableCell,
    sortable: true
  },
  {
    index: 'date',
    title: 'Modified',
    dataType: DataType.STRING,
    cellComponent: SkeletonTableCell,
    sortable: true
  },
  {
    index: 'action',
    title: 'Action',
    dataType: DataType.STRING,
    cellComponent: SkeletonActionCell,
    sortable: false
  },
  {
    index: 'more',
    title: '',
    dataType: DataType.STRING,
    cellComponent: MoreIcon,
    sortable: false
  }
];

export const SkeletonContractTable = () => {
  return (
    <SkeletonTheme highlightColor="#d9dde7">
      <DataTable columns={columns} data={[]} />
    </SkeletonTheme>
  );
};
