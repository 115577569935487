import { FC, cloneElement, useEffect, useState, useCallback, useRef, isValidElement, MouseEvent } from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';

import { useModal } from 'hooks';

export interface IModalProps {
  /**
   * If the modal should be open or not
   */
  isOpen: boolean;

  /**
   * If the modal should close when the user clicks "ESC".
   * @default true
   */
  shouldCloseOnEsc?: boolean;

  /**
   * If modal should close when the use clicks backdrop
   */
  shouldCloseOnBackdropClick?: boolean;

  onClose?: VoidFunction;
}

export const Modal: FC<IModalProps> = ({
  isOpen,
  onClose,
  shouldCloseOnEsc = true,
  shouldCloseOnBackdropClick = true,
  children
}) => {
  // Local state
  const modalId = useRef<string>();
  const [modalElement, setModalElement] = useState<Element | null>();

  const { registerModal, unregisterModal, openedModals } = useModal();

  const closeAndUnregisterModal = useCallback(() => {
    if (modalId.current) {
      unregisterModal?.(modalId.current);
      onClose?.();
    }
  }, [onClose, unregisterModal]);

  /**
   * Effect to register the modal into our ModalProvider
   */
  useEffect(() => {
    if (isOpen) {
      modalId.current = registerModal?.();
    }
    return () => {
      unregisterModal?.(modalId.current as string);
    };
  }, [isOpen, unregisterModal, registerModal]);

  useEffect(() => {
    setModalElement(document.querySelector('body'));
  }, []);

  /**
   * Effect to listen to keydown
   */
  useEffect(() => {
    if (!shouldCloseOnEsc) {
      return;
    }
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        closeAndUnregisterModal();
      }
    }
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [closeAndUnregisterModal, shouldCloseOnEsc]);

  const content = (
    <FocusTrap
      focusTrapOptions={{
        clickOutsideDeactivates: true
      }}
    >
      <div
        className="fixed top-0 left-0 right-0 h-screen flex justify-center items-center bg-gray-200/50 z-[1000]"
        role="dialog"
        aria-modal="true"
        onClick={shouldCloseOnBackdropClick ? closeAndUnregisterModal : undefined}
      >
        {isValidElement(children)
          ? cloneElement(children, {
              onClick: (e: MouseEvent) => {
                e.stopPropagation();
                children.props.onClick?.(e);
              },
              onClose
            } as any)
          : null}
      </div>
    </FocusTrap>
  );

  if (isOpen && openedModals?.some((openedModalId) => openedModalId === modalId.current)) {
    return ReactDOM.createPortal(content, modalElement as Element);
  }
  return null;
};
