import { FC } from 'react';
import classnames from 'classnames';

interface IDividerProps {
  className?: string;
  label?: React.ReactNode;
  align?: 'left' | 'center' | 'right';
}

export const Divider: FC<IDividerProps> = ({ className, label, align = 'center' }) => {
  return (
    <div
      className={classnames(
        'w-full flex items-center my-[1rem] before:relative before:h-px  before:bg-gray-200 after:relative after:h-px after:bg-gray-200',
        { 'before:w-1/2': align === 'center' },
        { 'after:w-1/2': align === 'center' },
        { 'after:w-full': align === 'left' },
        { 'before:w-full': align === 'right' }
      )}
    >
      {label ? (
        <span
          className={classnames(
            'text-p6 text-gray-500',
            {
              'mr-[0.625rem]': align !== 'right',
              'ml-[0.625rem]': align !== 'left'
            },
            className
          )}
        >
          {label}
        </span>
      ) : null}
    </div>
  );
};
