import { SVGProps } from 'react';

export const PenIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.54145 5.41671H5.9802C5.60386 5.4166 5.23661 5.53231 4.9283 5.74812C4.61999 5.96394 4.38557 6.2694 4.25687 6.62305L0.8102 16.1032C0.688283 16.4378 0.7717 16.8127 1.02287 17.0648L1.29145 17.3334L7.17828 11.4465C7.17736 11.4227 7.17095 11.3989 7.17095 11.375C7.17095 11.1031 7.25159 10.8373 7.40268 10.6111C7.55376 10.385 7.76851 10.2088 8.01976 10.1047C8.27101 10.0006 8.54747 9.97341 8.8142 10.0265C9.08092 10.0795 9.32592 10.2105 9.51822 10.4028C9.71052 10.5951 9.84147 10.8401 9.89453 11.1068C9.94758 11.3735 9.92035 11.65 9.81628 11.9012C9.71221 12.1525 9.53597 12.3672 9.30986 12.5183C9.08374 12.6694 8.8179 12.75 8.54595 12.75C8.52212 12.75 8.49828 12.7436 8.47445 12.7427L2.58762 18.6295L2.8562 18.8981C2.97945 19.0217 3.13549 19.1074 3.3059 19.1451C3.4763 19.1828 3.65392 19.1709 3.81778 19.1108L13.2979 15.6632C13.6516 15.5345 13.9571 15.3001 14.1729 14.9918C14.3887 14.6835 14.5044 14.3162 14.5043 13.9399V10.3786L16.3367 8.54713L11.3739 3.5843L9.54145 5.41671ZM17.1984 7.30688L12.615 2.72355L14.5584 0.779297L19.1417 5.36263L17.1984 7.30688Z"
        fill="currentColor"
      />
    </svg>
  );
};
