import { Dispatch } from 'react';

import { ArchivingStatus } from 'components/TxState/StateItem';
import { clearTxState, updateTxState } from 'store/txStateSlice';
import { ContractProgress } from 'types';
import { fetchContractsByAddress } from 'store/contractSlice';
import { NavigateFunction } from 'react-router-dom';

export const showSignProgress = (
  status: ContractProgress,
  hasEncryption: boolean,
  dispatch: Dispatch<unknown>,
  account?: string | null | undefined,
  setIsProcessing?: Dispatch<React.SetStateAction<boolean>>,
  setIsAwaitingTransactionModalOpen?: Dispatch<React.SetStateAction<boolean>>,
  navigate?: NavigateFunction
): void => {
  if (status === ContractProgress.INITIATED || (!hasEncryption && status === ContractProgress.UPLOADING_TO_ARWEAVE)) {
    const items = hasEncryption
      ? [
          {
            status: ArchivingStatus.PROCESSING,
            title: 'Encrypting file'
          },
          {
            status: ArchivingStatus.PROCESSING,
            title: 'Uploading to Arweave'
          },
          {
            status: ArchivingStatus.PROCESSING,
            title: 'Waiting for block confirmations'
          }
        ]
      : [
          {
            status: ArchivingStatus.PROCESSING,
            title: 'Uploading to Arweave'
          },
          {
            status: ArchivingStatus.PROCESSING,
            title: 'Waiting for block confirmations'
          }
        ];
    dispatch(
      updateTxState({
        title: 'Sending Contract',
        items,
        currentItem: hasEncryption ? 'Encrypting file' : 'Uploading to Arweave'
      })
    );
    return;
  }
  if (
    status === ContractProgress.INITIATING_FAILED ||
    (!hasEncryption && status === ContractProgress.UPLOADING_FAILED)
  ) {
    const items = hasEncryption
      ? [
          {
            status: ArchivingStatus.FAILED,
            title: 'Encrypting file'
          },
          {
            status: ArchivingStatus.FAILED,
            title: 'Uploading to Arweave'
          },
          {
            status: ArchivingStatus.FAILED,
            title: 'Waiting for block confirmations'
          }
        ]
      : [
          {
            status: ArchivingStatus.FAILED,
            title: 'Uploading to Arweave'
          },
          {
            status: ArchivingStatus.FAILED,
            title: 'Waiting for block confirmations'
          }
        ];
    dispatch(
      updateTxState({
        title: 'Sending Contract Failed',
        items,
        currentItem: hasEncryption ? 'Encrypting file' : 'Uploading to Arweave'
      })
    );
    if (setIsProcessing) {
      setIsProcessing(false);
    }
    return;
  }
  if (status === ContractProgress.UPLOADING_TO_ARWEAVE && hasEncryption) {
    dispatch(
      updateTxState({
        title: 'Uploading Contract',
        items: [
          {
            status: ArchivingStatus.SUCCESS,
            title: 'Encrypting file'
          },
          {
            status: ArchivingStatus.PROCESSING,
            title: 'Uploading to Arweave'
          },
          {
            status: ArchivingStatus.PROCESSING,
            title: 'Waiting for block confirmations'
          }
        ],
        currentItem: 'Uploading to Arweave'
      })
    );
    return;
  }
  if (status === ContractProgress.UPLOADING_FAILED && hasEncryption) {
    dispatch(
      updateTxState({
        title: 'Uploading Failed',
        items: [
          {
            status: ArchivingStatus.SUCCESS,
            title: 'Encrypting file'
          },
          {
            status: ArchivingStatus.FAILED,
            title: 'Uploading to Arweave'
          },
          {
            status: ArchivingStatus.FAILED,
            title: 'Waiting for block confirmations'
          }
        ],
        currentItem: 'Encrypting file'
      })
    );
    if (setIsProcessing) {
      setIsProcessing(false);
    }
    return;
  }
  if (status === ContractProgress.AWAITING_TRANSACION || status === ContractProgress.WAITING_FOR_CONFIRMATIONS) {
    const items = hasEncryption
      ? [
          {
            status: ArchivingStatus.SUCCESS,
            title: 'Encrypting file'
          },
          {
            status: ArchivingStatus.SUCCESS,
            title: 'Uploading to Arweave'
          },
          {
            status: ArchivingStatus.PROCESSING,
            title: 'Waiting for block confirmations'
          }
        ]
      : [
          {
            status: ArchivingStatus.SUCCESS,
            title: 'Uploading to Arweave'
          },
          {
            status: ArchivingStatus.PROCESSING,
            title: 'Waiting for block confirmations'
          }
        ];
    dispatch(
      updateTxState({
        title: 'Uploading Contract',
        items,
        currentItem: 'Waiting for block confirmations'
      })
    );
    return;
  }
  if (status === ContractProgress.SUCCESS) {
    const items = hasEncryption
      ? [
          {
            status: ArchivingStatus.SUCCESS,
            title: 'Encrypting file'
          },
          {
            status: ArchivingStatus.SUCCESS,
            title: 'Uploading to Arweave'
          },
          {
            status: ArchivingStatus.SUCCESS,
            title: 'Waiting for block confirmations'
          }
        ]
      : [
          {
            status: ArchivingStatus.SUCCESS,
            title: 'Uploading to Arweave'
          },
          {
            status: ArchivingStatus.SUCCESS,
            title: 'Waiting for block confirmations'
          }
        ];
    dispatch(
      updateTxState({
        title: 'Contract Sent',
        items,
        currentItem: 'Waiting for block confirmations'
      })
    );
    setTimeout(() => {
      if (account) {
        dispatch(fetchContractsByAddress(account));
        dispatch(clearTxState());
      } else if (navigate) {
        navigate('/');
      }
    }, 3000);
    return;
  }
  if (status === ContractProgress.FAILED) {
    const items = hasEncryption
      ? [
          {
            status: ArchivingStatus.SUCCESS,
            title: 'Encrypting file'
          },
          {
            status: ArchivingStatus.SUCCESS,
            title: 'Uploading to Arweave'
          },
          {
            status: ArchivingStatus.FAILED,
            title: 'Waiting for block confirmations'
          }
        ]
      : [
          {
            status: ArchivingStatus.SUCCESS,
            title: 'Uploading to Arweave'
          },
          {
            status: ArchivingStatus.FAILED,
            title: 'Waiting for block confirmations'
          }
        ];
    dispatch(
      updateTxState({
        title: 'Contract Failed',
        items,
        currentItem: 'Waiting for block confirmations'
      })
    );
    if (setIsAwaitingTransactionModalOpen) {
      setIsAwaitingTransactionModalOpen(false);
    }
    if (setIsProcessing) {
      setIsProcessing(false);
    }
    return;
  }
};
