import { Button, ButtonSize, ButtonVariant } from 'components/Button';
import { ArrowRight } from 'components/Icons';
import StepCard, { IStepCardProps } from './StepCard';

import { UploadIcon } from 'components/Icons/Welcome/UploadIcon';
import { RecipientsIcon } from 'components/Icons/Welcome/RecipientsIcon';
import { PrepareIcon } from 'components/Icons/Welcome/PrepareIcon';
import { SendIcon } from 'components/Icons/Welcome/SendIcon';
import { uuid4 } from '@sentry/utils';

const steps: IStepCardProps[] = [
  {
    icon: <UploadIcon className="text-orange-700 my-2" />,
    title: 'Upload Contract',
    description: 'Upload PDF documents for signing'
  },
  {
    icon: <RecipientsIcon className="text-orange-700 my-2" />,
    title: 'Manage Recipients',
    description: 'Enter recipient details for your uploaded contracts'
  },
  {
    icon: <PrepareIcon className="text-orange-700 my-2" />,
    title: 'Prepare Documents',
    description: 'Assign signatures, text fields, checkboxes, and more in your contracts'
  },
  {
    icon: <SendIcon className="text-orange-700 my-2" />,
    title: 'Review and Send',
    description: 'Review details one more time before sending your contracts on their way!'
  }
];

const Welcome = ({ onStarted }: { onStarted?: () => void }): JSX.Element => {
  const handleClick = () => {
    onStarted && onStarted();
  };

  return (
    <div className="flex flex-col justify-center items-center mt-32">
      <h1 className="font-bold font-circularMedium text-2xl text-gray-600">Hi! Welcome to EthSign.</h1>
      <p className="mt-4 mb-4 font-circular text-gray-500 font-normal text-base">
        Great to have you onboard! Let&apos;s get started by following the steps below:
      </p>
      <div className="steps-container flex justify-center mt-16 mb-16">
        {steps.map((el: IStepCardProps) => (
          <StepCard key={uuid4()} {...el} />
        ))}
      </div>
      <Button
        type="button"
        variant={ButtonVariant.PRIMARY}
        size={ButtonSize.CTA}
        className="py-3 px-6"
        onClick={handleClick}
        iconRight={<ArrowRight className="text-white" />}
      >
        Get Started
      </Button>
    </div>
  );
};

export default Welcome;
