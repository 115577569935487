import React, { useState, useRef, useEffect } from 'react';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { uniqueId } from 'lodash';

interface IDatepickerProps {
  className?: string;
  value?: Date | string | Moment;
  onChange: (selectedDate: Moment) => void;
  placeholder?: string;
}

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

interface IYearsViewProps {
  value?: string | Date | Moment;
  onChange: (value: Moment) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parentRef: React.MutableRefObject<any>;
}

const YearsView = ({ value, onChange, parentRef }: IYearsViewProps) => {
  const today = new Date();
  const currentDate = value ? (value as Date) : today;
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const [viewStart, setViewStart] = useState<number>();

  useEffect(() => {
    setViewStart(Math.floor(currentYear / 10) * 10 - 1);
  }, [currentYear]);

  return (
    <div className="rdtYears">
      <div className="border-b border-solid border-gray-100 pt-4 px-4 pb-1">
        <table>
          <thead>
            <tr>
              <th
                className="rdtPrev"
                onClick={() => {
                  setViewStart(viewStart ? viewStart - 10 : 0);
                }}
              >
                <span>‹</span>
              </th>
              <th
                className="rdtSwitch"
                colSpan={2}
                onClick={() => {
                  parentRef?.current?.navigate('years');
                }}
              >
                <div className="flex items-center justify-center">
                  <span>{MONTHS?.[currentMonth]}</span>
                  <div className="bg-gray-100 p-1 ml-2">{currentYear}</div>
                </div>
              </th>
              <th
                className="rdtNext"
                onClick={() => {
                  setViewStart(viewStart ? viewStart + 10 : 0);
                }}
              >
                <span>›</span>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <table>
        <tbody>
          {Array(4)
            .fill(0)
            .map((_, index) => {
              return (
                <tr key={uniqueId()}>
                  {Array(3)
                    .fill(0)
                    .map((_, idx) => {
                      const year = (viewStart || 0) + idx + 3 * index;
                      return (
                        <td key={uniqueId()} style={{ height: '34px', width: '33%' }}>
                          <div
                            className={classNames(
                              'font-circular text-xs w-full h-full flex justify-center items-center',
                              {
                                'bg-orange-700 rounded-2xl text-white': year === currentYear
                              },
                              { 'text-neutral': year !== currentYear }
                            )}
                            onClick={() => {
                              currentDate.setFullYear(year);
                              parentRef?.current?.navigate('months');
                              onChange(moment(currentDate));
                            }}
                          >
                            {year}
                          </div>
                        </td>
                      );
                    })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const TimeView = ({ value, onChange }: IDatepickerProps) => {
  const today = new Date();
  const currentDate = value ? (value as Date) : today;
  const currentHours = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();

  const handleHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hour = parseInt(e.target.value.substring(1, 3) || '0');
    currentDate.setHours(hour > 23 ? 23 : hour);

    onChange(moment(currentDate));
  };

  const handleMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const minute = parseInt(e.target.value.substring(1, 3) || '0');
    currentDate.setMinutes(minute > 59 ? 59 : minute);

    onChange(moment(currentDate));
  };

  return (
    <div className="flex justify-between items-center border-t pt-3 pb-2 px-4 border-solid border-gray-100">
      <span className="text-caption text-gray-600 font-circular">Time</span>
      <div className="flex">
        <div className="py-1 px-2 text-caption font-circular text-gray-600 rounded bg-gray-100 w-fit mr-1">
          <input
            type="input"
            className="bg-transparent focus:outline-none w-[15px] text-center"
            onChange={handleHourChange}
            value={currentHours.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            })}
          />
          <span>:</span>
          <input
            type="input"
            className="bg-transparent focus:outline-none w-[15px] text-center"
            onChange={handleMinuteChange}
            value={currentMinutes.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            })}
          />
        </div>
        {/* <div className="flex items-center justify-center rounded h-[24px] bg-gray-100 text-caption font-circular text-gray-600 p-[1px]">
          <div
            className={classNames(
              'flex items-center justify-center cursor-pointer',
              {
                'min-w-[24px]': currentHours >= 12
              },
              {
                'min-w-[28px] bg-white rounded shadow-sm h-full': currentHours < 12
              }
            )}
            onClick={() => {
              if (currentHours >= 12) {
                currentDate.setHours(currentHours - 12);

                onChange && onChange(moment(currentDate));
              }
            }}
          >
            AM
          </div>
          <div
            className={classNames(
              'flex items-center justify-center cursor-pointer',
              {
                'min-w-[24px]': currentHours < 12
              },
              {
                'min-w-[28px] bg-white rounded shadow-sm h-full': currentHours >= 12
              }
            )}
            onClick={() => {
              if (currentHours < 12) {
                currentDate.setHours(currentHours + 12);

                onChange && onChange(moment(currentDate));
              }
            }}
          >
            PM
          </div>
        </div> */}
      </div>
    </div>
  );
};

const CustomDatePicker = ({ className, value, onChange, placeholder }: IDatepickerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const datePickerRef = useRef<any>(null);

  moment.locale('en', {
    weekdaysShort: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    weekdaysMin: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  });

  const handleChange = (selectedDate: Moment) => {
    const yesterday = moment().subtract(1, 'days');
    if (selectedDate.isAfter(yesterday)) {
      onChange && onChange(selectedDate);
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const renderView = (mode: string, renderDefault: Function) => {
    const today = new Date();
    const currentDate = value ? (value as Date) : today;
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Only for years, months and days view
    if (mode === 'time') return renderDefault();

    return (
      <div className="wrapper">
        {mode === 'days' && <div className="p-3">{renderDefault()}</div>}
        {mode === 'months' && (
          <div className="rdtMonths">
            <div className="border-b border-solid border-gray-100 pt-4 px-4 pb-1">
              <table>
                <thead>
                  <tr>
                    <th
                      className="rdtPrev"
                      onClick={() => {
                        currentDate.setFullYear(currentYear - 1);
                        handleChange(moment(currentDate));
                      }}
                    >
                      <span>‹</span>
                    </th>
                    <th
                      className="rdtSwitch"
                      colSpan={2}
                      onClick={() => {
                        datePickerRef.current?.navigate('years');
                      }}
                    >
                      <div className="flex items-center justify-center">
                        <div className="bg-gray-100 p-1 mr-2">{MONTHS?.[currentMonth]}</div>
                        <span>{currentYear}</span>
                      </div>
                    </th>
                    <th
                      className="rdtNext"
                      onClick={() => {
                        currentDate.setFullYear(currentYear + 1);
                        handleChange(moment(currentDate));
                      }}
                    >
                      <span>›</span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <table>
              <tbody>
                {Array(4)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <tr key={uniqueId()}>
                        {Array(3)
                          .fill(0)
                          .map((_, idx) => {
                            const month = idx + 3 * index;
                            return (
                              <td key={uniqueId()} style={{ height: '34px', width: '33%' }}>
                                <div
                                  className={classNames(
                                    'font-circular text-xs w-full h-full flex justify-center items-center',
                                    {
                                      'bg-orange-700 rounded-2xl text-white': month === currentMonth
                                    },
                                    { 'text-neutral': month !== currentMonth }
                                  )}
                                  onClick={() => {
                                    currentDate.setMonth(month);
                                    datePickerRef.current?.navigate('days');
                                    handleChange(moment(currentDate));
                                  }}
                                >
                                  {MONTHS?.[idx + 3 * index]}
                                </div>
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
        {mode === 'years' && <YearsView value={value} onChange={handleChange} parentRef={datePickerRef} />}
        <TimeView value={value} onChange={handleChange} />
      </div>
    );
  };

  return (
    <Datetime
      value={value}
      inputProps={{
        className,
        placeholder
      }}
      ref={datePickerRef}
      renderDay={(props, currentDate: Moment, selectedDate: Moment) => {
        const current = currentDate.toDate().getTime() / 1000;
        const selected = selectedDate?.toDate().getTime() / 1000;

        return (
          <td key={props.key} style={{ height: '26px' }}>
            <div
              className={classNames(
                'font-circular text-xs text-gray-600 w-full h-full flex justify-center items-center',
                { 'bg-orange-700 rounded-lg text-white': current === selected },
                props.className
              )}
              onClick={() => handleChange(currentDate)}
            >
              {[props['data-value']]}
            </div>
          </td>
        );
      }}
      renderView={(mode, renderDefault) => renderView(mode, renderDefault)}
      timeFormat={false}
    />
  );
};

export default CustomDatePicker;
