import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { CloseIcon, ArrowDownIcon } from 'components/Icons';

import { StateItem, ArchivingStatus } from './StateItem';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store';
import { setIsEnabled } from 'store/txStateSlice';

const BACKGROUND_COLOR = {
  [ArchivingStatus.PROCESSING]: 'bg-orange-100',
  [ArchivingStatus.SUCCESS]: 'bg-green-100',
  [ArchivingStatus.FAILED]: 'bg-red-100'
};

export interface Item {
  status: ArchivingStatus;
  title: string;
}

export const TxState = () => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const txState = useSelector((state: RootState) => state.txState);
  const { items, currentItem, isEnabled, title } = txState;
  const [isWindowEnabled, setIsWindowEnabled] = useState<boolean>(isEnabled);
  const status = items.length ? items[items.length - 1].status : ArchivingStatus.SUCCESS;
  const backgroundColorClass = BACKGROUND_COLOR[status];
  const arrowRotateClass = isOpen ? 'rotate-0' : 'rotate-180';
  const roundedClass = isOpen ? 'rounded-3xl border border-solid border-gray-100' : 'rounded-t-3xl';

  useEffect(() => setIsWindowEnabled(isEnabled), [isEnabled]);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleToggleEnabled = () => {
    dispatch(setIsEnabled(!isEnabled));
  };

  const disableClose = useMemo(() => {
    const ongoingItem = items.find((item) => item.status === ArchivingStatus.PROCESSING);
    return ongoingItem ? true : false;
  }, [items]);

  const itemsDisplay = items.map((item) => {
    const { status, title: itemTitle } = item;
    const isCurrentItem = currentItem === itemTitle;

    return <StateItem key={`txState-item-${uuid()}`} isCurrentItem={isCurrentItem} status={status} title={itemTitle} />;
  });
  const bodyDisplay = isOpen && <div className="px-6 pb-6 state-body">{itemsDisplay}</div>;
  const windowDisplay = isWindowEnabled ? (
    <div className={classNames('fixed bottom-0 right-0 overflow-hidden bg-white z-[100]', roundedClass)}>
      <div className={classNames('py-3 px-6 flex justify-between min-w-[348px]', backgroundColorClass)}>
        <span className="text-body-bold text-gray-500">{title}</span>
        <div className="flex items-center min-w-[40px] justify-end">
          <ArrowDownIcon
            className={classNames('cursor-pointer h-[16px]', arrowRotateClass)}
            onClick={handleToggleOpen}
          />
          {!disableClose && <CloseIcon className="cursor-pointer h-[16px]" onClick={handleToggleEnabled} />}
        </div>
      </div>
      {bodyDisplay}
    </div>
  ) : (
    <></>
  );

  return windowDisplay;
};
