import { SVGProps } from 'react';

export const CoinbaseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2553_8935)">
        <path
          d="M20.4858 0.384766C31.5327 0.384766 40.4858 9.33789 40.4858 20.3848C40.4858 31.4316 31.5327 40.3848 20.4858 40.3848C9.43896 40.3848 0.48584 31.4316 0.48584 20.3848C0.48584 9.33789 9.43896 0.384766 20.4858 0.384766Z"
          fill="#0052FF"
        />
        <path
          d="M20.4897 27.416C16.6069 27.416 13.4585 24.2715 13.4585 20.3848C13.4585 16.498 16.6069 13.3535 20.4897 13.3535C23.9702 13.3535 26.8608 15.8926 27.4155 19.2129H34.4976C33.8999 11.9941 27.8569 6.32227 20.4858 6.32227C12.7202 6.32227 6.42334 12.6191 6.42334 20.3848C6.42334 28.1504 12.7202 34.4473 20.4858 34.4473C27.8569 34.4473 33.8999 28.7754 34.4976 21.5566H27.4116C26.853 24.877 23.9702 27.416 20.4897 27.416Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2553_8935">
          <rect width="40" height="40" fill="white" transform="translate(0.48584 0.384766)" />
        </clipPath>
      </defs>
    </svg>
  );
};
