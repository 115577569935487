export const ThemeIcon = ({ ...props }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.436 16C13.4969 16 14.5143 15.5786 15.2645 14.8284C16.0146 14.0783 16.436 13.0609 16.436 12C16.436 10.9391 16.0146 9.92172 15.2645 9.17157C14.5143 8.42143 13.4969 8 12.436 8V16Z"
        fill="#6E7179"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.436 2C6.91304 2 2.43604 6.477 2.43604 12C2.43604 17.523 6.91304 22 12.436 22C17.959 22 22.436 17.523 22.436 12C22.436 6.477 17.959 2 12.436 2ZM12.436 4V8C11.3752 8 10.3578 8.42143 9.60761 9.17157C8.85746 9.92172 8.43604 10.9391 8.43604 12C8.43604 13.0609 8.85746 14.0783 9.60761 14.8284C10.3578 15.5786 11.3752 16 12.436 16V20C14.5578 20 16.5926 19.1571 18.0929 17.6569C19.5932 16.1566 20.436 14.1217 20.436 12C20.436 9.87827 19.5932 7.84344 18.0929 6.34315C16.5926 4.84285 14.5578 4 12.436 4Z"
        fill="#6E7179"
      />
    </svg>
  );
};
