export const ContractIcon = ({ ...props }) => {
  return (
    <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.1011 0H3.5C2.63805 0 1.8114 0.34241 1.2019 0.951903C0.59241 1.5614 0.25 2.38805 0.25 3.25V22.75C0.25 23.612 0.59241 24.4386 1.2019 25.0481C1.8114 25.6576 2.63805 26 3.5 26H16.5C17.362 26 18.1886 25.6576 18.7981 25.0481C19.4076 24.4386 19.75 23.612 19.75 22.75V7.64887C19.7499 7.21793 19.5786 6.80468 19.2739 6.5L13.25 0.476125C12.9453 0.171357 12.5321 9.20358e-05 12.1011 0V0ZM12.4375 5.6875V2.4375L17.3125 7.3125H14.0625C13.6315 7.3125 13.2182 7.14129 12.9135 6.83655C12.6087 6.5318 12.4375 6.11848 12.4375 5.6875ZM4.3125 14.625C4.09701 14.625 3.89035 14.5394 3.73798 14.387C3.5856 14.2347 3.5 14.028 3.5 13.8125C3.5 13.597 3.5856 13.3903 3.73798 13.238C3.89035 13.0856 4.09701 13 4.3125 13H15.6875C15.903 13 16.1097 13.0856 16.262 13.238C16.4144 13.3903 16.5 13.597 16.5 13.8125C16.5 14.028 16.4144 14.2347 16.262 14.387C16.1097 14.5394 15.903 14.625 15.6875 14.625H4.3125ZM3.5 17.0625C3.5 16.847 3.5856 16.6403 3.73798 16.488C3.89035 16.3356 4.09701 16.25 4.3125 16.25H15.6875C15.903 16.25 16.1097 16.3356 16.262 16.488C16.4144 16.6403 16.5 16.847 16.5 17.0625C16.5 17.278 16.4144 17.4847 16.262 17.637C16.1097 17.7894 15.903 17.875 15.6875 17.875H4.3125C4.09701 17.875 3.89035 17.7894 3.73798 17.637C3.5856 17.4847 3.5 17.278 3.5 17.0625ZM4.3125 21.125C4.09701 21.125 3.89035 21.0394 3.73798 20.887C3.5856 20.7347 3.5 20.528 3.5 20.3125C3.5 20.097 3.5856 19.8903 3.73798 19.738C3.89035 19.5856 4.09701 19.5 4.3125 19.5H10.8125C11.028 19.5 11.2347 19.5856 11.387 19.738C11.5394 19.8903 11.625 20.097 11.625 20.3125C11.625 20.528 11.5394 20.7347 11.387 20.887C11.2347 21.0394 11.028 21.125 10.8125 21.125H4.3125Z"
        fill="currentColor"
      />
    </svg>
  );
};
