import { SVGProps } from 'react';

export const QuestionMarkCircleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.21992 11.7H8.61992V10.3H7.21992V11.7ZM7.91992 0.5C4.05592 0.5 0.919922 3.636 0.919922 7.5C0.919922 11.364 4.05592 14.5 7.91992 14.5C11.7839 14.5 14.9199 11.364 14.9199 7.5C14.9199 3.636 11.7839 0.5 7.91992 0.5ZM7.91992 13.1C4.83292 13.1 2.31992 10.587 2.31992 7.5C2.31992 4.413 4.83292 1.9 7.91992 1.9C11.0069 1.9 13.5199 4.413 13.5199 7.5C13.5199 10.587 11.0069 13.1 7.91992 13.1ZM7.91992 3.3C6.37292 3.3 5.11992 4.553 5.11992 6.1H6.51992C6.51992 5.33 7.14992 4.7 7.91992 4.7C8.68992 4.7 9.31992 5.33 9.31992 6.1C9.31992 7.5 7.21992 7.325 7.21992 9.6H8.61992C8.61992 8.025 10.7199 7.85 10.7199 6.1C10.7199 4.553 9.46692 3.3 7.91992 3.3Z"
        fill="currentColor"
      />
    </svg>
  );
};
