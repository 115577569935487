import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contract, ContractStatus } from 'types/Contract';
import { v4 as uuid } from 'uuid';
import { BarStatus } from 'components/StatusBar';
import { Annotation, Recipient, RecipientType } from 'types';
import { EthContract } from 'types/Event';
import { fetchContracts, fetchViewerContracts } from 'services/contracts';
import moment from 'moment';
import { concat, sortBy } from 'lodash';

export type ContractsState = {
  account: string | null;
  list: Contract[];
  current?: Contract;
  filtered: Contract[];
  files: File[];
  fdfString: string;
  status: BarStatus;
  inProgress: boolean;
  recipients: Recipient[];
  searchTerms: string;
  searchDisplay: boolean;
  contractName: string;
  notifyEtherscan: boolean;
  oneTapEncryption: boolean;
  passwordEncryption: boolean;
  contractExpiry: number;
  passPhase: string;
  signerAnnotations: Annotation[];
  loading: boolean;
  pendingTransaction?: { transactionId: string };
  isDecrypting: boolean;
};

const initialState: ContractsState = {
  account: null,
  list: [],
  filtered: [],
  files: [],
  fdfString: '',
  status: BarStatus.UPLOAD,
  inProgress: false,
  recipients: [],
  searchTerms: '',
  searchDisplay: false,
  contractName: '',
  notifyEtherscan: false,
  oneTapEncryption: false,
  passwordEncryption: false,
  contractExpiry: 0,
  passPhase: '',
  signerAnnotations: [],
  loading: false,
  isDecrypting: false
};

export const RecentContractsSelector = createSelector(
  (state: ContractsState) => state,
  (state) => {
    const pendingContracts: Contract[] = [];
    const viewContracts: Contract[] = [];
    const completedContracts: Contract[] = [];

    state.list.forEach((c: Contract) => {
      if (c.meta.status === ContractStatus.SIGN) {
        pendingContracts.push(c);
      }
      if (c.meta.status === ContractStatus.VIEW) {
        viewContracts.push(c);
      }
      if (c.meta.status === ContractStatus.COMPLETED) {
        completedContracts.push(c);
      }
    });

    return pendingContracts.concat(viewContracts, completedContracts).slice(0, 3);
  }
);

export const fetchContractsByAddress = createAsyncThunk(
  'events/fetchContractsByAddress',
  async (address: string): Promise<EthContract[]> => {
    const signerContractsReponse = await fetchContracts(address);
    const viewerContractsReponse = await fetchViewerContracts(address);
    const signerContracts = signerContractsReponse.data.data.contracts || [];
    const viewerContracts = viewerContractsReponse.data.data.contracts || [];

    const contracts = sortBy(concat(signerContracts, viewerContracts), 'birth', 'desc').reverse();
    return contracts;
  }
);

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    setAccount(state, action) {
      state.account = action.payload;
    },
    setSearchTerms(state, action) {
      state.searchTerms = action.payload;
      state.filtered = state.list.filter(
        (item) =>
          !action.payload ||
          item.name.toLowerCase().includes(action.payload.toLowerCase()) ||
          item.meta.status.toLowerCase().includes(action.payload.toLowerCase())
      );
    },
    setSearchDisplay(state, action) {
      state.searchDisplay = action.payload;
    },
    initContract(_: ContractsState, action: PayloadAction<Partial<ContractsState>>) {
      return { ...initialState, ...action.payload };
    },
    updateContract(state: ContractsState, action: PayloadAction<Partial<ContractsState>>) {
      return { ...state, ...action.payload };
    },
    addRecipient(state: ContractsState, action: PayloadAction<Omit<Recipient, 'id'>>) {
      return { ...state, recipients: [...state.recipients, { ...action.payload, id: uuid() }] };
    },
    updateRecipient(state: ContractsState, action: PayloadAction<{ id: string; data: Partial<Recipient> }>) {
      const { id, data } = action.payload;
      return {
        ...state,
        recipients: state.recipients.map((recipient) => (recipient.id === id ? { ...recipient, ...data } : recipient))
      };
    },
    removeRecipient(state: ContractsState, action: PayloadAction<string>) {
      return { ...state, recipients: state.recipients.filter((recipient) => recipient.id !== action.payload) };
    },
    swapRecipient(state: ContractsState, action: PayloadAction<{ first: number; second: number }>) {
      const recipients = [...state.recipients];
      [recipients[action.payload.first], recipients[action.payload.second]] = [
        recipients[action.payload.second],
        recipients[action.payload.first]
      ];
      return { ...state, recipients };
    },
    setSignerAnnotations(state: ContractsState, action: PayloadAction<Annotation[]>) {
      state.signerAnnotations = action.payload;
    },
    updateSignerAnnotation(
      state: ContractsState,
      action: PayloadAction<{ id: string; annotation: Partial<Annotation> }>
    ) {
      state.signerAnnotations = state.signerAnnotations.map((annotation) =>
        annotation.id === action.payload.id ? { ...annotation, ...action.payload.annotation } : annotation
      );
    },
    savePendingTransaction(state, action) {
      state.pendingTransaction = action.payload;
    },
    setDecryptStatus(state, action) {
      state.isDecrypting = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContractsByAddress.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchContractsByAddress.fulfilled, (state, action) => {
      const account = state.account;

      if (!account) {
        state.list = [];
        state.loading = false;
      } else {
        const list: Contract[] = action.payload
          .map((item: EthContract) => {
            // compose contract meta for better status manage
            const hasSignPermission = item.signers.findIndex((adx) => adx === account) !== -1;
            const hasAccountSigned = item.signedSigners.findIndex((adx) => adx === account) !== -1;
            const numPendingSigners = item.signers.length - item.signedSigners.length;
            const numSigned = item.signedSigners.length;

            const today = new Date().getTime();
            let status: ContractStatus = ContractStatus.SIGN;
            let expiredAt = '';

            if (!hasSignPermission || hasAccountSigned) {
              status = ContractStatus.VIEW;
            }

            if (numPendingSigners === 0) {
              status = ContractStatus.COMPLETED;
            }

            if (item.expiry > 0 && today > item.expiry * 1000) {
              status = ContractStatus.EXPIRED;
              expiredAt = moment(item.expiry * 1000).format('YYYY/MM/DD HH:mm A');
            }

            return {
              id: item.id,
              name: item.name,
              network: 'Polygon',
              birth: item.birth,
              date: moment(item.birth * 1000).format('MMMM DD, YYYY hh:mm A'),
              expiry: item.expiry,
              expired: item.expiry > 0 && new Date().getTime() > item.expiry * 1000,
              recipients: item.signers.map((address) => ({
                id: address,
                type: RecipientType.SIGNER,
                user: { email: '', address: address, oneTapEnabled: false, regKey: null },
                signed: item.signedSigners.findIndex((adx) => adx === address) !== -1
              })),
              encrypted: item.metadata,
              pdfTxId: item.rawDataHash,
              signatureTxId: item.rawDataHash,
              signed: item.signed,
              meta: {
                hasSignPermission,
                hasAccountSigned,
                numPendingSigners,
                numSigned,
                status,
                expiredAt
              }
            };
          })
          .filter((item) => !!item.pdfTxId);
        state.list = list;
        state.loading = false;
      }
    });
    builder.addCase(fetchContractsByAddress.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const {
  setAccount,
  setSearchTerms,
  setSearchDisplay,
  initContract,
  updateContract,
  addRecipient,
  updateRecipient,
  removeRecipient,
  swapRecipient,
  setSignerAnnotations,
  updateSignerAnnotation,
  savePendingTransaction,
  setDecryptStatus
} = contractsSlice.actions;

export const contractsReducer = contractsSlice.reducer;
