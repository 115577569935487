import { Button, ButtonColor, ButtonVariant } from 'components/Button';
import { DiscordSettingIcon, TwitterSettingIcon } from 'components/Icons';

const ConnectionSection = () => {
  return (
    <div>
      <div className="py-16 flex flex-col max-w-[60%]">
        <h3 className="font-nexa font-bold text-2xl text-gray-600 mb-5">Connections</h3>
        <p className="font-lato font-normal text-gray-500 mb-5">
          Connect with your social media accounts to display them on your signatures and receive notifications via those
          platforms.
        </p>
        <div className="flex">
          <Button
            className="text-white w-fit py-3 px-6 mr-4 bg-discord"
            variant={ButtonVariant.FILLED}
            color={ButtonColor.GREEN}
            iconLeft={<DiscordSettingIcon />}
            fullWidth={false}
          >
            Sign in with Discord
          </Button>
          <Button
            className="text-white w-fit py-3 px-6 bg-twitter"
            variant={ButtonVariant.FILLED}
            color={ButtonColor.GREEN}
            iconLeft={<TwitterSettingIcon />}
            fullWidth={false}
          >
            Sign in with Twitter
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConnectionSection;
