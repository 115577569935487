import classNames from 'classnames';
import React, { useEffect, useState, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RecipientCard } from 'components/RecipientCard';
import { Divider } from 'components/Divider';
import { Button, ButtonSize, ButtonVariant } from 'components/Button';
import DatePicker from 'components/Datepicker';
import { Switch } from 'components/Switch';
import { HeavyCheckIcon, PencilIcon, QuestionMarkCircleIcon } from 'components/Icons';
import TextField from 'components/TextField';
import { RootState } from 'store';
import { updateContract } from 'store/contractSlice';
import { BarStatus } from 'components/StatusBar';

import { AwaitingTransactionModal } from './AwaitingTransactionModal';
import { useEthSignContract, useToast } from 'hooks';
import { ProcessingSignatureModal } from './ProcessingSignatureModal';
import { ContractProgress, ProviderType } from 'types';
import ReactTooltip from 'react-tooltip';
import { showSignProgress } from 'utils/sign';

const Review = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { create } = useEthSignContract();
  const { recipients, passwordEncryption, oneTapEncryption, passPhase, contractName, contractExpiry } = useSelector(
    (state: RootState) => state.contracts
  );
  const { status } = useSelector((state: RootState) => state.txState);

  const [isAwaitingTransactionModalOpen, setIsAwaitingTransactionModalOpen] = useState<boolean>(false);
  const [isProcessingSignatureOpen, setIsProcessingSignatureOpen] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [shouldEditContractName, setShouldEditContractName] = useState<boolean>(false);
  const [shouldSetExpiration, setShouldSetExpiration] = useState<boolean>(false);
  const [hasEncryption, setHasEncryption] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>('');
  const [contractNameError, setContractNameError] = useState<string>('');
  const { showError } = useToast();

  const loginType = window.localStorage.getItem('provider') as ProviderType;
  const hasOneTap = loginType === ProviderType.INJECTED || loginType === ProviderType.TORUS;
  const shouldDisableOneTap = recipients.some((item) => !item.user.oneTapEnabled);

  useEffect(() => {
    if (hasEncryption && !shouldDisableOneTap && hasOneTap) {
      dispatch(updateContract({ oneTapEncryption: true, passwordEncryption: false }));
    } else if (hasEncryption) {
      dispatch(updateContract({ oneTapEncryption: false, passwordEncryption: true }));
    } else {
      dispatch(updateContract({ oneTapEncryption: false, passwordEncryption: false }));
    }

    dispatch(updateContract({ passPhase: '' }));
  }, [hasEncryption]);

  useEffect(() => {
    if (!shouldDisableOneTap) {
      dispatch(updateContract({ oneTapEncryption: true, passwordEncryption: false }));
    } else {
      dispatch(updateContract({ oneTapEncryption: false, passwordEncryption: true }));
    }
  }, [shouldDisableOneTap]);

  useEffect(() => {
    if (!shouldSetExpiration && contractExpiry > 0) {
      setShouldSetExpiration(true);
    }
  }, [contractExpiry]);

  useEffect(() => {
    handleValidateContractName();
    handleValidatePassword();
  }, [contractName, passPhase]);

  useEffect(() => {
    const hasEncryption = passwordEncryption || oneTapEncryption;
    showSignProgress(
      status,
      hasEncryption,
      dispatch,
      null,
      setIsProcessing,
      setIsAwaitingTransactionModalOpen,
      navigate
    );
  }, [status]);

  useEffect(() => {
    dispatch(updateContract({ status: BarStatus.REVIEW }));
    setHasEncryption(true);
    setContractNameError('');
    setPasswordError('');
  }, []);

  useEffect(() => {
    if (status === ContractProgress.AWAITING_TRANSACION) {
      setIsAwaitingTransactionModalOpen(true);
      return;
    }
    if (status === ContractProgress.WAITING_FOR_CONFIRMATIONS) {
      setIsAwaitingTransactionModalOpen(false);
      setIsProcessingSignatureOpen(true);
      return;
    }
  }, [status]);

  const toggleAwaitingTransactionModal = () => {
    setIsAwaitingTransactionModalOpen(!isAwaitingTransactionModalOpen);
  };

  const toggleProcessingSignatureModal = () => {
    setIsProcessingSignatureOpen(!isProcessingSignatureOpen);
  };

  const handleValidatePassword = () => {
    let errorMessage = '';

    if (!passwordEncryption) {
      return errorMessage;
    }

    if (!passPhase) {
      errorMessage = 'Password cannot be empty';
    } else if (passPhase.length < 6) {
      errorMessage = 'Password should have a minimum of 6 characters';
    } else if (passPhase.length > 20) {
      errorMessage = 'Password should have less than 20 characters';
    }

    setPasswordError(errorMessage);

    return errorMessage;
  };

  const handleValidateContractName = () => {
    let errorMessage = '';
    if (!contractName) {
      errorMessage = 'Contract Name cannot be empty';
    }

    setContractNameError(errorMessage);

    return errorMessage;
  };

  const handleNext = async () => {
    if (handleValidatePassword() || handleValidateContractName()) {
      return;
    }

    setIsProcessing(true);
    try {
      await create();
      setIsProcessing(false);
    } catch {
      showError('An error triggered on Review page. Please try again.');
    }
  };

  const handleBack = () => {
    navigate('/prepare-document');
  };

  const toggleEditContractName = () => {
    setShouldEditContractName(!shouldEditContractName);
    dispatch(updateContract({ contractName: contractName }));
  };

  const handleOnChangeContractName = (newContractName: string) => {
    dispatch(updateContract({ contractName: newContractName }));
  };

  const handleKeyDownContractName = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      setShouldEditContractName(false);
    }
  };

  const handleOnBlur = (newContractName: string) => {
    dispatch(updateContract({ contractName: newContractName }));
    toggleEditContractName();
  };

  const contractNameDisplay = shouldEditContractName ? (
    <TextField
      additionalClasses="h-1/2 p-2.5"
      name="contractName"
      placeholder="Name"
      type="text"
      value={contractName}
      autoFocus
      error={contractNameError}
      maxLength={50}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChangeContractName(e.target.value)}
      onKeyDown={handleKeyDownContractName}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleOnBlur(e.target.value)}
    />
  ) : (
    contractName
  );

  const contractNameIconDisplay = shouldEditContractName ? (
    <HeavyCheckIcon className="text-gray-400 cursor-pointer mr-3 h-[25px] w-[25px]" onClick={toggleEditContractName} />
  ) : (
    <PencilIcon className="text-gray-400 cursor-pointer h-[25px] w-[25px]" onClick={toggleEditContractName} />
  );

  return (
    <div>
      <h2 className="mb-8 text-heading2 text-gray-600">Review and Send</h2>
      <h4 className="mb-6 text-body-bold text-gray-500">Added Recipients</h4>
      <div
        className={classNames('mb-8 flex w-full overflow-x-auto flex-wrap gap-10', {
          'flex-col h-[420px]': recipients.length > 6
        })}
      >
        {recipients.map((recipient) => (
          <RecipientCard key={recipient.id} recipient={recipient} />
        ))}
      </div>
      <Divider />
      <h4 className="mt-6 mb-10 text-body-bold text-gray-500">Settings</h4>
      <div className="text-body grid grid-cols-2 gap-8 min-h-[240px]">
        <div className="divide-y divide-dashed">
          <div className="flex flex-col">
            <div className="px-2 py-6 flex justify-between items-center max-h-[75px]">
              <span
                className={classNames(
                  'inline-block whitespace-nowrap overflow-hidden text-ellipsis items-center text-p5 text-gray-500 mr-2',
                  {
                    'flex flex-row': shouldEditContractName
                  },
                  { 'max-h-[19px]': !shouldEditContractName }
                )}
              >
                <span
                  className={classNames('mr-2', {
                    'mt-[-2px]': shouldEditContractName
                  })}
                >
                  Contract Name:
                </span>{' '}
                {contractNameDisplay}
              </span>
              {contractNameIconDisplay}
            </div>
            {contractNameError && !shouldEditContractName && (
              <div className="text-caption text-red-200 text-center mt-[-14.5px] ml-[-47px] pb-[0.5px]">
                {contractNameError}
              </div>
            )}
          </div>
          <div className="px-2 py-6 flex flex-col">
            <div className="flex justify-between items-center">
              <span className="text-p5 text-gray-500">Set Contract Expiration</span>
              <Switch
                label="set-contract-expiration"
                value={shouldSetExpiration}
                onChange={(value) => {
                  setShouldSetExpiration(value || false);
                  if (!value) {
                    dispatch(updateContract({ contractExpiry: 0 }));
                  }
                }}
              />
            </div>
            <div>
              {shouldSetExpiration && (
                <div className="pt-4">
                  <DatePicker
                    className="rounded-lg text-body outline-none w-full max-w-[500px] focus:outline-none  px-4 py-3 bg-white border border-gray-100 hover:border focus:border hover:border-orange-600 focus:border-orange-600 h-1/2 placeholder-gray-300"
                    value={contractExpiry > 0 ? new Date(contractExpiry * 1000) : new Date()}
                    onChange={(value) => {
                      if (typeof value !== 'string' && value) {
                        const date = value.toDate();
                        dispatch(updateContract({ contractExpiry: Math.floor((date?.getTime() || 0) / 1000) }));
                      }
                    }}
                    placeholder="MM/DD/YYYY hh:mm"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="">
          <div className="px-2 py-3 flex justify-between items-center">
            <span className="text-body text-gray-500">Contract Encryption</span>
            <Switch
              label="onetap-encryption"
              value={hasEncryption}
              onChange={(value) => setHasEncryption(value || false)}
            />
          </div>
          {hasEncryption && (
            <>
              {hasOneTap && (
                <div className="px-2 py-3 flex flex-col">
                  <div className="flex items-center">
                    <input
                      id="1_tap_encryption"
                      name="1_tap_encryption"
                      type="radio"
                      checked={oneTapEncryption}
                      disabled={shouldDisableOneTap}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        dispatch(
                          updateContract({ oneTapEncryption: e.target.checked, passwordEncryption: !e.target.checked })
                        );
                      }}
                      className="focus:ring-orange-500 h-4 w-4 text-orange-500 border-gray-300"
                    />
                    <label
                      htmlFor="1_tap_encryption"
                      data-for="1_tap_encryption"
                      className="ml-3 block text-caption text-gray-600"
                      data-tip="React-tooltip"
                    >
                      <span className="flex flex-row text-body text-gray-500">
                        1-Tap Encryption
                        <QuestionMarkCircleIcon className="w-2 ml-1 text-gray-500" />
                      </span>
                    </label>
                    {shouldDisableOneTap && (
                      <ReactTooltip
                        id="1_tap_encryption"
                        border
                        borderColor="#EBECEF"
                        className="!rounded-lg w-[200px]"
                        place="right"
                        textColor="#6E7179"
                        type="light"
                        effect="solid"
                      >
                        All recipients need to enable 1- Tap Encryption
                      </ReactTooltip>
                    )}
                  </div>
                </div>
              )}
              <div className="px-2 py-3 flex flex-col">
                <div className="flex items-center">
                  <input
                    id="password_encryption"
                    name="password_encryption"
                    type="radio"
                    checked={passwordEncryption}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(
                        updateContract({ oneTapEncryption: !e.target.checked, passwordEncryption: e.target.checked })
                      );
                    }}
                    className="focus:ring-orange-500 h-4 w-4 text-orange-500 border-gray-300"
                  />
                  <label htmlFor="password_encryption" className="ml-3 block text-caption text-gray-600">
                    <span className="flex flex-row text-body text-gray-500">Password Protection</span>
                  </label>
                </div>
                {passwordEncryption && (
                  <div className="ml-6 pt-4 w-[">
                    <TextField
                      additionalClasses="h-1/2 w-[80%]"
                      name="contractPassword"
                      placeholder="Password..."
                      type="password"
                      value={passPhase}
                      error={passwordError}
                      onFocus={() => handleValidatePassword()}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        dispatch(updateContract({ passPhase: e.target.value }))
                      }
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="mt-24 flex justify-end space-x-4 absolute right-10 bottom-10 w-full">
        <Button variant={ButtonVariant.TERTIARY} size={ButtonSize.CTA} onClick={handleBack}>
          Back
        </Button>
        <Button
          size={ButtonSize.CTA}
          variant={ButtonVariant.PRIMARY}
          disabled={!recipients.length || isProcessing}
          onClick={handleNext}
        >
          Send
        </Button>
      </div>
      <AwaitingTransactionModal
        shouldCloseOnBackdropClick={false}
        shouldCloseOnEsc={false}
        isOpen={isAwaitingTransactionModalOpen}
        onClose={toggleAwaitingTransactionModal}
      />
      <ProcessingSignatureModal
        shouldCloseOnBackdropClick={false}
        shouldCloseOnEsc={false}
        isOpen={isProcessingSignatureOpen}
        onClose={toggleProcessingSignatureModal}
      />
    </div>
  );
};

export default Review;
