import { ethers } from 'ethers';
import Torus from '@toruslabs/torus-embed';

export const POLYGON_NETWORK_URL = process.env.REACT_APP_NETWORK_URL || 'https://polygon-rpc.com';

export const MAINNET_PROVIDER = new ethers.providers.AlchemyProvider(
  'homestead',
  process.env.REACT_APP_MAINNET_API_KEY
);

export const POLYGON_PROVIDER = new ethers.providers.JsonRpcProvider(POLYGON_NETWORK_URL);

export const TORUS_PROVIDER = new Torus();

export const getAddressByAlias = async (alias: string): Promise<{ address: string; status: boolean }> => {
  try {
    const address = await MAINNET_PROVIDER.resolveName(alias);
    if (!address) throw Error();
    return { address, status: true };
  } catch (err) {
    throw Error('ENS name not found');
  }
};

export const getAddressByEmail = async (email: string): Promise<{ address: string; status: boolean }> => {
  try {
    const address = await TORUS_PROVIDER.getPublicAddress({
      verifier: 'google',
      verifierId: email
    });
    if (!address) throw Error();
    return {
      address: typeof address === 'string' ? address : address.address,
      status: true
    };
  } catch (error) {
    throw Error('ENS name not found');
  }
};

export const getAliasByAddress = async (address: string): Promise<string> => {
  try {
    const alias = await MAINNET_PROVIDER.lookupAddress(address);
    return alias || 'Me';
  } catch (error) {
    throw Error('Address not found');
  }
};

export const getAvatarByAlias = async (alias: string): Promise<string | null> => {
  try {
    const avatar = await MAINNET_PROVIDER.getAvatar(alias);
    return avatar;
  } catch (error) {
    throw Error('Alias not found');
  }
};
