import { Identifier } from 'dnd-core';
import { FC, HTMLAttributes, useRef } from 'react';
import { useDrop } from 'react-dnd';

import { RecipientType } from 'types/Recipient';
import { DragItem, LABELS } from './RecipientList';

export interface RecipientPlaceholderProps extends HTMLAttributes<HTMLDivElement> {
  type: RecipientType;
  onTypeChange: (id: string, type: RecipientType) => void;
}

export const RecipientPlaceholder: FC<RecipientPlaceholderProps> = ({ type, onTypeChange }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'signer',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    drop(item: DragItem) {
      if (type !== item.recipientType) {
        onTypeChange(item.id, type);
        return;
      }
    }
  });

  drop(ref);
  return (
    <div ref={ref} data-handler-id={handlerId} className="text-gray-400 text-p5 text-center py-20">
      No {LABELS[type].toLowerCase()} has been added yet
    </div>
  );
};
