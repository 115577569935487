import { FC, createContext, useContext, useState } from 'react';
import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';

import { createSignatureField, createSignerField } from 'utils/annotations';
import { AllAnnotationFields, Annotation, AnnotationField, AnnotationOptions, Recipient } from 'types';
import {
  ANNOTATION_SUBJECT_PREFIX,
  createSignatureWidget,
  createTextWidget,
  createCheckButtonWidget,
  getThumbnail
} from 'utils/pdfviewer';
import { parseJSON } from 'utils/utils';

interface WebViewerProps {
  instance: WebViewerInstance | null;
  documentStatus: 'loaded' | 'rendered' | 'closed';
  docViewer: Core.DocumentViewer | undefined;
  initializeWebInstance: (element: HTMLElement) => Promise<WebViewerInstance>;
  loadDocument: (file: File | string) => Promise<boolean>;
  addSignerAnnotationTools: (signers: Recipient[]) => void;
  removeAnnotationTools: (signers: Recipient[]) => void;
  loadSignFields: (account: string, annotations: Core.Annotations.Annotation[], signers: Recipient[]) => Annotation[];
  downloadPdf: (account: string, file: File, filename: string, xfdfString: string) => void;
  exportAnnotationsForNewContract: () => Promise<string>;
  exportAnnotationsForSigner: () => Promise<string>;
  getOptimizedDocument: (buffer: Uint8Array) => Promise<Uint8Array>;
  generateThumbnailBlob: () => Promise<unknown>;
  mergeDocument: (file: File | string) => Promise<boolean>;
}

const PDFTRON_LICENSE_KEY = process.env.REACT_APP_PDFTRON_LICENSE_KEY;

const WebViewerContext = createContext<WebViewerProps | null>(null);

const WebViewerProvider: FC = (props) => {
  const [instance, setInstance] = useState<WebViewerInstance | null>(null);
  const [documentStatus, setDocumentStatus] = useState<'loaded' | 'rendered' | 'closed'>('closed');

  const initializeWebInstance = async (element: HTMLElement): Promise<WebViewerInstance> => {
    const webViewerInstance = await WebViewer(
      {
        path: '/lib',
        licenseKey: PDFTRON_LICENSE_KEY,
        extension: ['pdf'],
        disabledElements: [
          'header',
          'toolsHeader',
          'thumbnailsSizeSlider',
          'thumbnailControl',
          'notesPanel',
          'searchPanel',
          'redactionPanel',
          'outlinesPanelButton',
          'layersPanelButton',
          'signaturePanelButton',
          'documentControl',
          // disable popups
          'textPopup',
          'contextMenuPopup',
          // Disable some annotation related buttons
          'linkButton',
          'annotationStyleEditButton'
        ],
        fullAPI: true
      },
      element
    );
    const { annotationManager, Annotations, documentViewer } = webViewerInstance.Core;
    webViewerInstance.Core.disableEmbeddedJavaScript();
    // Add callbacks for document status
    documentViewer.addEventListener('documentLoaded', () => {
      setDocumentStatus('loaded');
    });
    documentViewer.addEventListener('finishedRendering', () => {
      setDocumentStatus('rendered');
    });
    documentViewer.addEventListener('documentUnloaded', () => {
      setDocumentStatus('closed');
    });

    // global annotation managing
    annotationManager.addEventListener('annotationChanged', (annotations, _, { imported }) => {
      if (imported) {
        // remove all sticky notes
        annotationManager.deleteAnnotations(
          annotations.filter((annotation: Core.Annotations.Annotation) => {
            return annotation instanceof Annotations.StickyAnnotation;
          }),
          { force: true }
        );
      }
    });

    // annotation outline color to orange 700
    Annotations.SelectionModel.defaultSelectionOutlineColor = new Annotations.Color(217, 125, 64);
    Annotations.SelectionModel.defaultNoPermissionSelectionOutlineColor = new Annotations.Color(217, 125, 64);
    Annotations.SelectionModel.selectionOutlineThickness = 2;
    Annotations.SelectionModel.selectionOutlineExtraPadding = 4;

    // annotation control handler colors to orange 700
    Annotations.ControlHandle.outlineColor = new Annotations.Color(217, 125, 64);
    Annotations.ControlHandle.color = new Annotations.Color(217, 125, 64);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (webViewerInstance.Core.Tools.AnnotationSelectTool as any).setMinimumAnnotationSizeWhenResizing(
      (annotation: Core.Annotations.Annotation) => {
        if (annotation.Subject?.startsWith(ANNOTATION_SUBJECT_PREFIX)) {
          // minimum size for rectangles
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return { width: (annotation as any).minWidth, height: (annotation as any).minHeight };
        }
        return null;
      }
    );

    setInstance(webViewerInstance);
    return webViewerInstance;
  };

  const loadDocument = async (file: File | string): Promise<boolean> => {
    if (instance) {
      await instance.Core.documentViewer.loadDocument(file, { filename: 'contract.pdf' });
      return true;
    }
    return false;
  };

  const mergeDocument = async (file: File | string): Promise<boolean> => {
    if (instance) {
      await instance.Core.documentViewer.getDocument().mergeDocument(file);
      return true;
    }
    return false;
  };

  const generateThumbnailBlob = async () => {
    if (documentStatus !== 'closed' && instance) {
      const thumbnail = await getThumbnail(instance);

      return new Promise((resolve) => {
        const blobCallback = () => {
          return function (blob: Blob | null) {
            resolve(blob);
          };
        };
        thumbnail.toBlob(blobCallback());
      });
    }
    return null;
  };

  const addSignerAnnotationTools = (singers: Recipient[]) => {
    if (!instance) return;
    createSignatureField(instance, singers);
  };

  const removeAnnotationTools = (signers: Recipient[]) => {
    if (!instance) return;
    AllAnnotationFields.forEach((field) => {
      signers.forEach((singer) => {
        instance.UI.unregisterTool(`${ANNOTATION_SUBJECT_PREFIX}-${field}-${singer.user.address}`);
      });
    });
  };

  const loadSignFields = (account: string, annotations: Core.Annotations.Annotation[], signers: Recipient[]) => {
    if (!instance) return [];
    const { annotationManager } = instance.Core;
    const fieldManager = annotationManager.getFieldManager();

    const annotationsToDelete: Core.Annotations.Annotation[] = [];
    const annotationsToDraw: Core.Annotations.Annotation[] = [];

    const signerAnnotations: Annotation[] = [];

    const signerMap = signers.reduce((a, c) => ({ ...a, [c.user.address]: c }), {} as Record<string, Recipient>);

    annotations.forEach((annotation, index) => {
      annotation.ReadOnly = true;
      annotation.Hidden = false;

      // remove other signers' form widgets
      if (annotation instanceof instance.Core.Annotations.SignatureWidgetAnnotation) {
        annotationsToDelete.push(annotation);
      } else if (annotation instanceof instance.Core.Annotations.WidgetAnnotation) {
        annotation.fieldFlags.set('ReadOnly', true);
      }

      if (!annotation.Subject || !annotation.Subject?.startsWith(ANNOTATION_SUBJECT_PREFIX)) return;

      const options: AnnotationOptions = parseJSON(annotation.getCustomData('options'));
      const address = options?.signer?.user?.address;
      if (!address) return;

      const signer = signerMap[address.toLowerCase()];
      if (signer?.signed) {
        const meta = parseJSON(annotation.getCustomData('meta'));
        annotation.setCustomData('meta', JSON.stringify({ ...meta, signed: true }));
      }

      if (address.toLowerCase() !== account) return;
      const meta = JSON.stringify({ signed: true, date: new Date() });
      annotation.setCustomData('meta', meta);

      const newAnnotation = convertSignerField(account, annotation, options, index);
      if (newAnnotation) {
        annotationManager.addAnnotation(newAnnotation);
        fieldManager.addField(newAnnotation.getField());

        annotationsToDelete.push(annotation);
        annotationsToDraw.push(newAnnotation);
        newAnnotation.setCustomData('meta', meta);
      }

      signerAnnotations.push({
        id: newAnnotation ? newAnnotation.Id : annotation.Id,
        options,
        page: annotation.getPageNumber(),
        filled:
          [AnnotationField.Address, AnnotationField.DateSigned].includes(options.field) || // TODO: remove this after sometime
          !options.required
      });
    });

    // delete old annotations
    annotationManager.deleteAnnotations(annotationsToDelete, { force: true });

    // refresh viewer
    annotationManager.drawAnnotationsFromList(annotationsToDraw);

    return signerAnnotations;
  };

  const convertSignerField = (
    author: string,
    annotation: Core.Annotations.Annotation,
    options: AnnotationOptions,
    index: number
  ) => {
    if (!instance) return;

    let newAnnotation: Core.Annotations.WidgetAnnotation | undefined = undefined;
    if (options?.field === AnnotationField.Signature) {
      newAnnotation = createSignatureWidget(instance, annotation, index);
    } else if (options?.field === AnnotationField.Text) {
      newAnnotation = createTextWidget(instance, annotation, options.required);
    } else if (options?.field === AnnotationField.Checkbox) {
      newAnnotation = createCheckButtonWidget(instance, annotation, options.required);
    }

    if (!newAnnotation) return;

    // set common fields
    newAnnotation.Author = author;
    newAnnotation.Subject = annotation.Subject;
    newAnnotation.setPageNumber(annotation.getPageNumber());

    return newAnnotation;
  };

  const downloadPdf = async (account: string, file: File, filename: string, xfdfString: string) => {
    if (!instance) return;
    await loadDocument(file);
    createSignerField(account.toLowerCase(), instance, true);
    const annotations = await instance.Core.annotationManager.importAnnotations(xfdfString);
    // NOT SURE BUT THIS DOES MAGIC ¯\_(ツ)_/¯
    annotations.forEach((annotation: Core.Annotations.Annotation) => {
      const meta = parseJSON(annotation.getCustomData('meta'));
      annotation.setCustomData('meta', JSON.stringify(meta));
    });
    instance.Core.documentViewer.refreshAll();
    await instance.UI.downloadPdf({
      filename,
      includeAnnotations: true,
      flatten: true
    });
  };

  const exportAnnotationsForNewContract = async () => {
    if (!instance) return '';
    const { annotationManager } = instance.Core;
    const annotations = annotationManager.getAnnotationsList();

    // make all annotations read-only
    annotations.forEach((annotation) => {
      annotation.ReadOnly = true;
    });
    const fdfString = await annotationManager.exportAnnotations({ annotList: annotations });
    return fdfString;
  };

  const exportAnnotationsForSigner = async () => {
    if (!instance) return '';
    const { annotationManager } = instance.Core;

    return await annotationManager.exportAnnotations();
  };

  const getOptimizedDocument = (buffer: Uint8Array) => {
    if (!instance || !PDFTRON_LICENSE_KEY) return Promise.reject();

    const { PDFNet } = instance.Core;
    return PDFNet.runWithCleanup(async () => {
      const doc = await PDFNet.PDFDoc.createFromBuffer(buffer);
      doc.initSecurityHandler();
      await doc.lock();

      // TODO: Your license does not include Flattener permission.
      // const fl = await PDFNet.Flattener.create();
      // await fl.process(doc, PDFNet.Flattener.Mode.e_fast);

      await PDFNet.Optimizer.optimize(doc);
      return await doc.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_linearized);
    }, PDFTRON_LICENSE_KEY);
  };

  return (
    <WebViewerContext.Provider
      value={{
        initializeWebInstance,
        loadDocument,
        addSignerAnnotationTools,
        removeAnnotationTools,
        loadSignFields,
        downloadPdf,
        exportAnnotationsForNewContract,
        exportAnnotationsForSigner,
        getOptimizedDocument,
        instance,
        documentStatus,
        docViewer: instance?.Core.documentViewer,
        generateThumbnailBlob,
        mergeDocument
      }}
      {...props}
    />
  );
};

const useWebViewer = () => {
  const hookData = useContext(WebViewerContext);
  if (!hookData) throw new Error('Hook used without provider');
  return hookData;
};

export { WebViewerProvider, useWebViewer };
